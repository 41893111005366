import React from "react";
import {
  Box,
  Button,
  CircularProgress,
  makeStyles,
  Tooltip,
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import DownloadIcon from "../assests/icons/download.png";
import RepeatIcon from "@material-ui/icons/ReplayOutlined";
import PrintIcon from "@material-ui/icons/Print";
import MessageIcon from "@material-ui/icons/Message";
import { IconScanner, SupplierIcon, PersonIcon, GenerateReport } from "./icons";
import { Mail, Textsms, GetApp } from "@material-ui/icons";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import DoneAllIcon from "@material-ui/icons/DoneAll";
import { getTranslation } from "../heplers/translationHelper";

import CancelIcon from "@material-ui/icons/Cancel";
const useStyles = makeStyles((theme) => ({
  editButton: {
    padding: 0,
    margin: 0,
    color: "rgb(19,68,102)",
  },
  deleteButton: {
    color: theme.palette.error.dark,
  },
  scannerButton: {
    color: theme.palette.error.dark,
  },
  infoButton: {
    color: theme.palette.primary.contrastText,
    backgroundColor: "rgb(229,60,22)",
  },
  createButton: {
    color: theme.palette.primary.contrastText,
    backgroundColor: "rgb(229,60,22)",
    width: "130px",
    borderRadius: "20px",
    marginBottom: "20px",
    height: "35px",
    fontWeight: "bold",
    fontSize: "14px",
    "&:hover": {
      fontSize: "13px",
      transitions: {
        font: "font-size 0.3s",
      },
    },
  },
  confirmAll: {
    color: theme.palette.primary.contrastText,
    backgroundColor: "rgb(229,60,22)",
    borderRadius: "20px",
    marginBottom: "20px",
    height: "35px",
    width: "180px",
    fontWeight: "bold",
    fontSize: "14px",
    "&:hover": {
      fontSize: "13px",
      transitions: {
        font: "font-size 0.3s",
      },
    },
  },
  generateReportButton: {
    color: theme.palette.primary.contrastText,
    backgroundColor: "rgb(229,60,22)",
    width: "210px",
    height: "35px",
    borderRadius: "25px",
    fontWeight: "bold",
    fontSize: "14px",
  },
  successButton: {
    color: theme.palette.success.contrastText,
    backgroundColor: theme.palette.success.light,
  },
}));

export const EditButton = ({ onClick }) => {
  const classes = useStyles();
  return (
    <IconButton
      size="small"
      variant="outlined"
      className={classes.editButton}
      onClick={onClick}
    >
      <EditIcon />
    </IconButton>
  );
};

export const DeleteIConButton = ({ onClick }) => {
  return (
    <IconButton
      size="small"
      variant="outlined"
      color="secondary"
      onClick={onClick}
    >
      <CancelIcon style={{ color: "rgb(251,94,57)" }} />
    </IconButton>
  );
};

export const DeleteButton = ({ onClick }) => {
  const classes = useStyles();
  return (
    <IconButton
      aria-label="delete"
      className={classes.deleteButton}
      onClick={onClick}
      size="small"
    >
      <DeleteIcon />
    </IconButton>
  );
};

export const CancelButton = ({ onClick }) => {
  const classes = useStyles();
  return (
    <Button aria-label="cancel" onClick={onClick}>
      Cancel
    </Button>
  );
};

export const MessageButton = ({ onClick, children }) => {
  const classes = useStyles();
  return (
    <Button
      className={classes.infoButton}
      size="small"
      variant="contained"
      onClick={onClick}
    >
      <MessageIcon />
      {children}
    </Button>
  );
};

export const ReminderButton = ({ onClick, isSubmiting, children }) => {
  const classes = useStyles();
  return isSubmiting ? (
    <CircularProgress size={18} />
  ) : (
    <IconButton size="small" variant="contained" onClick={onClick}>
      <Mail />
      {children}
    </IconButton>
  );
};

export const SendSMSButton = ({ onClick, isSubmiting, children }) => {
  const classes = useStyles();
  return isSubmiting ? (
    <CircularProgress size={18} />
  ) : (
    <IconButton size="small" variant="contained" onClick={onClick}>
      <Textsms />
      {children}
    </IconButton>
  );
};

export const PrintButton = ({ onClick, children, style }) => {
  const classes = useStyles();
  return (
    <Button
      className={classes.infoButton}
      size="small"
      variant="contained"
      onClick={onClick}
      style={style}
    >
      <PrintIcon style={{ marginRight: "10px" }} />
      {children}
    </Button>
  );
};

export const CreateButton = ({ onClick, children }) => {
  const classes = useStyles();
  return (
    <Button
      className={classes.createButton}
      size="medium"
      variant="contained"
      onClick={onClick}
    >
      <span style={{ marginRight: "10px" }}>
        {getTranslation("Add", "Add", "Add")}
      </span>
      <AddCircleIcon />
      {children}
    </Button>
  );
};

export const ConfirmAll = ({ onClick, children, status }) => {
  const classes = useStyles();
  return (
    <Button
      className={classes.confirmAll}
      size="medium"
      variant="contained"
      disabled={status == "false" ? "true" : ""}
      onClick={onClick}
    >
      <span style={{ marginRight: "10px" }}>
        {getTranslation("Confirm All", "Confirm All", "Confirm All")}
      </span>
      <Box
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          background: status == "false" ? "rgb(170,170,170)" : "white",
          width: "20px",
          height: "20px",
          borderRadius: "50%",
        }}
      >
        <DoneAllIcon
          style={{
            color: status == "false" ? "white" : "rgb(229,60,22)",
            fontSize: "15px",
            marginTop: "1px",
          }}
          disabled="true"
        />
      </Box>
      {children}
    </Button>
  );
};
export const GenerateReportButton = ({ onClick, children }) => {
  const classes = useStyles();
  return (
    <Tooltip
      title={getTranslation(
        "Generate all buildings report",
        "G?n?rer le rapport de tous les b?timents",
        "Erstellen Sie einen Bericht ?ber alle Geb?ude"
      )}
      arrow
      placement="left-start"
    >
      <Button
        className={classes.generateReportButton}
        size="medium"
        variant="contained"
        onClick={onClick}
      >
        <span style={{ marginRight: "10px" }}>
          {getTranslation(
            "Generate Report",
            "Generate Report",
            "Generate Report"
          )}
        </span>
        <Box
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <GenerateReport style={{ width: "25px", height: "25px" }} />
        </Box>
      </Button>
    </Tooltip>
  );
};
export const DownloadButton = ({ onClick, children }) => {
  const classes = useStyles();
  return (
    <IconButton
      onClick={onClick}
      size="small"
      variant="outlined"
      style={{ color: "rgb(19,68,102)" }}
    >
      <GetApp />
    </IconButton>
  );
};

export const TryAgainButton = ({ onClick }) => {
  const classes = useStyles();
  return (
    <IconButton
      aria-label="delete"
      className={classes.deleteButton}
      onClick={onClick}
    >
      <RepeatIcon fontSize="small" />
    </IconButton>
  );
};

export const ScannerButton = ({ onClick }) => {
  const classes = useStyles();
  return (
    <IconScanner
      className={classes.scannerButton}
      onClick={onClick}
    ></IconScanner>
  );
};

export const FundsToSupplier = ({ onClick, isSubmiting, children }) => {
  const classes = useStyles();
  return isSubmiting ? (
    <CircularProgress size={18} />
  ) : (
    <IconButton size="small" onClick={onClick}>
      <SupplierIcon />
    </IconButton>
  );
};

export const FundsToTenant = ({ onClick, isSubmiting, children }) => {
  const classes = useStyles();
  return isSubmiting ? (
    <CircularProgress size={18} />
  ) : (
    <IconButton size="small" onClick={onClick}>
      <PersonIcon />
    </IconButton>
  );
};
