import {
  Box,
  CardActions,
  CardContent,
  Grid,
  TextField,
} from "@material-ui/core";
import { Form, Formik } from "formik";
import React, { useDebugValue, useState } from "react";
import ButtonWithLoading from "../../../components/ButtonWithLoading";
import { getTranslation } from "../../../heplers/translationHelper";
import { Post_InsertBankWithoutPonto_URL } from "../../../constants/apiUrls";
import { useSnackbar } from "notistack";
import { Post } from "../../../actions";
import * as Yup from "yup";
import MaskedInput from "react-text-mask";

function TextMaskCustom(props) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[
        /[a-zA-Z]/,
        /[a-zA-Z]/,
        /\d/,
        /\d/,
        " ",
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        " ",
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        " ",
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        " ",
        /\d/,
        /\d/,
        /\d/,
        /\d/,
      ]}
      showMask={false}
      guide={false}
    />
  );
}

const AddBankWithoutPonto = ({ setRefresh, updateBank }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsloading] = useState(false);
  const submitWithOutPonto = (payload, action) => {
    setIsloading(true);
    Post(
      payload,
      Post_InsertBankWithoutPonto_URL,
      null,
      (resp) => {
        enqueueSnackbar("bank added Without ponto successfully", {
          variant: "success",
        });
        setIsloading(false);
        setRefresh(true);
      },
      (err) => {
        enqueueSnackbar(
          err?.data
            ? err?.data
            : getTranslation(
                "Something went wrong. Please contact with admin.",
                "Something went wrong. Please contact with admin.",
                "Something went wrong. Please contact with admin."
              ),
          {
            variant: "error",
          }
        );
        setIsloading(false);
      }
    );
  };

  const initialValues = {
    iban: "",
    shortDescription: " ",
    AccountBalance: "",
  };
  const basicSystemSettingValidationSchema = Yup.object().shape({
    iban: Yup.string().required("Ponto public key is required"),
    AccountBalance: Yup.number()
      .typeError("Initial balance must be a number")
      .required("Initial balance is required")
      .min(0, "Initial balance must be greater than or equal to 0"),
    shortDescription: Yup.string().required("Write some Description"),
  });
  const defaultValue = updateBank ? updateBank : initialValues;

  return (
    <div>
      <Formik
        enableReinitialize
        initialValues={defaultValue}
        validationSchema={basicSystemSettingValidationSchema}
        onSubmit={(values, actions) => {
          console.log(values);
          // actions.setSubmitting(true);
          // values.iban = values.iban.replace(/\s/g, "");
          // submitWithOutPonto(values, actions);
          // actions.setSubmitting(false);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleSubmit,
          setFieldValue,
          isSubmitting,
          getFieldProps,
        }) => (
          <Form>
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <TextField
                    fullWidth
                    id='iban'
                    label='IBAN'
                    size='small'
                    variant='outlined'
                    name='iban'
                    {...getFieldProps("iban")}
                    error={touched.iban && Boolean(errors.iban)}
                    helperText={touched.iban && errors.iban}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      inputComponent: TextMaskCustom,
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <TextField
                    fullWidth
                    id='AccountBalance'
                    label={getTranslation(
                      "Initial Balance",
                      "Initial Balance",
                      "Initial Balance"
                    )}
                    size='small'
                    variant='outlined'
                    name='AccountBalance'
                    {...getFieldProps("AccountBalance")}
                    type='number' // Set the input type to number
                    inputProps={{
                      min: 0, // Avoid negative values
                    }}
                    error={
                      touched.AccountBalance && Boolean(errors.AccountBalance)
                    }
                    helperText={touched.AccountBalance && errors.AccountBalance}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <TextField
                    fullWidth
                    id='shortDescription'
                    label={getTranslation(
                      "Short Description",
                      "brève description",
                      "kurze Beschreibung"
                    )}
                    multiline
                    size='large'
                    variant='outlined'
                    name='shortDescription'
                    {...getFieldProps("shortDescription")}
                    error={
                      touched.shortDescription &&
                      Boolean(errors.shortDescription)
                    }
                    helperText={
                      touched.shortDescription && errors.shortDescription
                    }
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
              </Grid>
            </CardContent>
            <CardActions>
              <Box
                pr={1}
                pb={1}
                width='100%'
                display='flex'
                alignItems='center'
                justifyContent='center'
              >
                <ButtonWithLoading
                  title={getTranslation("Add", "Ajouter", "Addieren")}
                  style={{
                    backgroundColor: "rgb(229,60,22)",
                    color: "white",
                    fontWeight: "bold",
                  }}
                  size='medium'
                  variant='contained'
                  loading={isLoading}
                  onClick={handleSubmit}
                />
              </Box>
            </CardActions>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default AddBankWithoutPonto;
