import React, { useEffect, useRef, useState } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import {
  FormControl,
  Checkbox,
  FormControlLabel,
  Typography,
  TextField,
  Grid,
  Card,
  CardContent,
  CardHeader,
  Box,
  ButtonGroup,
  Button,
  FormHelperText,
} from "@material-ui/core";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import ButtonWithLoading from "../../components/ButtonWithLoading";
import { FormLoader } from "../../components/FormLoader";

import { Get, Post } from "../../actions";
import {
  Get_EnableSMSAutomaticSendReminder_URL,
  Get_GetReminderCosts_URL,
  Get_ReminderTemplate_URL,
  Post_EnableSMSAutomaticSendReminder_URL,
  Post_ReminderCosts_URL,
  Post_UpdateReminderTemplate_URL,
} from "../../constants/apiUrls";
import { useSnackbar } from "notistack";
import { getTranslation } from "../../heplers/translationHelper";
import authService from "../../utils/authUtils";
import { UpgradeSMSReminder } from "../common/UpgradeSMSReminder";
import { HtmlEditor } from "../../components/HtmlEditor";
import { useSelector } from "react-redux";
import { buttonStyles } from "../tanents/components/recoveryprocedure/components/recoveryComponents/RecoveryPersons";
import TextEditor from "./component/TextEditorCard";
import TestSmsDialoge from "./component/TestingDialoge";

export const SMSReminderSettings = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [reminderTemplate, setReminderTempate] = useState({});
  const [reminderTemplateLoading, setReminderTempateLoaading] = useState(false);
  const [SMSReminderCheck, setSMSReminderCheck] = useState(false);
  const [smsReminderDisabled, setSMSReminderDisabled] = useState(false);
  const [reminderKey, setReminderKey] = useState("FirstSMSReminder");
  const [reminderCost, setReminderCost] = useState();
  const [enableReloading, setEnableReloading] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState("english");
  const [testSmsDialoge, setSmsDialoge] = useState(false);
  const [editorValue, setEditorValue] = useState("");
  const [templateValue, setTemplateValue] = useState("");
  const [loading, setLoading] = useState(false);
  const handleLanguage = (language) => {
    setSelectedLanguage(language);
  };

  const openTestSmsDialoge = () => {
    setSmsDialoge(true);
  };

  const editorRef = useRef(null);
  const classes = buttonStyles();

  //get user credit amount from user reducer

  const userData = useSelector((state) => state.auth.user);

  useEffect(() => {
    GetSmsAutoReminder();
    getReminderCost();
  }, []);

  useEffect(() => {
    GetReminderTemplate(reminderKey);
  }, [reminderKey, selectedLanguage]);

  useEffect(() => {
    if (userData?.smsCredit < 0) {
      setSMSReminderCheck(false);
      setSMSReminderDisabled(true);
    } else if (SMSReminderCheck) {
      setSMSReminderDisabled(false);
    }
  }, [SMSReminderCheck]);

  const GetSmsAutoReminder = () => {
    Get(
      {},
      Get_EnableSMSAutomaticSendReminder_URL,
      null,
      (resp) => {
        setSMSReminderCheck(resp?.data?.enableSMSAutoReminder);
        if (resp?.data?.enableSMSAutoReminder) {
          GetReminderTemplate("FirstSMSReminder");
        }
      },
      (error) => {
        enqueueSnackbar(error?.data, { variant: "error" });
      }
    );
  };
  const GetReminderTemplate = (reminderKey) => {
    setReminderTempateLoaading(true);
    Get(
      { reminderKey: reminderKey },
      Get_ReminderTemplate_URL,
      null,
      (resp) => {
        setReminderTempate(resp?.data);
        setReminderTempateLoaading(false);
      },
      (error) => {
        enqueueSnackbar("Some thing went wrong", { variant: "error" });
      }
    );
  };
  const AppendTemplate = (template, text, setFieldValue) => {
    editorRef.current.insertContent(text);
  };

  //post reminder cost
  const postReminderCost = (value) => {
    if (!value) {
      enqueueSnackbar(
        getTranslation(
          "Reminder Cost Can't be null",
          "Reminder Cost Can't be null",
          "Reminder Cost Can't be null"
        ),
        {
          variant: "error",
        }
      );
      return;
    }
    if (value < 0) {
      enqueueSnackbar(
        getTranslation(
          "Reminder Cost Can't be negative",
          "Reminder Cost Can't be negative",
          "Reminder Cost Can't be negative"
        ),
        {
          variant: "error",
        }
      );
      return;
    }
    Post(
      {
        reminderCost: value,
        costType: 0,
      },
      Post_ReminderCosts_URL,
      null,
      (resp) => {
        enqueueSnackbar(
          getTranslation(
            "Updated successfully",
            "Updated successfully",
            "Updated successfully"
          ),
          {
            variant: "success",
          }
        );
      },
      (err) => {
        enqueueSnackbar(err?.data, { variant: "error" });
      }
    );
  };

  //get reminder cost api
  const getReminderCost = () => {
    Get(
      {},
      Get_GetReminderCosts_URL,
      null,
      (resp) => {
        setReminderCost(resp?.data?.smsReminderCost);
      },
      (err) => {
        enqueueSnackbar(err?.data, { variant: "error" });
      }
    );
  };
  const initialValues = {
    dayOfMonth: 0,
    template: "",
    sMSRemainderCost: 0,
  };

  const basicValidationSchema = Yup.object().shape({
    template: Yup.string().required("Reminder text is required"),
    dayOfMonth: Yup.number().min(1).required("Interval is required"),
    sMSRemainderCost: Yup.number().min(0),
  });
  const defaultValue = reminderTemplate ? reminderTemplate : initialValues;
  return authService.getIsStarter() == true ? (
    <UpgradeSMSReminder />
  ) : (
    <>
      <FormControl>
        <FormControlLabel
          label={getTranslation(
            "Enable Auto SMS Reminder",
            "Enable Auto SMS Reminder",
            "Enable Auto SMS Reminder"
          )}
          labelPlacement='start'
          control={
            <Checkbox
              id='SMSReminderCheck'
              style={{
                color: "rgb(229,60,22)",
              }}
              disabled={smsReminderDisabled}
              checked={SMSReminderCheck}
              onChange={(e, check) => {
                setSMSReminderCheck(check);
                Post(
                  { enableReminder: check },
                  Post_EnableSMSAutomaticSendReminder_URL,
                  null,
                  (resp) => {
                    GetReminderTemplate("FirstSMSReminder");
                  },
                  (error) => {}
                );
              }}
            />
          }
        />
      </FormControl>
      {smsReminderDisabled ? (
        <Typography variant='h5' style={{ color: "red", marginLeft: "18px" }}>
          {getTranslation(
            "Please first add credit to SMS credit",
            "Please first add credit to SMS credit",
            "Please first add credit to SMS credit"
          )}
        </Typography>
      ) : (
        ""
      )}
      <Grid
        container
        spacing={2}
        style={{ marginTop: "10px", marginBottom: "10px" }}
      >
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <TextField
            fullWidth
            variant='outlined'
            type='number'
            value={reminderCost}
            label={getTranslation(
              "Remainder Cost",
              "Remainder Cost",
              "Remainder Cost"
            )}
            size='small'
            InputProps={{ inputProps: { min: 0 } }}
            onChange={(e) => {
              setReminderCost(e.target.value);
            }}
            onBlur={(e) => {
              postReminderCost(e.target.value);
            }}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
      </Grid>
      <div>
        <FormControl
          size='small'
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <ToggleButtonGroup
            id='reminderKey'
            value={reminderKey}
            exclusive
            onChange={(event, key) => {
              if (key == null) {
                setReminderKey("FirstSMSReminder");
              } else {
                setReminderKey(key);
              }
            }}
            helperText={
              "Enable Sms Auto Reminder might occure costs for sms sending"
            }
          >
            <ToggleButton value='FirstSMSReminder' aria-label='left aligned'>
              <Typography variant='h6'>
                {getTranslation(
                  "1st Reminder",
                  "1er rappel",
                  "Erste Erinnerung"
                )}
              </Typography>
            </ToggleButton>
            <ToggleButton value='SecondSMSReminder' aria-label='left aligned'>
              <Typography variant='h6'>
                {getTranslation(
                  "2nd Reminder",
                  "2�me rappel",
                  "Zweite Erinnerung"
                )}
              </Typography>
            </ToggleButton>
            <ToggleButton value='ThirdSMSReminder' aria-label='centered'>
              <Typography variant='h6'>
                {getTranslation(
                  "3rd Reminder",
                  "3�me rappel",
                  "Dritte Erinnerung"
                )}
              </Typography>
            </ToggleButton>
          </ToggleButtonGroup>
          <FormHelperText style={{ color: "red" }}>
            {getTranslation(
              "Enable SMS Auto Reminder might occur cost for SMS sending",
              "Enable SMS Auto Reminder might occur cost for SMS sending",
              "Enable SMS Auto Reminder might occur cost for SMS sending"
            )}
          </FormHelperText>
        </FormControl>

        <br />
        <br />
        <FormLoader progress={reminderTemplateLoading}>
          <Formik
            enableReinitialize
            initialValues={defaultValue}
            validationSchema={basicValidationSchema}
            onSubmit={(values, actions) => {
              actions.setSubmitting(true);
              values.reminderKey = reminderKey;

              Post(
                values,
                Post_UpdateReminderTemplate_URL,
                null,
                setLoading(true),
                (resp) => {
                  actions.setSubmitting(false);
                  enqueueSnackbar(
                    resp?.data
                      ? getTranslation(resp?.data, resp?.data, resp?.data)
                      : getTranslation(
                          "Reminder template updated successfully",
                          "Reminder template updated successfully",
                          "Reminder template updated successfully"
                        ),
                    {
                      variant: "success",
                    }
                  );
                  setLoading(false);
                },
                (error) => {
                  actions.setSubmitting(false);
                  enqueueSnackbar(
                    error?.data
                      ? getTranslation(error?.data, error?.data, error?.data)
                      : getTranslation(
                          "Something went wrong. Please contact with admin.",
                          "Something went wrong. Please contact with admin.",
                          "Something went wrong. Please contact with admin."
                        ),
                    { variant: "error" }
                  );
                  setLoading(false);
                }
              );
            }}
          >
            {({
              values,
              setFieldValue,
              errors,
              touched,
              handleChange,
              handleSubmit,
              isSubmitting,
              getFieldProps,
            }) => (
              <>
                <Form>
                  <>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6} md={4} lg={3}>
                        <TextField
                          fullWidth
                          variant='outlined'
                          required
                          type='number'
                          id='dayOfMonth'
                          label={getTranslation(
                            "Interval (Days)",
                            "Intervalle (jours)",
                            "Intervall (Tage)"
                          )}
                          name='dayOfMonth'
                          size='small'
                          {...getFieldProps("dayOfMonth")}
                          // error={
                          // 	touched.dayOfMonth && Boolean(errors.dayOfMonth)
                          // }
                          // helperText={
                          // 	touched.dayOfMonth &&
                          // 	getTranslation(
                          // 		"Interval day(s) must be greater then 0"
                          // 	)
                          // }
                          // InputLabelProps={{
                          // 	shrink: true,
                          // }}
                          inputProps={{ min: 0 }}
                          onChange={(e) => {
                            setFieldValue("dayOfMonth", e.target.value);
                          }}
                        />
                      </Grid>
                    </Grid>

                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        <Typography variant='h5'>
                          {getTranslation(
                            "Reminder Text",
                            "Texte de rappel",
                            "Erinnerungstext"
                          )}
                        </Typography>
                      </Grid>
                    </Grid>
                    {/* dynamic buttons */}
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "left",
                        flexWrap: "wrap",
                      }}
                    >
                      <Button
                        className={classes.root}
                        size='small'
                        onClick={() => {
                          AppendTemplate(
                            values.template,
                            "<span>[tenant_name]</span>",
                            setFieldValue
                          );
                        }}
                      >
                        {getTranslation("Name", "Name", "Name")}
                      </Button>
                      <Button
                        size='small'
                        className={classes.root}
                        onClick={() =>
                          AppendTemplate(
                            values.template,
                            "<span>[tenant_email]</span>",
                            setFieldValue
                          )
                        }
                      >
                        {getTranslation("Email", "Email", "Email")}
                      </Button>
                      <Button
                        size='small'
                        className={classes.root}
                        onClick={() =>
                          AppendTemplate(
                            values.template,
                            "<span>[tenant_reference]</span>",
                            setFieldValue
                          )
                        }
                      >
                        {getTranslation("Reference", "Reference", "Reference")}
                      </Button>

                      <Button
                        size='small'
                        className={classes.root}
                        onClick={() =>
                          AppendTemplate(
                            values.template,
                            "<span>[tenant_appartment]</span>",
                            setFieldValue
                          )
                        }
                      >
                        {getTranslation("Apartment", "Logement", "Wohnungen")}
                      </Button>

                      <Button
                        size='small'
                        className={classes.root}
                        onClick={() =>
                          AppendTemplate(
                            values.template,
                            "<span>[zip_code]</span>",
                            setFieldValue
                          )
                        }
                      >
                        {getTranslation("Zip Code", "Zip Code", "Zip Code")}
                      </Button>

                      <Button
                        size='small'
                        className={classes.root}
                        onClick={() =>
                          AppendTemplate(
                            values.template,
                            "<span>[due_Amount]</span>",
                            setFieldValue
                          )
                        }
                      >
                        {getTranslation(
                          "Due Amount",
                          "Montant dû",
                          "Geburtstermin"
                        )}
                      </Button>
                    </div>
                    {/* text editor */}
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        <TextEditor
                          translatedText={
                            reminderKey === "FirstSMSReminder"
                              ? getTranslation(
                                  "1st Reminder",
                                  "1st Reminder",
                                  "1st Reminder"
                                )
                              : reminderKey === "SecondSMSReminder"
                              ? getTranslation(
                                  "2nd Reminder",
                                  "2nd Reminder",
                                  "2nd Reminder"
                                )
                              : getTranslation(
                                  "3rd Reminder",
                                  "3rd Reminder",
                                  "3rd Reminder"
                                )
                          }
                          selectedLanguage={selectedLanguage}
                          reminderKey={reminderKey}
                          openTestSmsDialoge={openTestSmsDialoge}
                          handleLanguage={handleLanguage}
                          title='Send Test Sms'
                        >
                          <CardContent>
                            <HtmlEditor
                              ref={editorRef}
                              text={
                                selectedLanguage === "english"
                                  ? reminderTemplate?.template
                                  : selectedLanguage === "french"
                                  ? reminderTemplate?.templateFrench
                                  : selectedLanguage === "german"
                                  ? reminderTemplate?.templateGerman
                                  : ""
                              }
                              onChange={(text) => {
                                setEnableReloading(true);
                                switch (selectedLanguage) {
                                  case "english":
                                    setFieldValue("template", text);
                                    break;
                                  case "french":
                                    setFieldValue("templateFrench", text);
                                    break;
                                  case "german":
                                    setFieldValue("templateGerman", text);
                                    break;
                                  default:
                                    setFieldValue("template", text);
                                }
                                setEditorValue(text);
                                setEnableReloading(false);
                                handleSubmit();
                              }}
                              isLoading={false}
                              buttonText={getTranslation(
                                "Save",
                                "Save",
                                "Save"
                              )}
                              isSubmitting={loading}
                            />
                            <br />
                            <Box
                              pr={1}
                              pb={1}
                              width='100%'
                              display='flex'
                              alignItems='center'
                              justifyContent='center'
                            ></Box>
                          </CardContent>
                        </TextEditor>
                        <TestSmsDialoge
                          template={templateValue}
                          title='Send Test Message'
                          isOpen={testSmsDialoge}
                          onClose={() => setSmsDialoge(false)}
                          editor={editorValue}
                        />
                      </Grid>
                    </Grid>
                  </>
                </Form>
              </>
            )}
          </Formik>
        </FormLoader>
      </div>
      <br />
    </>
  );
};
