import {
	Box,
	Card,
	CardContent,
	CardHeader,
	Grid,
	IconButton,
	TextField,
	Tooltip,
	Typography,
} from "@material-ui/core";
import React from "react";
import { getTranslation } from "../../../../../../heplers/translationHelper";
import { Form, Formik } from "formik";
import ButtonWithLoading from "../../../../../../components/ButtonWithLoading";
import {
	Autocomplete,
	ToggleButton,
	ToggleButtonGroup,
} from "@material-ui/lab";
import TableComponent from "../../../../../../components/table";
import { useState } from "react";
import DialogComponent from "../../../../../../components/Dialog";
import Logo from "../../../../../../assests/icons/qollex-clicrent-logo.png";
import { DownloadButton } from "../../../../../../components/ButttonsWithIcons";
import { UploadFiles } from "../../../../../../components/Upload";
import AddNoteDialog from "./addNoteDialog";
import { useParams } from "react-router-dom";
import { DownloadFile, Get, Post } from "../../../../../../actions";
import {
	Get_AllFundActivitiesWithPagination_URL,
	Get_AllTanentsWithPagination_URL,
	Get_NotesWithPagination_URL,
	Get_Tanenet_URL,
	Get_TenantDuesSummary_URL,
	Post_AddNote_URL,
	Post_InsertNotes_URL,
	Post_PoneRecoveryProcedure_URL,
	Post_PostPoneDue_URL,
	Post_StartRecoveryProcedure_URL,
	GET_GetLatestFundActivity_URL,
} from "../../../../../../constants/apiUrls";
import { useEffect } from "react";
import { useSnackbar } from "notistack";
import { format } from "../../../../../../heplers/format";
import { PostponIcon } from "../../../../../../components/icons";
import RecoveryProcedureStepper from "../../components/recoveryProcedureStepper";
import { AddPostDueDate } from "../../../addPostponDueDate";

//notes columns
const columns = [
	{
		id: "date",
		label: getTranslation("Date", "Date", "Date"),
		align: "left",
		component: ({ row }) => {
			return <Typography>{format("date", row.date)}</Typography>;
		},
	},
	{
		id: "comments",
		label: getTranslation("Comment", "Comment", "Comment"),
		format: "left",
	},
	{
		id: "actions",
		label: getTranslation("Actions", "Actions", "Actions"),
		align: "right",
	},
];
const tenantColumns = [
	{
		id: "date",
		numeric: false,
		disablePadding: true,
		label: getTranslation("Date", "Date", "Date"),
		format: "date",
		component: ({ row }) => {
			return (
				<>
					<Typography>{format("date", row.date)}</Typography>
					<Typography variant="caption" style={{ color: "red" }}>
						{row.postponedReason}
					</Typography>
				</>
			);
		},
	},
	{
		id: "activity",
		numeric: false,
		disablePadding: true,
		label: getTranslation("Activity", "Type", "Typ"),
		component: ({ row }) => (
			<Typography>
				{" "}
				{getTranslation(row.activity, row.activity, row.activity)}
			</Typography>
		),
	},
	{
		id: "description",
		numeric: false,
		disablePadding: true,
		label: getTranslation("Description", "La description", "Beschreibung"),
		component: ({ row }) => {
			const descriptions = row.description
				.split(/\s*\d+\s*\|\s*/)
				.map((description) => description.trim());

			// Joining the updated descriptions
			const updatedDescription = descriptions.join(" | ");

			return (
				<Typography>
					{updatedDescription
						.replace("Amount from", "")
						.replace("Account", "")
						.replace("|", "")
						.replace("account", "")
						.replace(
							"Generated By Admin",
							getTranslation(
								"Generated By Admin",
								"Transaction générée par le systeme ",
								"Operation vom System generiert"
							)
						)}
				</Typography>
			);
		},
	},
	{
		id: "dueGenerated",
		numeric: true,
		disablePadding: true,
		label: getTranslation("Due", "Montant d�", "Zu zahlen"),
		format: "number",
		align: "right",
		component: ({ row }) => {
			return <Typography>{format("number", row?.dueGenerated)}</Typography>;
		},
	},
	{
		id: "amountDeposit",
		label: getTranslation("Bank", "Banque", "Bank"),
		format: "number",
		align: "right",
		component: ({ row }) => {
			return <Typography>{format("number", row?.amountDeposit)}</Typography>;
		},
	},
	{
		id: "actions",
		label: getTranslation("Action", "Action", "Action"),
		align: "right",
	},
];

const PhoneCallPublicScreen = () => {
	const [addNoteDialog, setaddNoteDialog] = useState("FirstPerson");
	const [addDetailsDialog, setAddNoteDialog] = useState(false);
	const [notesData, setNotesData] = useState();
	const [refreshNote, setRefreshNote] = useState(false);
	const [tenantDuesSummary, settenantDuesSummary] = useState();
	const [tanent, setTenant] = useState();
	const [dueList, setDueList] = useState();
	const [tenantDetailStatus, setTenantDetailStatus] = useState("all");
	const [page, setPage] = useState(0);
	const [pageNotes, setPageNotes] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(10);
	const [rowsPerPageNotes, setRowsPerPageNotes] = useState(10);
	const [isLoading, setIsLoading] = useState(false);
	const [startRecoveryDialog, setStartRecoveryDialog] = useState(false);
	const [callCenterComment, setCallCenterComment] = useState("");
	const [isOpenPostPone, setIsOpenPostPone] = useState(false);
	const [tanentList, setTanentList] = useState();
	const [notesLoading, setNotesLoading] = useState(false);
	const [postPoneLoading, setPostPoneLoading] = useState(false);
	const [fundActivityId, setFundActivityId] = useState();
	const { enqueueSnackbar } = useSnackbar();
	const { tenantId } = useParams();
	useEffect(() => {
		getNotesData();
		loadDues();
		GetLatesFundActivity();
		setRefreshNote(false);
	}, [
		refreshNote,
		tenantDetailStatus,
		page,
		rowsPerPage,
		pageNotes,
		rowsPerPageNotes,
	]);

	useEffect(() => {
		getTenantDuesSummary();
		getTenant();
		loadTenants();
		GetLatesFundActivity();
	}, []);

	//load all dues
	const loadDues = () => {
		setIsLoading(true);
		Get(
			{
				duescategory: tenantDetailStatus,
				pageNumber: page,
				pageSize: rowsPerPage,
				searchString: "",
				sort: "date",
				sortDirection: 1,
				tenantId: tenantId,
			},
			Get_AllFundActivitiesWithPagination_URL,
			null,
			(resp) => {
				setDueList(resp?.data);
				setIsLoading(false);
			},
			(error) => {
				enqueueSnackbar("Internal server Error", { variant: "error" });
				setIsLoading(false);
			}
		);
	};

	//get tenant details
	const getTenant = () => {
		Get(
			{ tenantId: tenantId },
			Get_Tanenet_URL,
			null,
			(resp) => {
				setTenant(resp?.data);
			},
			(err) => {
				enqueueSnackbar(
					err?.data
						? err?.data
						: getTranslation(
								"Something went wrong. Please contact with admin.",
								"Something went wrong. Please contact with admin.",
								"Something went wrong. Please contact with admin."
						  ),
					{
						variant: "error",
					}
				);
			}
		);
	};

	const GetLatesFundActivity = () => {
		Get(
			{ tenantId: tenantId },
			GET_GetLatestFundActivity_URL,
			null,
			(resp) => {
				setFundActivityId(resp.data?.fundActivityId);
			},
			(error) => {}
		);
	};

	//get all tenant
	const loadTenants = () => {
		setIsLoading(true);
		Get(
			{
				pageNumber: 0,
				pageSize: "10",
				searchString: "",
				sort: "name",
				sortDirection: 0,
				status: "active",
			},
			Get_AllTanentsWithPagination_URL,
			null,
			(resp) => {
				setIsLoading(false);
				setTanentList(resp?.data);
			},
			(error) => {
				enqueueSnackbar(
					getTranslation(
						"Can't load tenants. Internal server error",
						"Can't load tenants. Internal server error",
						"Can't load tenants. Internal server error"
					),
					{
						variant: "error",
					}
				);
				setIsLoading(false);
			}
		);
	};

	const getNotesData = () => {
		setIsLoading(true);
		Get(
			{
				tenantId: tenantId,
				type: 1,
				pageNumber: pageNotes,
				pageSize: rowsPerPageNotes,
				searchString: "",
			},
			Get_NotesWithPagination_URL,
			null,
			(resp) => {
				setNotesData(resp?.data);
				setIsLoading(false);
			},
			(err) => {
				enqueueSnackbar(
					err?.data
						? err?.data
						: getTranslation(
								"Something went wrong. Please contact with admin.",
								"Something went wrong. Please contact with admin.",
								"Something went wrong. Please contact with admin."
						  ),
					{
						variant: "error",
					}
				);
				setIsLoading(false);
			}
		);
	};
	//post recovery procedure
	const postRecoveryProcedure = (payload) => {
		setIsLoading(true);
		Post(
			payload,
			Post_StartRecoveryProcedure_URL,
			null,
			(resp) => {
				enqueueSnackbar("Recovery Procedure Added successfully ", {
					variant: "success",
				});
				setIsLoading(false);
				setStartRecoveryDialog(false);
			},
			(err) => {
				enqueueSnackbar(
					err?.data
						? err?.data
						: getTranslation(
								"Something went wrong. Please try again.",
								"Something went wrong. Please try again.",
								"Something went wrong. Please try again."
						  ),
					{
						variant: "error",
					}
				);
				setIsLoading(false);
			}
		);
	};

	//handle change page size
	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};
	const handleChangePageNotes = (event, newPage) => {
		setPageNotes(newPage);
	};
	//set rowperpage
	const handleChangeRowsPerPage = (event) => {
		console.log(event.target.value);
		localStorage.setItem("sizeDue", parseInt(event.target.value));
		setRowsPerPage(localStorage.getItem("sizeDue"));
		setPage(0);
	};
	const handleChangeRowsPerPageNotes = (event) => {
		console.log(event.target.value);
		localStorage.setItem("sizeNotes", parseInt(event.target.value));
		setRowsPerPageNotes(localStorage.getItem("sizeNotes"));
		setPageNotes(0);
	};

	const starrtRecoveryPRocedure = () => {
		setStartRecoveryDialog(true);
	};

	const postNotesCommment = () => {
		if (!callCenterComment) {
			enqueueSnackbar(
				getTranslation(
					"Comments Cant Not be empty",
					"Comments Cant Not be empty",
					"Comments Cant Not be empty"
				),
				{
					variant: "error",
				}
			);
			return;
		}
		setNotesLoading(true);
		Post(
			{
				comments: `${callCenterComment} (Added by call center)`,
				date: new Date(),
				tenantId: tenantId,
				type: 1,
				document: "",
			},
			Post_InsertNotes_URL,
			null,
			(resp) => {
				enqueueSnackbar("Your Comment is added successfully", {
					variant: "success",
				});
				setRefreshNote(true);
				setCallCenterComment("");
				setNotesLoading(false);
			},
			(err) => {
				setNotesLoading(false);
				enqueueSnackbar(
					err.data
						? err.data
						: getTranslation(
								"Something went wrong. Please try again.",
								"Something went wrong. Please try again.",
								"Something went wrong. Please try again."
						  ),
					{
						variant: "error",
					}
				);
			}
		);
	};

	//tenant dues summay
	const getTenantDuesSummary = () => {
		Get(
			{ tenantId: tenantId },
			Get_TenantDuesSummary_URL,
			null,
			(res) => {
				settenantDuesSummary(res?.data);
			},
			(err) => {
				enqueueSnackbar(
					err.data
						? err.data
						: getTranslation(
								"Something went wrong. Please try again.",
								"Something went wrong. Please try again.",
								"Something went wrong. Please try again."
						  ),
					{
						variant: "error",
					}
				);
			}
		);
	};

	//Post postpone dues
	const handleAddPostpone = () => {
		setIsOpenPostPone(true);
	};

	const handleSubmitPostpone = (values, actions) => {
		setIsLoading(true);
		values.id = fundActivityId;
		Post(
			values,
			Post_PostPoneDue_URL,
			null,
			(resp) => {
				// setAddPostponDueDate(false);
				enqueueSnackbar(resp.data, { variant: "success" });
				setIsLoading(false);
				setIsOpenPostPone(false);
				setRefreshNote(true);
			},
			(err) => {
				// enqueueSnackbar(err.data, { variant: "error" });
				// setIsLoading(false);
				console.log(err);
			}
		);
	};

	return (
		<div style={{ margin: "30px" }}>
			<div
				style={{
					marginBottom: "30px",
					display: "flex",
					justifyContent: "center",
				}}
			>
				<img src={Logo} width="8%" height="8%" />
			</div>

			{/* Tenant Details */}
			<Card>
				<CardContent>
					<Grid
						container
						style={{
							marginTop: "10px",
						}}
					>
						<Grid item xs={12} sm={10} md={10} lg={8} xl={6}>
							<Typography style={{ fontWeight: "bold" }}>
								{getTranslation("Tenant", "Tenant", "Tenant")}
							</Typography>

							<div style={{ display: "flex" }}>
								<div style={{ marginLeft: "30px" }}>
									<Typography variant="h5">
										{getTranslation("Name", "Name", "Name")} :
									</Typography>
									<Typography variant="h5">
										{getTranslation(
											"Building Name",
											"Building Name",
											"Building Name"
										)}{" "}
										:
									</Typography>
									<Typography variant="h5">
										{getTranslation(
											"Apartment Number",
											"Apartment Number",
											"Apartment Number"
										)}{" "}
										:
									</Typography>
									<Typography variant="h5">
										{getTranslation("Email", "Email", "Email")} :
									</Typography>
									<Typography variant="h5">
										{getTranslation("Telephone", "Telephone", "Telephone")} :
									</Typography>
									<Typography variant="h5">
										{getTranslation("Address", "Address", "Address")} :
									</Typography>
								</div>
								<div
									style={{
										marginLeft: "30px",
										display: "flex",
										flexDirection: "column",
										//alignItems: "flex-end",
									}}
								>
									<Typography variant="h5">{tanent?.name}</Typography>
									<Typography variant="h5">{tanent?.buildingName}</Typography>
									<Typography variant="h5">{tanent?.apartmentNo}</Typography>
									<Typography variant="h5">{tanent?.email}</Typography>
									<Typography variant="h5">
										{tanent?.telephone ? tanent?.telephone : "-"}
									</Typography>
									<Typography variant="h5">
										{tanent?.aparmentAddress ? tanent?.aparmentAddress : "-"}
									</Typography>
								</div>
							</div>
							{/* <Box style={{ marginLeft: "30px" }}>
                <Typography variant='h5'>{tanent?.name}</Typography>
                <Typography variant='h5'>{tanent?.buildingName}</Typography>
                <Typography variant='h5'>{tanent?.apartmentNo}</Typography>
                <Typography variant='h5'>{tanent?.email}</Typography>
                <Typography variant='h5'>{tanent?.telephone}</Typography>
              </Box> */}
							{/* </td> */}
							{/* </tr> */}
						</Grid>

						<Grid item xs={12} sm={4} md={4} lg={4} xl={3}>
							<Typography style={{ fontWeight: "bold" }}>
								{getTranslation(
									"Recovery Info",
									"Recovery Info",
									"Recovery Info"
								)}
							</Typography>
							<div style={{ display: "flex" }}>
								<div style={{ marginLeft: "30px" }}>
									<Typography variant="h5">
										{getTranslation("Start Date", "Start Date", "Start Date")} :
									</Typography>
									<Typography variant="h5">
										{getTranslation("End Date", "End Date", "End Date")} :
									</Typography>
									<Typography variant="h5">
										{getTranslation("Rent", "Rent", "Rent")} :
									</Typography>
								</div>
								<div
									style={{
										marginLeft: "30px",
										display: "flex",
										flexDirection: "column",
										alignItems: "flex-end",
									}}
								>
									<Typography variant="h5">
										{format(
											"date",
											tanent?.contracts[tanent?.contracts.length - 1].startDate
										)}
									</Typography>
									<Typography variant="h5">
										{format(
											"date",
											tanent?.contracts[tanent?.contracts.length - 1].endDate
										)}
									</Typography>
									<Typography variant="h5">
										{format(
											"number",
											tanent?.contracts[tanent?.contracts.length - 1]
												.currentRent
										)}
									</Typography>
								</div>
							</div>
						</Grid>

						<Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
							<Typography style={{ fontWeight: "bold" }}>
								{getTranslation("Dues", "Dues", "Dues")}
							</Typography>
							<div style={{ display: "flex" }}>
								<div style={{ marginLeft: "30px" }}>
									<Typography variant="h5">
										{getTranslation("Total Rent", "Total Rent", "Total Rent")}:
									</Typography>

									<Typography variant="h5">
										{getTranslation("Total Cost", "Total Cost", "Total Cost")} :
									</Typography>
									<Typography variant="h5">
										{getTranslation(
											"Total warranty",
											"Total warranty",
											"Total warranty"
										)}{" "}
										:
									</Typography>
									<Typography variant="h5">
										{getTranslation(
											"Total Maintenance",
											"Total Maintenance",
											"Total Maintenance"
										)}{" "}
										:
									</Typography>
								</div>
								<div
									style={{
										marginLeft: "30px",
										display: "flex",
										flexDirection: "column",
										alignItems: "flex-end",
									}}
								>
									<Typography variant="h5">
										{format("number", tenantDuesSummary?.totalRent)}
									</Typography>

									<Typography variant="h5">
										{format("number", tenantDuesSummary?.totalCost)}
									</Typography>
									<Typography variant="h5">
										{format("number", tenantDuesSummary?.totalWarranty)}
									</Typography>
									<Typography variant="h5">
										{format(
											"number",
											tenantDuesSummary?.totalRepairAndMaintenance
										)}
									</Typography>
								</div>
							</div>
						</Grid>
					</Grid>
				</CardContent>
			</Card>

			{/* tenant dues table and filter  */}

			<Card style={{ marginTop: "20px", marginBottom: "20px" }}>
				<CardHeader title={getTranslation("Dues", "Dues", "Dues")} />
				<ToggleButtonGroup
					size="medium"
					style={{
						display: "flex",
						alignItems: "center",
						justifyContent: "center",
					}}
					id="tenantDetailStatus"
					value={tenantDetailStatus}
					exclusive
					onChange={(event, tenantDetailStatus) => {
						if (tenantDetailStatus !== null) {
							setTenantDetailStatus(tenantDetailStatus);
						}
					}}
				>
					<ToggleButton value={"all"} aria-label="left aligned">
						<Typography>{getTranslation("All", "All", "All")}</Typography>
					</ToggleButton>
					<ToggleButton value={"rent"} aria-label="left aligned">
						<Typography>{getTranslation("Rent", "Rent", "Rent")}</Typography>
					</ToggleButton>
					<ToggleButton value={"cost"} aria-label="centered">
						<Typography>{getTranslation("Cost", "Cost", "Cost")}</Typography>
					</ToggleButton>
					<ToggleButton value={"warranty"} aria-label="centered">
						<Typography>
							{getTranslation("Warranty", "Warranty", "Warranty")}
						</Typography>
					</ToggleButton>
					<ToggleButton value={"maintenance"} aria-label="centered">
						<Typography>
							{getTranslation("Maintenance", "Maintenance", "Maintenance")}
						</Typography>
					</ToggleButton>
				</ToggleButtonGroup>
				<br />
				<CardContent>
					{/* dues table  */}
					<TableComponent
						loading={isLoading}
						dense
						title={getTranslation("Dues", "Dues", "Dues")}
						colums={tenantColumns}
						rows={dueList?.data}
						count={dueList?.totalCount}
						page={page}
						rowsPerPage={rowsPerPage}
						handleChangePage={handleChangePage}
						handleChangeRowsPerPage={handleChangeRowsPerPage}
						viewSearch
						actions={[
							{
								component: (row) =>
									row.pdfDocumentId != "" ? (
										<Tooltip
											title={getTranslation("Note", "Noter", "Notiz")}
											arrow
											placement="top"
										>
											<DownloadButton
												onClick={() => DownloadFile(row?.pdfDocumentId)}
											/>
										</Tooltip>
									) : null,
							},
						]}
					/>
				</CardContent>
			</Card>

			{/* note table */}
			{/* <TableComponent
        loading={isLoading}
        dense
        title={getTranslation("Notes", "Notes", "Notes")}
        colums={columns}
        rows={notesData}
        count={notesData?.length}
        viewSearch
        viewCreate
        page={page}
        rowsPerPage={rowsPerPage}
        handleChangeRowsPerPage={handleChangeRowsPerPageNotes}
        onCreate={() => {
          setAddNoteDialog(true);
        }}
        actions={[
          {
            component: (row) =>
              row.document != "" ? (
                <Tooltip
                  title={getTranslation("Note", "Noter", "Notiz")}
                  arrow
                  placement='top'
                >
                  <DownloadButton
                    onClick={() => DownloadFile(row?.pdfDocumentId)}
                  />
                </Tooltip>
              ) : null,
          },
        ]}
      /> */}
			<TableComponent
				loading={isLoading}
				dense
				title={getTranslation("Notes", "Notes", "Notes")}
				colums={columns}
				rows={notesData?.data}
				count={notesData?.totalCount}
				page={pageNotes}
				rowsPerPage={rowsPerPageNotes}
				handleChangePage={handleChangePageNotes}
				handleChangeRowsPerPage={handleChangeRowsPerPageNotes}
				viewSearch
				actions={[
					{
						component: (row) =>
							row.document !== "" ? (
								<Tooltip
									title={getTranslation("Note", "Noter", "Notiz")}
									arrow
									placement="top"
								>
									<DownloadButton onClick={() => DownloadFile(row?.document)} />
								</Tooltip>
							) : null,
					},
				]}
			/>

			<Box
				style={{
					display: "flex",
					justifyContent: "space-around",
					marginTop: "20px",
				}}
			>
				<TextField
					style={{ marginTop: "20ox" }}
					fullWidth
					id="comments"
					label={getTranslation("Comment", "Comment", "Comment")}
					size="small"
					variant="outlined"
					multiline
					rows={5}
					name="comments"
					value={callCenterComment}
					onChange={(e) => {
						setCallCenterComment(e.target.value);
					}}
					// onBlur={postNotesCommment}
					InputLabelProps={{
						shrink: true,
					}}
				/>
			</Box>

			<Box
				style={{
					display: "flex",
					justifyContent: "center",
					marginTop: "20px",
				}}
			>
				<ButtonWithLoading
					title={getTranslation("Add Comment", "Add Comment", "Add Comment")}
					size="medium"
					variant="contained"
					type="submit"
					loading={notesLoading}
					style={{ backgroundColor: "rgb(229,60,22)", color: "white" }}
					onClick={postNotesCommment}
				/>
			</Box>

			<Box
				style={{
					display: "flex",
					justifyContent: "space-around",
					marginTop: "8px",
					marginBottom: "20px",
				}}
			>
				<CardContent>
					<ButtonWithLoading
						title={getTranslation("Postpone", "Postpone", "Postpone")}
						size="medium"
						variant="contained"
						type="submit"
						style={{ backgroundColor: "rgb(229,60,22)", color: "white" }}
						onClick={handleAddPostpone}
					/>{" "}
					<ButtonWithLoading
						title={getTranslation(
							"Start Payment Plan",
							"Start Payment Plan",
							"Start Payment Plan"
						)}
						size="medium"
						variant="contained"
						type="submit"
						style={{ backgroundColor: "rgb(229,60,22)", color: "white" }}
					/>{" "}
					<ButtonWithLoading
						title={getTranslation(
							"Start Recovery Procedure",
							"Start Recovery Procedure",
							"Start Recovery Procedure"
						)}
						size="medium"
						variant="contained"
						type="submit"
						style={{ backgroundColor: "rgb(229,60,22)", color: "white" }}
						onClick={starrtRecoveryPRocedure}
					/>
				</CardContent>
			</Box>

			{/* add note dialog */}
			{/* <DialogComponent
        open={addDetailsDialog}
        onClose={() => setAddNoteDialog(false)}
        title={getTranslation("Add Notes", "Add Notes", "Add Notes")}
        fullWidth
      >
        <AddNoteDialog
          setAddNoteDialog={setAddNoteDialog}
          type={1}
          tenantId={tenantId}
          setRefresh={setRefreshNote}
        />
      </DialogComponent> */}

			{/* //recoveryprocedure dialog  */}
			<DialogComponent
				fullWidth
				maxWidth="lg"
				title={getTranslation(
					"Start Recovery Procedure",
					"Start Recovery Procedure",
					"Start Recovery Procedure"
				)}
				open={startRecoveryDialog}
				onClose={() => {
					setStartRecoveryDialog(false);
				}}
			>
				<RecoveryProcedureStepper
					tenantDetailLis={tanentList}
					tenantData={tanent}
					handleSubmit={postRecoveryProcedure}
					isLoading={isLoading}
					phoneCall={true}
				/>
			</DialogComponent>

			{/* post pone dialog  */}
			<AddPostDueDate
				isOpen={isOpenPostPone}
				onClose={() => {
					setIsOpenPostPone(false);
				}}
				onSubmit={handleSubmitPostpone}
				isLoading={isLoading}
			/>
		</div>
	);
};
export default PhoneCallPublicScreen;
