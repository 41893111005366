import React from "react";
import { makeStyles, Typography, Box } from "@material-ui/core";
import Moment from "moment";
import { getTranslation } from "../../../../../heplers/translationHelper";
import { ReportLayout, reportStyles } from "../../../../report/ReportLayout";
import _ from "lodash";
import renderHTML from "react-render-html";
import authService from "../../../../../utils/authUtils";
import { LetterReportLayout } from "../../../../report/LetterReportLayou";

const PrintLetterDialog = React.forwardRef(({ rows, tenant, activeContract, sendByPost, message }, ref) => {
  const classes = reportStyles();
  const noData = typeof rows === "undefined";
  var organization=authService.getCustomerOrganizations()?.find(x=>x.customerId==authService.getCustomerId());
  return (
  <LetterReportLayout ref={ref} noData={noData} tenant={tenant}>
      {sendByPost?
            <div style={{marginBottom:'20px'}} >
              <Typography className={classes.titleStyles}>
                {getTranslation("Message","Message","Message")}:</Typography>
              {renderHTML(message.replace("[zip_code]",tenant?.buildingZipCode).replace("[appartment_address]",tenant?.aparmentAddress)
              .replace("[town]",tenant?.buildingTown).replace("due_Amount",tenant?.accountBalance)
              )}
            </div>:null}
        
        <table className={classes.table} style={{pageBreakBefore:"always"}}>
          <thead>
            <tr>
              <th>
                <Typography className={classes.titleStyles} align="left">
                  {getTranslation(
                    "Date",
                    "Date",
                    "Date"
                  )}</Typography>
              </th>
              <th>
                <Typography className={classes.titleStyles} align="left">
                  {getTranslation("Description", "La description", "Beschreibung")}</Typography>

              </th>
              <th style={{ textAlign: "right" }}>
                <Typography className={classes.titleStyles}>
                  {getTranslation("Due", "Payable", "F�llig")}</Typography>

              </th>
              <th style={{ textAlign: "right" }}>
                <Typography className={classes.titleStyles}>
                  {getTranslation("Bank", "Banque", "Bank")}</Typography>

              </th>
            </tr>
          </thead>
          <tbody>
            {rows?.data?.map((item) => (
              <tr>
                <td>
                  <Typography className={classes.subtitle}>

                    {Moment(item.date).format("DD-MM-YYYY")}
                  </Typography>
                  <Typography style={{
                    color: 'red',
                    fontSize: '9px'
                  }}>
                    {item.postponedReason}
                  </Typography>
                </td>
                <td style={{ verticalAlign: 'top' }}>
                  <Typography className={classes.subtitle} align="left">

                    {item.description.replace("Amount from","")
                      .replace("account","")
                      .replace("Account","")
                      .replace("Generated By Admin",getTranslation("Generated By Admin","Transation générée par le system ","Operation vom System generiert"))}
                    </Typography>
                </td>
                <td align="right" style={{ verticalAlign: 'top' }}>
                  <Typography className={classes.subtitle}>
                    {item.dueGenerated.toFixed(2)}
                  </Typography>
                </td>
                <td align="right" style={{ verticalAlign: 'top' }}>
                  <Typography className={classes.subtitle}>
                    {item.amountDeposit.toFixed(2)}
                  </Typography>
                </td>
              </tr>
            ))}
          </tbody>
          <tfoot>
            <tr>
              <td colSpan={4} style={{ height: "14px" }}></td>
            </tr>
            <tr>
              <td align={"center"} style={{
                background: "#233044",
                color: "#fff",
              }}>

                <Typography className={classes.endCards} align="left"
                >
                  {getTranslation(" Total ", " Totale ", " Gesamt ")}
                </Typography>

              </td>
              <td style={{
                background:"#233044",
                color: "#fff",
              }}></td>
              <td align="right" style={{
                background: "#EB5C3E",
                color: "#fff",
              }}>
                <Typography className={classes.endCards}>
                  &euro;{" "}
                  {_.sumBy(rows?.data, "dueGenerated").toFixed(2)}
                </Typography>
              </td>
              <td align="right" style={{
                background: "#EB5C3E",
                color: "#fff",
              }}>
                <Typography className={classes.endCards}>
                  &euro;{" "}
                  {_.sumBy(rows?.data, "amountDeposit").toFixed(2)}
                </Typography>
              </td>
            
            </tr>
          </tfoot>
        </table>
    
  
  </LetterReportLayout>
  );
});

export default PrintLetterDialog;
