import {
	AddDetails,
	Banks,
	PrintDialog,
	Dues,
	TenantNotes,
} from "./components";
import React, { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import { useHistory, useLocation } from "react-router";
import { Get, Post } from "../../../../actions";
import {
	Get_AllContractsWithPagination_URL,
	Get_AllFundActivitiesWithPagination_URL,
	Get_Tanenet_URL,
	Get_TenantDuesSummary_URL,
	Post_UpdateTanents_URL,
} from "../../../../constants/apiUrls";
import { useSnackbar } from "notistack";
import { useReactToPrint } from "react-to-print";
import { ContractsTable } from "./components/contracts";
import { getTranslation } from "../../../../heplers/translationHelper";

//state management
const List = () => {
	const [dueList, setDueList] = useState([]);
	const [duesLoading, setDuesLoadign] = useState(false);
	const [activeContract, setActiveContract] = useState(null);
	const [tenantDuesSummary, setTenanDuesSummary] = useState();
	const [isLoading, setIsLoading] = useState(false);
	const { tenantId } = useParams();
	const location = useLocation();
	const [filterItems, setFilterItems] = useState({
		searchString: "",
		pageNumber: 0,
		pageSize: 10,
		sort: "date",
		sortDirection: 1,
		tenantId: tenantId,
		duescategory: "all",
	});
	const [printDueList, setPrintDueList] = useState([]);

	const loadTenantContracts = () => {
		Get(
			{ tenantId: tenantId },
			Get_AllContractsWithPagination_URL,
			null,
			(resp) => {
				let active = resp?.data?.data.find((x) => x.status == 2);
				if (active) {
					setActiveContract(active);
				} else {
					setActiveContract(resp?.data?.data.find((x) => x.status == 0));
				}
			},
			(error) => {
				enqueueSnackbar(error.data, { variant: "error" });
			}
		);
	};

	const history = useHistory();
	const { enqueueSnackbar } = useSnackbar();

	const componentRef = useRef();

	const handlePrint = useReactToPrint({
		documentTitle: getTranslation(
			"Tenant Report",
			"Tenant Report",
			"Tenant Report"
		),
		content: () => componentRef.current,
	});

	const [tenant, setTenant] = useState();

	useEffect(() => {
		getTenant(tenantId);
		getTenantDuesSummary();
		loadTenantContracts();
		loadDues(filterItems);
		loadAllDues(filterItems);
	}, [tenantId]);

	const getTenant = (values) => {
		Get(
			{ tenantId: values },
			Get_Tanenet_URL,
			history,
			(resp) => {
				setTenant(resp?.data);
			},
			(error) => {
				enqueueSnackbar(error?.data, { variant: "error" });
			}
		);
	};

	const getTenantDuesSummary = () => {
		Get(
			{ tenantId: tenantId },
			Get_TenantDuesSummary_URL,
			null,
			(res) => {
				setTenanDuesSummary(res?.data);
			},
			(err) => {
				enqueueSnackbar(
					err.data
						? err.data
						: getTranslation(
								"Something went wrong. Please try again.",
								"Something went wrong. Please try again.",
								"Something went wrong. Please try again."
						  ),
					{
						variant: "error",
					}
				);
			}
		);
	};

	const loadDues = (values) => {
		setFilterItems(values);
		setDuesLoadign(true);
		Get(
			values,
			Get_AllFundActivitiesWithPagination_URL,
			history,
			(resp) => {
				setDuesLoadign(false);
				setDueList(resp?.data);
			},
			(error) => {
				enqueueSnackbar("Internal server Error", { variant: "error" });
			}
		);
	};
	// load all dues without pagination
	const loadAllDues = (values) => {
		values.pageSize = 100;
		values.sortDirection = 1;
		Get(
			values,
			Get_AllFundActivitiesWithPagination_URL,
			null,
			(resp) => {
				setPrintDueList(resp?.data);
			},
			(error) => {
				enqueueSnackbar("Internal server Error", { variant: "error" });
			}
		);
	};

	const handleEditTenant = (tenant) => {
		setTenant(tenant);
	};
	const updateTenant = (values, actions) => {
		setIsLoading(true);
		Post(
			values,
			Post_UpdateTanents_URL,
			history,
			(resp) => {
				actions.setSubmitting(false);
				enqueueSnackbar("Tenant updated", {
					variant: "success",
				});
				setIsLoading(false);
				history.push("/tanents");
			},
			(error) => {
				enqueueSnackbar(error?.data, {
					variant: "error",
				});
				setIsLoading(false);
			}
		);
	};

	return (
		<div>
			{location.pathname.endsWith("/details") && (
				<AddDetails
					onSubmit={updateTenant}
					onEditTenant={handleEditTenant}
					printDetails={handlePrint}
					tenant={tenant}
					isLoading={isLoading}
					activeContract={activeContract}
				/>
			)}
			{location.pathname.endsWith("/contracts") && (
				<ContractsTable
					setActiveContract={(contract) => {
						setActiveContract(contract);
					}}
				/>
			)}
			{location.pathname.endsWith("/banks") && (
				<Banks
					tenantId={tenantId}
					loading={duesLoading}
					rows={dueList}
					onFilter={(values) => {
						loadDues(values);
					}}
				/>
			)}

			{location.pathname.endsWith("/dues") && (
				<Dues
					tenantId={tenantId}
					loading={duesLoading}
					printDetails={handlePrint}
					rows={dueList}
					onFilter={(values) => {
						loadDues(values);
					}}
				/>
			)}

			{location.pathname.endsWith("/notes") && (
				<TenantNotes
					tenantId={tenantId}
					loading={duesLoading}
					rows={dueList}
					onFilter={(values) => {
						loadDues(values);
					}}
				/>
			)}
			<div style={{ display: "none" }}>
				<PrintDialog
					activeContract={activeContract}
					tenant={tenant}
					rows={printDueList}
					tenantDuesSummary={tenantDuesSummary}
					filterItems={filterItems}
					ref={componentRef}
				/>
			</div>
		</div>
	);
};
export default List;
