import React, { useState } from "react";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { getTranslation } from "../../../heplers/translationHelper";
import TableComponent from "../../../components/table";
import { Helmet } from "react-helmet";
import { Get, Post } from "../../../actions";
import {
	Get_AllIndexationProposalsWithPagination_URL,
	Post_ConfirmIndexationProposals_URL,
} from "../../../constants/apiUrls";
import { useSnackbar } from "notistack";
import { format } from "../../../heplers/format";
import { TextField, Typography } from "@material-ui/core";
import AlertDialog from "../../../components/DeleteDialog";
import DashBoardDataTable from "../../dashboard/components/dashbordDataTable";

const Indexation = () => {
	const [tabValue, setTabValue] = useState("0");
	const [indexationList, setIndexationList] = useState([]);
	const [recoveryList, setRecoveryList] = useState([]);
	const [indexationListCount, setIndexationListCount] = useState([]);
	const [indexationListData, setIndexationLisData] = useState([]);
	const [dueList, setDueList] = useState([]);

	const handleChange = (event, newValue) => {
		setTabValue(newValue);
	};

	return (
		<div>
			<Helmet>
				<title>
					{getTranslation("Indexation", "Indexation", "Indexation")}
				</title>
			</Helmet>

			<DashBoardDataTable
				dataType={tabValue}
				setRecoveryList={setRecoveryList}
				setIndexationList={setIndexationList}
				setIndexationListCount={setIndexationListCount}
				recoveryList={recoveryList}
				indexationList={indexationList}
				indexationListCount={indexationListCount}
				dueList={dueList}
				setDueList={setDueList}
			/>
		</div>
	);
};

export default Indexation;
