import {
  Grid,
  TextField,
  Box,
  FormControl,
  FormControlLabel,
  Checkbox,
  Typography,
  InputLabel,
  Select,
  MenuItem,
  FormLabel,
  FormGroup,
  Button,
} from "@material-ui/core";
import { Form, Formik } from "formik";
import ButtonWithLoading from "../../../components/ButtonWithLoading";
import * as Yup from "yup";
import { UploadFiles } from "../../../components/Upload";
import DialogComponent from "../../../components/Dialog";
import { getTranslation } from "../../../heplers/translationHelper";
import MaskedInput from "react-text-mask";
import { Get, Post } from "../../../actions";
import { useState } from "react";
import { Get_GetSMS_URL, Post_SendSMS_URL } from "../../../constants/apiUrls";
import { useSnackbar } from "notistack";
import authService from "../../../utils/authUtils";
import {
  APP_MANAGER_SERVER_GETSMS_URL,
  APP_MANAGER_SERVER_SENDSMS_URL,
} from "../../../constants";
import { DatePicker } from "../../../components/DatePicker";
import {
  PhoneInput,
  defaultCountries,
  parseCountry,
} from "react-international-phone";
import "react-international-phone/style.css";
import { PhoneNumberUtil } from "google-libphonenumber";
import { MuiPhone } from "../../../components/MuiPhone";
import { Autocomplete } from "@material-ui/lab";

const phoneUtil = PhoneNumberUtil.getInstance();

const isPhoneValid = (phone) => {
  try {
    return phoneUtil.isValidNumber(phoneUtil.parseAndKeepRawInput(phone));
  } catch (error) {
    return false;
  }
};

const countries = defaultCountries.filter((country) => {
  const { iso2 } = parseCountry(country);
  return ["de", "pk"].includes(iso2);
});

function TextMaskCustom(props) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[
        /\+/,
        /\d/,
        /\d/,
        /\d/,
        " ",
        /\d/,
        /\d/,
        /\d/,
        " ",
        /\d/,
        /\d/,
        /\d/,
        " ",
        /\d/,
        /\d/,
        /\d/,
      ]}
      showMask
    />
  );
}

const AddTanentDialog = ({ onSubmit, isOpen, onClose, isLoading }) => {
  const selectDate = [
    {
      id: 1,
      day: getTranslation("1st", "1st", "1st"),
    },
    {
      id: 2,
      day: getTranslation("2nd", "2nd", "2nd"),
    },
    {
      id: 3,
      day: getTranslation("3rd", "3rd", "3rd"),
    },
    {
      id: 4,
      day: getTranslation("4th", "4th", "4th"),
    },
    {
      id: 5,
      day: getTranslation("5th", "5th", "5th"),
    },
    {
      id: 6,
      day: getTranslation("6th", "6th", "6th"),
    },
    {
      id: 7,
      day: getTranslation("7th", "7th", "7th"),
    },
    {
      id: 8,
      day: getTranslation("8th", "8th", "8th"),
    },
    {
      id: 9,
      day: getTranslation("9th", "9th", "9th"),
    },
    {
      id: 10,
      day: getTranslation("10th", "10th", "10th"),
    },
    {
      id: 11,
      day: getTranslation("11th", "11th", "11th"),
    },
    {
      id: 12,
      day: getTranslation("12th", "12th", "12th"),
    },
    {
      id: 13,
      day: getTranslation("13th", "13th", "13th"),
    },
    {
      id: 14,
      day: getTranslation("14th", "14th", "14th"),
    },
    {
      id: 15,
      day: getTranslation("15th", "15th", "15th"),
    },
    {
      id: 16,
      day: getTranslation("16th", "16th", "16th"),
    },
    {
      id: 17,
      day: getTranslation("17th", "17th", "17th"),
    },
    {
      id: 18,
      day: getTranslation("18th", "18th", "18th"),
    },
    {
      id: 19,
      day: getTranslation("19th", "19th", "19th"),
    },
    {
      id: 20,
      day: getTranslation("20th", "20th", "20th"),
    },
    {
      id: 21,
      day: getTranslation("21st", "21st", "21st"),
    },
    {
      id: 22,
      day: getTranslation("22nd", "22nd", "22nd"),
    },
    {
      id: 23,
      day: getTranslation("23rd", "23rd", "23rd"),
    },
    {
      id: 24,
      day: getTranslation("24th", "24th", "24th"),
    },
    {
      id: 25,
      day: getTranslation("25th", "25th", "25th"),
    },
    {
      id: 26,
      day: getTranslation("26th", "26th", "26th"),
    },
    {
      id: 27,
      day: getTranslation("27th", "27th", "27th"),
    },
    {
      id: 28,
      day: getTranslation("28th", "28th", "28th"),
    },
    {
      id: 29,
      day: getTranslation("29th", "29th", "29th"),
    },
    {
      id: 30,
      day: getTranslation("30th", "30th", "30th"),
    },
    {
      id: 31,
      day: getTranslation("31st", "31st", "31st"),
    },
  ];
  const languages = ["English", "French", "German"];
  const { enqueueSnackbar } = useSnackbar();

  const customerId = authService.getCustomerId();

  const [verified, setVerified] = useState(false);
  const [isDisable, setIsDisable] = useState(false);
  const [btnVisiable, setBtnVisiable] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [isValid, setIsValid] = useState(false);
  const [startContractLoading, setStartContractLoading] = useState(false);
  const [phone, setPhone] = useState("");

  const isPhoneValid = (phone) => {
    try {
      return phoneUtil.isValidNumber(phoneUtil.parseAndKeepRawInput(phone));
    } catch (error) {
      return false;
    }
  };

  const handlePhoneChange = (value) => {
    setIsValid(isPhoneValid(value));
    setPhoneNumber(value);
  };

  const rentDueOptions = () => {
    return selectDate.map((item) => {
      return <MenuItem value={item.id}>{item.day}</MenuItem>;
    });
  };
  const callGetSms = (smsSid) => {
    setTimeout(getSms(smsSid), 3000);
  };

  const getSms = (payload) => {
    Get(
      { SmsSid: payload },
      APP_MANAGER_SERVER_GETSMS_URL,
      null,
      (resp) => {
        switch (resp?.data) {
          case 1:
            callGetSms(payload);
            break;
          case 4:
            enqueueSnackbar("Verification faild please Verify again", {
              variant: "error",
            });
            setVerified(false);
            setIsDisable(true);
            break;
          case 5:
            enqueueSnackbar("Your phone number is verified", {
              variant: "success",
            });
            setVerified(true);
            setIsDisable(false);
            break;
          case 6:
            enqueueSnackbar(
              "Your phone number is not verified.please try another number",
              { variant: "error" }
            );
            setVerified(false);
            setIsDisable(true);
            break;
          default:
            callGetSms(payload);
            break;
        }
      },
      (error) => {
        enqueueSnackbar("verification failed", { variant: "error" });
      }
    );
  };

  const sendSms = (payload) => {
    Post(
      payload,
      APP_MANAGER_SERVER_SENDSMS_URL,
      null,
      (resp) => {
        if (resp?.data == null) {
          enqueueSnackbar("Twilio cannot send your message please try again", {
            variant: "error",
          });
        } else {
          callGetSms(resp?.data);
          setIsDisable(false);
        }
      },
      (error) => {
        enqueueSnackbar("Twilio verification failed please try again", {
          variant: "error",
        });
      }
    );
  };

  const initialValues = {
    firstName: "",
    lastName: "",
    idCard: "",
    telephone: "+",
    email: "",
    isActive: false,
    reminderType: "",
    rentDueDay: 1,
    language: "",
    enableAutoReminder: true,
    files: [],
    startContract: false,
    ListOfSendReminderBy: [],
    comments: "",
    birthDate: new Date(),
  };

  const basicValidationSchema = Yup.object().shape({
    firstName: Yup.string()
      .matches(
        /^[a-zA-Z\sÀ-ÿ\u00C0-\u00FF\u0100-\u017F\u0180-\u024F]+$/,
        "Only English, French, and German characters are allowed"
      )
      .min(3, "Minimum 3 characters is required")
      .max(20, "Maximum 20 characters")
      .required("First name is required"),
    lastName: Yup.string()
      .matches(
        /^[a-zA-Z\sÀ-ÿ\u00C0-\u00FF\u0100-\u017F\u0180-\u024F]+$/,
        "Only English, French, and German characters are allowed"
      )
      .required("Last name is required"),
    idCard: Yup.string().required("ID card is required"),
    email: Yup.string().email("Invalid Email").required("Email is required"),
    language: Yup.string().required("Language is required"),
    birthDate: Yup.date().nullable(),
  });
  const defaultValue = initialValues;
  return (
    <>
      <DialogComponent
        title={getTranslation("Add Tenants", "Add Tenants", "Add Tenants")}
        open={isOpen}
        onClose={onClose}
        fullWidth
        maxWidth='md'
      >
        <Box p={2}>
          <Formik
            enableReinitialize
            initialValues={defaultValue}
            validationSchema={basicValidationSchema}
            onSubmit={(values, actions) => {
              if (!values.birthDate) {
                values.birthDate = "0001-01-01T00:00:00.000Z"; // Change this value to suit your date format
              }
              actions.setSubmitting(true);
              values.telephone = values.telephone.replace(/\s/g, "");
              onSubmit(values, actions);
              actions.setSubmitting(false);
              actions.resetForm();
              setStartContractLoading(false);
            }}
          >
            {({
              errors,
              touched,
              values,
              handleSubmit,
              isSubmitting,
              getFieldProps,
              setFieldValue,
            }) => (
              <Form>
                <Grid container spacing={2}>
                  <Grid item xs={12} lg={6} md={6} sm={6}>
                    <TextField
                      fullWidth
                      id='firstName'
                      label={getTranslation(" First Name", "Pr�nom", "Vorname")}
                      required
                      size='small'
                      variant='outlined'
                      type='text'
                      name='firstName'
                      {...getFieldProps("firstName")}
                      error={touched.firstName && Boolean(errors.firstName)}
                      helperText={
                        touched.firstName && errors.firstName ? (
                          <span
                            style={{
                              boxShadow: "none",
                            }}
                          >
                            {errors.firstName}
                          </span>
                        ) : null
                      }
                      InputLabelProps={{
                        shrink: true,
                      }}
                      style={{
                        borderRadius: "5px",
                        height: "40px",
                        marginBottom:
                          touched.firstName && errors.firstName
                            ? "16px"
                            : "8px",
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} lg={6} md={6} sm={6}>
                    <TextField
                      fullWidth
                      id='lastName'
                      label={getTranslation(
                        "Last Name",
                        "Last Name",
                        "Last Name"
                      )}
                      required
                      size='small'
                      variant='outlined'
                      name='lastName'
                      type='text'
                      {...getFieldProps("lastName")}
                      error={touched.lastName && Boolean(errors.lastName)}
                      helperText={
                        touched.lastName && errors.lastName ? (
                          <span
                            style={{
                              boxShadow: "none",
                            }}
                          >
                            {errors.lastName}
                          </span>
                        ) : null
                      }
                      InputLabelProps={{
                        shrink: true,
                      }}
                      style={{
                        borderRadius: "5px",
                        height: "40px",
                        marginBottom:
                          touched.lastName && errors.lastName ? "16px" : "8px",
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} lg={6} md={6} sm={6}>
                    <TextField
                      fullWidth
                      id='idCard'
                      label={getTranslation("ID Card", "ID Card", "ID Card")}
                      size='small'
                      variant='outlined'
                      required
                      name='idCard'
                      {...getFieldProps("idCard")}
                      error={touched.idCard && Boolean(errors.idCard)}
                      helperText={
                        touched.idCard && errors.idCard ? (
                          <span
                            style={{
                              boxShadow: "none",
                            }}
                          >
                            {errors.idCard}
                          </span>
                        ) : null
                      }
                      InputLabelProps={{
                        shrink: true,
                      }}
                      style={{
                        borderRadius: "5px",
                        height: "40px",
                        marginBottom:
                          touched.idCard && errors.idCard ? "16px" : "8px",
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} lg={6} md={6} sm={6}>
                    <TextField
                      fullWidth
                      id='email'
                      label={getTranslation("Email", "Email", "Email")}
                      required
                      size='small'
                      variant='outlined'
                      name='email'
                      {...getFieldProps("email")}
                      error={touched.email && Boolean(errors.email)}
                      helperText={
                        touched.email && errors.email ? (
                          <span
                            style={{
                              boxShadow: "none",
                            }}
                          >
                            {errors.email}
                          </span>
                        ) : null
                      }
                      InputLabelProps={{
                        shrink: true,
                      }}
                      style={{
                        borderRadius: "5px",
                        height: "40px",
                        marginBottom:
                          touched.email && errors.email ? "16px" : "8px",
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} lg={6} md={6} sm={6}>
                    <DatePicker
                      fullWidth
                      id='birthDate'
                      size='small'
                      label={getTranslation(
                        "birthDate",
                        "birthDate",
                        "birthDate"
                      )}
                      value={values.birthDate}
                      name='birthDate'
                      onChange={(date) => {
                        setFieldValue("birthDate", date);
                      }}
                      style={{
                        borderRadius: "5px",
                      }}
                      error={touched.birthDate && Boolean(errors.birthDate)}
                    />
                  </Grid>
                  <Grid item xs={12} lg={6} md={6} sm={6}>
                    <TextField
                      fullWidth
                      id='birthTown'
                      label={getTranslation(
                        "Birth Town",
                        "Birth Town",
                        "Birth Town"
                      )}
                      size='small'
                      variant='outlined'
                      name='birthTown'
                      {...getFieldProps("birthTown")}
                      error={touched.birthTown && Boolean(errors.birthTown)}
                      helperText={touched.birthTown && errors.birthTown}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      style={{
                        borderRadius: "5px",
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={6}>
                    <FormControl fullWidth variant='outlined'>
                      <InputLabel id='demo-simple-select-label'>
                        {getTranslation(
                          "Rent Due Date",
                          "Rent Due Date",
                          "Rent Due Date"
                        )}
                      </InputLabel>
                      <Select
                        style={{
                          height: "40px",
                          borderRadius: "5px",
                        }}
                        autoComplete='true'
                        labelId='demo-simple-select-label'
                        id='demo-simple-select'
                        value={values.rentDueDay}
                        label={getTranslation(
                          "Rent Due Date",
                          "Rent Due Date",
                          "Rent Due Date"
                        )}
                        onChange={(e, reminderType) => {
                          setFieldValue("rentDueDay", e.target.value);
                        }}
                      >
                        {rentDueOptions()}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={6}>
                    <FormControl fullWidth variant='outlined'>
                      <Autocomplete
                        options={languages}
                        getOptionLabel={(option) => option}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            fullWidth
                            id='language'
                            label='Language'
                            size='small'
                            required
                            variant='outlined'
                            {...getFieldProps("language")}
                            error={touched.language && Boolean(errors.language)}
                            helperText={touched.language && errors.language}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            style={{
                              height: "40px",
                              borderRadius: "5px",
                            }}
                          />
                        )}
                        onChange={(event, newValue) => {
                          setFieldValue(
                            "language",
                            languages.indexOf(newValue)
                          );
                        }}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} lg={6} md={6} sm={6}>
                    <TextField
                      fullWidth
                      id='telephone'
                      label={getTranslation("Phone", "T�l�phone", "Telefon")}
                      size='small'
                      variant='outlined'
                      name='telephone'
                      {...getFieldProps("telephone")}
                      error={touched.telephone && Boolean(errors.telephone)}
                      helperText={touched.telephone && errors.telephone}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      onChange={(e) => {
                        setFieldValue("telephone", e.target.value);
                        // setIsDisable(true);
                        // setBtnVisiable(true);
                        // if (e.target.value == "" || e.target.value == "+") {
                        //   setIsDisable(false);
                        //   setBtnVisiable(false);
                        // }
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} lg={12} md={12} sm={12}>
                    <TextField
                      fullWidth
                      id='comments'
                      label={getTranslation("Comments", "Comments", "Comments")}
                      size='small'
                      variant='outlined'
                      multiline
                      rows={5}
                      name='comments'
                      {...getFieldProps("comments")}
                      error={touched.comments && Boolean(errors.comments)}
                      helperText={touched.comments && errors.comments}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      style={{
                        // boxShadow: "0px 2px 3px rgba(0, 0, 0, 0.2)",
                        borderRadius: "5px",
                      }}
                    />
                  </Grid>
                  {/* enable auto reminder checkbox */}
                  <Grid xs={12} sm={12} md={12} lg={12}>
                    <FormControl>
                      <FormControlLabel
                        label={getTranslation(
                          "Enable Auto Reminder",
                          "Activer les rappels automatiques",
                          "Automatische Erinnernugen aktivieren"
                        )}
                        labelPlacement='start'
                        control={
                          <Checkbox
                            id='enableAutoReminder'
                            // checked={values.enableAutoReminder}
                            onChange={(e, check) => {
                              setFieldValue("enableAutoReminder", check);
                            }}
                            style={{ color: "#e65837" }}
                          />
                        }
                      />
                    </FormControl>
                  </Grid>

                  {/* reminder check boxes  */}
                  <Grid item xs={12} lg={12} md={12} sm={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={values.ListOfSendReminderBy.includes(1)}
                          onChange={(e) => {
                            if (e.target.checked) {
                              setFieldValue("ListOfSendReminderBy", [
                                ...values.ListOfSendReminderBy,
                                1,
                              ]);
                            } else {
                              setFieldValue(
                                "ListOfSendReminderBy",
                                values.ListOfSendReminderBy.filter(
                                  (value) => value !== 1
                                )
                              );
                            }
                          }}
                          style={{ color: "#e65837" }}
                        />
                      }
                      label={getTranslation("Email", "Email", "Email")}
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={values.ListOfSendReminderBy.includes(2)}
                          onChange={(e) => {
                            if (e.target.checked) {
                              setFieldValue("ListOfSendReminderBy", [
                                ...values.ListOfSendReminderBy,
                                2,
                              ]);
                            } else {
                              setFieldValue(
                                "ListOfSendReminderBy",
                                values.ListOfSendReminderBy.filter(
                                  (value) => value !== 2
                                )
                              );
                            }
                          }}
                          style={{ color: "#e65837" }}
                        />
                      }
                      label={getTranslation("SMS", "SMS", "SMS")}
                    />

                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={values.ListOfSendReminderBy.includes(4)}
                          onChange={(e) => {
                            if (e.target.checked) {
                              setFieldValue("ListOfSendReminderBy", [
                                ...values.ListOfSendReminderBy,
                                4,
                              ]);
                            } else {
                              setFieldValue(
                                "ListOfSendReminderBy",
                                values.ListOfSendReminderBy.filter(
                                  (value) => value !== 4
                                )
                              );
                            }
                          }}
                          style={{ color: "#e65837" }}
                        />
                      }
                      label={getTranslation("Post", "Post", "Post")}
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={values.ListOfSendReminderBy.includes(8)}
                          onChange={(e) => {
                            if (e.target.checked) {
                              setFieldValue("ListOfSendReminderBy", [
                                ...values.ListOfSendReminderBy,
                                8,
                              ]);
                            } else {
                              setFieldValue(
                                "ListOfSendReminderBy",
                                values.ListOfSendReminderBy.filter(
                                  (value) => value !== 8
                                )
                              );
                            }
                          }}
                          style={{ color: "#e65837" }}
                        />
                      }
                      label={getTranslation("Phone", "Phone", "Phone")}
                    />
                  </Grid>

                  {values.otherDocument ? (
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <TextField
                        fullWidth
                        id='otherDocumentDescription'
                        label={getTranslation(
                          "Description",
                          "Description",
                          "Description"
                        )}
                        size='small'
                        variant='outlined'
                        multiline
                        rows={5}
                        name='otherDocumentDescription'
                        {...getFieldProps("otherDocumentDescription")}
                        error={
                          touched.otherDocumentDescription &&
                          Boolean(errors.otherDocumentDescription)
                        }
                        helperText={
                          touched.otherDocumentDescription &&
                          errors.documentDescription
                        }
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid>
                  ) : (
                    ""
                  )}
                </Grid>

                <Box
                  pt={4}
                  width='100%'
                  display='flex'
                  alignItems='center'
                  justifyContent='center'
                >
                  <ButtonWithLoading
                    disabled={isDisable}
                    style={{
                      borderRadius: "25px",
                      backgroundColor: "#e65837",
                    }}
                    title={getTranslation("Add", "Ajouter", "Addieren")}
                    size='small'
                    variant='contained'
                    color='primary'
                    loading={isLoading}
                    onClick={handleSubmit}
                  />
                  &nbsp; &nbsp;
                  <ButtonWithLoading
                    disabled={isDisable}
                    style={{
                      borderRadius: "25px",
                      backgroundColor: "#e65837",
                    }}
                    title={getTranslation(
                      "Start Contract",
                      "D�but du contrat",
                      "Vertrag starten"
                    )}
                    size='small'
                    variant='contained'
                    color='primary'
                    loading={startContractLoading}
                    onClick={(e) => {
                      setFieldValue("startContract", true);
                      handleSubmit(e);
                      setStartContractLoading(true);
                    }}
                  />
                </Box>
                <br />
                {isDisable ? (
                  <Typography
                    variant='h5'
                    align='center'
                    style={{ color: "red" }}
                  >
                    {getTranslation(
                      "Please verify phone number to add tenant",
                      "Please verify phone number to add tenant",
                      "Please verify phone number to add tenant"
                    )}
                  </Typography>
                ) : (
                  ""
                )}
              </Form>
            )}
          </Formik>
        </Box>
      </DialogComponent>
    </>
  );
};

export default AddTanentDialog;
