import React, { useRef, useState, useEffect } from "react";
import CardComponent from "../../../../../components/Card";
import { getTranslation } from "../../../../../heplers/translationHelper";
import {
	CardContent,
	IconButton,
	InputAdornment,
	TextField,
} from "@material-ui/core";
import { Get, Post } from "../../../../../actions";
import { useParams } from "react-router";
import { Grid } from "@material-ui/core";
import ButtonWithLoading from "../../../../../components/ButtonWithLoading";
import CloudUpload from "@material-ui/icons/CloudUpload";
import TableComponent from "../../../../../components/table/index";
import { useSnackbar } from "notistack";
import {
	Post_UploadTenantFile_URL,
	Post_UpdateTenantFile_URL,
} from "../../../../../constants/apiUrls";
import axios from "../../../../../heplers/apiHelper";
import _ from "lodash";
import { DisplayTenantDocs } from "./displayTenantDocs";

export const TenantDocuments = () => {
	const { tenantId } = useParams();

	const [selectedFileName, setSelectedFileName] = useState();
	const [selectedFile, setSelectedFile] = useState({
		file: null,
		description: "",
	});
	const [isLoading, setIsLoading] = useState(false);
	const [referesh, setReferesh] = useState(false);
	const [documentData, setDocumentData] = useState();
	const [updatedDocumentData, setUpdatedDocumentData] = useState();
	const [uploadedFile, setUploadedFile] = useState();
	const { enqueueSnackbar } = useSnackbar();

	useEffect(() => {
		setReferesh(false);
	}, [referesh]);

	// useEffect(() => {
	//   if (selectedFile != null) {
	//     handleSubmitFile();
	//   }
	// }, [selectedFile]);

	useEffect(() => {
		handleFileUpload();
	}, [documentData]);
	const fileInputRef = useRef(null);
	const fileNameRef = useRef(null);
	const handleUploadClick = () => {
		fileInputRef.current.click();
	};

	const handleFileUpload = (event) => {
		if (documentData) {
			fileNameRef?.current?.focus();
		}
		const file = event?.target.files[0];
		const fileName = documentData ? documentData.fileName : file?.name;
		const lastDotIndex = _.findLastIndex(fileName, (char) => char === ".");
		const name =
			lastDotIndex !== -1
				? _.slice(fileName, 0, lastDotIndex).join("")
				: fileName;
		const extension =
			lastDotIndex !== -1 ? _.slice(fileName, lastDotIndex).join("") : "";
		setSelectedFileName({ name, extension });
		setSelectedFile({
			...selectedFile,
			file: file,
			description: documentData ? documentData.description : "",
		});
	};

	// const handleSubmitFile = () => {
	//   if (selectedFile?.file == null) {
	//     enqueueSnackbar("No file selected", { variant: "warning" });
	//   } else {
	//     let formData = new FormData();
	//     formData.append("formFile", selectedFile?.file);
	//     Post(
	//       formData,
	//       Post_UploadTenantFile_URL,
	//       null,
	//       (resp) => {
	//         setUploadedFile(resp?.data);
	//       },
	//       (err) => {
	//         enqueueSnackbar(err?.data ? err?.data : "internal server error", {
	//           variant: "error",
	//         });
	//       }
	//     );
	//   }
	// };

	const handleFileNameChange = (e) => {
		setSelectedFileName({ ...selectedFileName, name: e.target.value });
	};

	const handleDescriptionChange = (event) => {
		const newDescription = event.target.value;
		setSelectedFile({ ...selectedFile, description: newDescription });
	};
	// async function Upload(onError, onComplete, onProgressChange) {
	//   try {
	//     let formData = new FormData();
	//     formData.append("tenantId", tenantId);
	//     formData.append("file", selectedFile?.file);
	//     formData.append("description", selectedFile?.description);
	//     formData.append(
	//       "fileName",
	//       selectedFileName.name + selectedFileName.extension
	//     );
	//     await axios(null)
	//       .post(Post_UploadTenantFile_URL, formData, {
	//         headers: {
	//           "Content-Type": "multipart/form-data",
	//         },
	//         onUploadProgress: (event) => {
	//           onProgressChange(event);
	//         },
	//       })
	//       .then((resp) => {
	//         onComplete(resp);
	//       })
	//       .catch((error) => {
	//         onError(error.response);
	//       });
	//   } catch (error) {
	//     onError(error.data);
	//   }
	// }

	const handleUpdateDocument = () => {
		setIsLoading(true);
		Post(
			{
				fileName: selectedFileName?.name + selectedFileName?.extension,
				description: selectedFile?.description,
				id: documentData?.id,
				tenantId: documentData?.tenantId,
			},
			Post_UpdateTenantFile_URL,
			null,
			(res) => {
				enqueueSnackbar(
					res
						? res.data
						: getTranslation(
								"File updated successfully",
								"File updated successfully",
								"File updated successfully"
						  ),
					{
						variant: "success",
					}
				);
				setReferesh(true);
				setSelectedFile({
					file: null,
					description: "",
				});
				setSelectedFileName({
					name: "",
					extension: "",
				});
				setDocumentData();
				setIsLoading(false);
			},
			(err) => {
				enqueueSnackbar(
					err
						? err.data
						: "An error occurred while processing your request. Please contact with admin.",
					{ variant: "error" }
				);
				setReferesh(true);
				setSelectedFile({
					file: null,
					description: "",
				});
				setSelectedFileName({
					name: "",
					extension: "",
				});
			}
		);
	};

	const handleSubmit = () => {
		if (selectedFile?.file == null) {
			enqueueSnackbar("No file selected", { variant: "warning" });
		} else {
			setIsLoading(true);
			let formData = new FormData();
			formData.append("File", selectedFile?.file);
			formData.append("tenantId", tenantId);
			formData.append("description", selectedFile?.description);
			formData.append(
				"fileName",
				selectedFileName.name + selectedFileName.extension
			);
			Post(
				formData,
				Post_UploadTenantFile_URL,
				null,
				(resp) => {
					setIsLoading(false);
					setUploadedFile(resp?.data);
					enqueueSnackbar("File uploaded successfully", { variant: "success" });
					setReferesh(true);
					setSelectedFile({
						file: null,
						description: "",
					});
					setSelectedFileName({
						name: "",
						extension: "",
					});
				},
				(err) => {
					setIsLoading(false);
					enqueueSnackbar(err?.data ? err?.data : "internal server error", {
						variant: "error",
					});
				}
			);
		}
	};
	return (
		<CardComponent
			title={getTranslation(
				"Upload Documents",
				"Upload Documents",
				"Upload Documents"
			)}
		>
			<CardContent>
				<div
					style={{
						display: "flex",
						flexDirection: "row",
						alignItems: "center",
						justifyContent: "spacce-between",
						marginBottom: "20px",
					}}
				>
					<div style={{ width: "80%" }}>
						<Grid container spacing={2}>
							<Grid item xs={12} sm={12} md={3} lg={4} xl={3}>
								<TextField
									fullWidth
									placeholder="Attach Document"
									label={getTranslation(
										"Upload Document",
										"Upload Document",
										"Upload Document"
									)}
									variant="outlined"
									size="small"
									InputLabelProps={{
										shrink: true,
									}}
									disabled={documentData ? true : false}
									InputProps={{
										readOnly: true,
										endAdornment: (
											<InputAdornment position="end">
												<IconButton
													onClick={() => {
														handleUploadClick();
													}}
													disabled={documentData ? true : false}
												>
													<CloudUpload
														style={{
															color: documentData
																? "lightgrey"
																: "rgb(229,60,22)",
														}}
													/>
												</IconButton>
												<input
													type="file"
													ref={fileInputRef}
													style={{ display: "none" }}
													onChange={handleFileUpload}
												/>
											</InputAdornment>
										),
									}}
									style={{
										boxShadow: "0px 2px 3px rgba(0, 0, 0, 0.2)",
										borderRadius: "5px",
									}}
								/>
							</Grid>
							<Grid item xs={12} sm={12} md={3} lg={4} xl={3}>
								<TextField
									fullWidth
									inputRef={fileNameRef}
									label={getTranslation("File Name", "File Name", "File Name")}
									value={!referesh ? selectedFileName?.name : ""}
									variant="outlined"
									size="small"
									InputLabelProps={{
										shrink: true,
									}}
									InputProps={{ readOnly: false }}
									onChange={(e) => {
										handleFileNameChange(e);
									}}
									style={{
										boxShadow: "0px 2px 3px rgba(0, 0, 0, 0.2)",
										borderRadius: "5px",
									}}
								/>
							</Grid>
							<Grid item xs={12} sm={12} md={3} lg={4} xl={3}>
								<TextField
									fullWidth
									value={!referesh ? selectedFile?.description : ""}
									label={getTranslation(
										"Description",
										"Description",
										"Description"
									)}
									variant="outlined"
									size="small"
									InputLabelProps={{
										shrink: true,
									}}
									onChange={handleDescriptionChange}
									style={{
										boxShadow: "0px 2px 3px rgba(0, 0, 0, 0.2)",
										borderRadius: "5px",
									}}
								/>
							</Grid>
						</Grid>
					</div>
					<div
						style={{
							width: "20%",
							textAlign: "right",
						}}
					>
						<ButtonWithLoading
							loading={isLoading}
							size="small"
							variant="contained"
							margin="20px"
							color="primary"
							// title={getTranslation("Submit", "Submit", "Submit")}
							title={
								documentData
									? getTranslation("Update", "Update", "Update")
									: getTranslation("Submit", "Submit", "Submit")
							}
							style={{
								borderRadius: "20px",
								backgroundColor: "rgb(229,60,22)",
								marginRight: "20px",
								fontWeight: "bold",
							}}
							onClick={() => {
								documentData ? handleUpdateDocument() : handleSubmit();
							}}
						/>
					</div>
				</div>
				<DisplayTenantDocs
					refereshState={referesh}
					onEdit={(document) => {
						setDocumentData(document);
					}}
				/>
			</CardContent>
		</CardComponent>
	);
};
