import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  Grid,
  TextField,
  Box,
  Typography,
  FormControl,
  FormControlLabel,
  Checkbox,
  InputLabel,
  Select,
  MenuItem,
  Card,
  CardContent,
  Button,
  CardHeader,
} from "@material-ui/core";
import { Form, Formik } from "formik";
import ButtonWithLoading from "../../../../../components/ButtonWithLoading";
import * as Yup from "yup";
import { PrintButton } from "../../../../../components/ButttonsWithIcons";
import { UploadFiles } from "../../../../../components/Upload";
import { useParams } from "react-router";
import { Get, Post } from "../../../../../actions";
import {
  Get_DeleteFileInfo_URL,
  Get_Tanenet_URL,
} from "../../../../../constants/apiUrls";
import { getTranslation } from "../../../../../heplers/translationHelper";
import { PreviewImageDialog } from "../../previewImageDialog";
import "react-phone-number-input/style.css";
import "./style.css";
import {
  Get_GetTenantBanks_URL,
  Get_TenantDuesSummary_URL,
} from "../../../../../constants/apiUrls";

import PhoneInput from "react-phone-number-input";
import MaskedInput from "react-text-mask";
import { useSnackbar } from "notistack";
import authService from "../../../../../utils/authUtils";
import {
  APP_MANAGER_SERVER_GETSMS_URL,
  APP_MANAGER_SERVER_SENDSMS_URL,
} from "../../../../../constants";
import CardComponent from "../../../../../components/Card";
import { format } from "../../../../../heplers/format";
import { TenantDocuments } from "./tenantDocuments";
import { DisplayTenantDocs } from "./displayTenantDocs";
import { MuiPhone } from "../../../../../components/MuiPhone";
import { PhoneNumberUtil } from "google-libphonenumber";
import { Autocomplete } from "@material-ui/lab";
const phoneUtil = PhoneNumberUtil.getInstance();

function TextMaskCustom(props) {
  const { inputRef, ...other } = props;
  return (
    <>
      <MaskedInput
        {...other}
        ref={(ref) => {
          inputRef(ref ? ref.inputElement : null);
        }}
        mask={[
          /\+/,
          /\d/,
          /\d/,
          /\d/,
          " ",
          /\d/,
          /\d/,
          /\d/,
          " ",
          /\d/,
          /\d/,
          /\d/,
          " ",
          /\d/,
          /\d/,
          /\d/,
        ]}
        showMask
      />
    </>
  );
}

const AddDetails = ({ onSubmit, printDetails, isLoading }) => {
  const sendReminderType = [
    {
      id: 0,
      label: getTranslation("SMS", "SMS", "SMS"),
    },
    {
      id: 1,
      label: getTranslation("Email", "E-mail", "Email"),
    },
    {
      id: 2,
      label: getTranslation("Email And SMS", "E-mail et SMS", "Email und SMS"),
    },
  ];
  const selectDate = [
    {
      id: 1,
      day: getTranslation(
        "1st of each month",
        "1st of each month",
        "1st of each month"
      ),
    },
    {
      id: 2,
      day: getTranslation(
        "2nd of each month",
        "2nd of each month",
        "2nd of each month"
      ),
    },
    {
      id: 3,
      day: getTranslation(
        "3rd of each month",
        "3rd of each month",
        "3rd of each month"
      ),
    },
    {
      id: 4,
      day: getTranslation(
        "4th of each month",
        "4th of each month",
        "4th of each month"
      ),
    },
    {
      id: 5,
      day: getTranslation(
        "5th of each month",
        "5th of each month",
        "5th of each month"
      ),
    },
    {
      id: 6,
      day: getTranslation(
        "6th of each month",
        "6th of each month",
        "6th of each month"
      ),
    },
    {
      id: 7,
      day: getTranslation(
        "7th of each month ",
        "7th of each month",
        "7th of each month"
      ),
    },
    {
      id: 8,
      day: getTranslation(
        "8th of each month",
        "8th of each month",
        "8th of each month"
      ),
    },
    {
      id: 9,
      day: getTranslation(
        "9th of each month",
        "9th of each month",
        "9th of each month"
      ),
    },
    {
      id: 10,
      day: getTranslation(
        "10th of each month",
        "10th of each month",
        "10th of each month"
      ),
    },
    {
      id: 11,
      day: getTranslation(
        "11th of each month",
        "11th of each month",
        "11th of each month"
      ),
    },
    {
      id: 12,
      day: getTranslation(
        "12th of each month",
        "12th of each month",
        "12th of each month"
      ),
    },
    {
      id: 13,
      day: getTranslation(
        "13th of each month",
        "13th of each month",
        "13th of each month"
      ),
    },
    {
      id: 14,
      day: getTranslation(
        "14th of each month",
        "14th of each month",
        "14th of each month"
      ),
    },
    {
      id: 15,
      day: getTranslation(
        "15th of each month",
        "15th of each month",
        "15th of each month"
      ),
    },
    {
      id: 16,
      day: getTranslation(
        "16th of each month",
        "16th of each month",
        "16th of each month"
      ),
    },
    {
      id: 17,
      day: getTranslation(
        "17th of each month",
        "17th of each month",
        "17th of each month"
      ),
    },
    {
      id: 18,
      day: getTranslation(
        "18th of each month",
        "18th of each month",
        "18th of each month"
      ),
    },
    {
      id: 19,
      day: getTranslation(
        "19th of each month",
        "19th of each month",
        "19th of each month"
      ),
    },
    {
      id: 20,
      day: getTranslation(
        "20th of each month",
        "20th of each month",
        "20th of each month"
      ),
    },
    {
      id: 21,
      day: getTranslation(
        "21st of each month",
        "21st of each month",
        "21st of each month"
      ),
    },
    {
      id: 22,
      day: getTranslation(
        "22nd of each month",
        "22nd of each month",
        "22nd of each month"
      ),
    },
    {
      id: 23,
      day: getTranslation(
        "23rd of each month",
        "23rd of each month",
        "23rd of each month"
      ),
    },
    {
      id: 24,
      day: getTranslation(
        "24th of each month",
        "24th of each month",
        "24th of each month"
      ),
    },
    {
      id: 25,
      day: getTranslation(
        "25th of each month",
        "25th of each month",
        "25th of each month"
      ),
    },
    {
      id: 26,
      day: getTranslation(
        "26th of each month",
        "26th of each month",
        "26th of each month"
      ),
    },
    {
      id: 27,
      day: getTranslation(
        "27th of each month",
        "27th of each month",
        "27th of each month"
      ),
    },
    {
      id: 28,
      day: getTranslation(
        "28th of each month",
        "28th of each month",
        "28th of each month"
      ),
    },
    {
      id: 29,
      day: getTranslation(
        "29th of each month",
        "29th of each month",
        "29th of each month"
      ),
    },
    {
      id: 30,
      day: getTranslation(
        "30th of each month",
        "30th of each month",
        "30th of each month"
      ),
    },
    {
      id: 31,
      day: getTranslation(
        "31st of each month",
        "31st of each month",
        "31st of each month"
      ),
    },
  ];
  const languages = ["English", "French", "German"];
  const history = useHistory();

  const { tenantId } = useParams();
  const [tenant, setTenant] = useState(null);
  const [previewImage, setPreviewImage] = useState(false);
  const [previewImageAddress, setPreviewImageAddress] = useState("");
  const [isDisable, setIsDisable] = useState(false);
  const [btnVisiable, setBtnVisiable] = useState(false);
  const [verified, setVerified] = useState(false);
  const [tenantDuesSummary, setTenanDuesSummary] = useState();
  const [tenantBanks, setTenantBanks] = useState();
  const [phoneNumber, setPhoneNumber] = useState("");
  const [isValid, setIsValid] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  //for tenant dues summay and tenant bank
  useEffect(() => {
    getTenantDuesSummary();
  }, []);

  const getTenantDuesSummary = () => {
    Get(
      { tenantId: tenantId },
      Get_TenantDuesSummary_URL,
      null,
      (res) => {
        setTenanDuesSummary(res?.data);
      },
      (err) => {
        enqueueSnackbar(
          err ? err.data : "An error occurred while processing your request",
          { variant: "error" }
        );
      }
    );
  };

  useEffect(() => {
    Get(
      { tenantId: tenantId },
      Get_Tanenet_URL,
      null,
      (resp) => {
        setTenant(resp.data);
      },
      (error) => {
        enqueueSnackbar(
          error
            ? error.data
            : "An error occurred while getting tenants. Please contact with admin",
          { variant: "error" }
        );
      }
    );
  }, [tenantId]);
  const rentDueOptions = () => {
    return selectDate.map((item) => {
      return <MenuItem value={item.id}>{item.day}</MenuItem>;
    });
  };
  const deleteTenantFile = (payload) => {
    Get(
      payload,
      Get_DeleteFileInfo_URL,
      null,
      (resp) => {
        enqueueSnackbar("File Deleted Successfully", { variant: "success" });
      },
      (error) => {
        enqueueSnackbar("Cannot Delete file", { variant: "error" });
      }
    );
  };
  const customerId = authService.getCustomerId();

  const callGetSms = (smsSid) => {
    setTimeout(getSms(smsSid), 3000);
  };

  const getSms = (payload) => {
    Get(
      { SmsSid: payload },
      APP_MANAGER_SERVER_GETSMS_URL,
      null,
      (resp) => {
        switch (resp?.data) {
          case 1:
            callGetSms(payload);
            break;
          case 4:
            enqueueSnackbar("Verification faild please Verify again", {
              variant: "error",
            });
            setVerified(false);
            setIsDisable(true);
            break;
          case 5:
            enqueueSnackbar("Your phone number is verified", {
              variant: "success",
            });
            setVerified(true);
            setIsDisable(false);
            break;
          case 6:
            enqueueSnackbar(
              "Your phone number is not verified.please try another number",
              { variant: "error" }
            );
            setVerified(false);
            setIsDisable(true);
            break;
          default:
            callGetSms(payload);
            break;
        }
      },
      (error) => {
        enqueueSnackbar("verification failed", { variant: "error" });
      }
    );
  };

  const sendSms = (payload) => {
    Post(
      payload,
      APP_MANAGER_SERVER_SENDSMS_URL,
      null,
      (resp) => {
        if (resp?.data == null) {
          enqueueSnackbar("Twilio cannot send your message please try again", {
            variant: "error",
          });
        } else {
          callGetSms(resp?.data);
          setIsDisable(false);
        }
      },
      (error) => {
        enqueueSnackbar("Twilio verification failed please try again", {
          variant: "error",
        });
      }
    );
  };

  const initialValues = {
    firstName: "",
    lastName: "",
    idCard: "",
    telephone: "",
    email: "",
    isActive: false,
    apartmentId: 0,
    reminderType: "",
    rentDueDay: 0,
    language: "",
    enableAutoReminder: false,
    files: [],
    buildingId: 0,
    apartment: {},
    building: {},
    listOfSendReminderBy: [],
  };

  const basicValidationSchema = Yup.object().shape({
    firstName: Yup.string().required("First Name is required"),
    lastName: Yup.string().required("Last Name is required"),
    idCard: Yup.string().required("ID is required"),
    email: Yup.string().email("Invalid Email").required("Email is required"),
    language: Yup.string().required("Language is required"),
  });

  const defaultValue = tenant ? tenant : initialValues;
  const language = languages[tenant?.language];

  const isPhoneValid = (phone) => {
    try {
      return phoneUtil.isValidNumber(phoneUtil.parseAndKeepRawInput(phone));
    } catch (error) {
      return false;
    }
  };

  const handlePhoneChange = (value) => {
    setIsValid(isPhoneValid(value));
    setPhoneNumber(value);
  };
  return (
    <>
      {/* tenant dues summary */}
      <CardComponent>
        <CardHeader
          title={
            <Typography variant='h6' style={{ fontWeight: "bold" }}>
              {getTranslation(
                "Tenant Dues Summary",
                "Tenant Dues Summary",
                "Tenant Dues Summary"
              )}
            </Typography>
          }
        />
        <CardContent>
          <Grid
            container
            spacing={2}
            style={{
              alignContent: "center",
              alignItems: "center",
            }}
          >
            <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
              <Box display='flex'>
                <Typography variant='h5'>
                  {getTranslation("Total Rent", "Total Rent", "Total Rent")}
                </Typography>
                <Typography variant='h5' style={{ fontWeight: "bold" }}>
                  : {format("number", tenantDuesSummary?.totalRent)} €
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
              <Box display='flex'>
                <Typography variant='h5'>
                  {getTranslation(
                    "Total Warranty",
                    "Total Warranty",
                    "Total Warranty"
                  )}
                </Typography>
                <Typography variant='h5' style={{ fontWeight: "bold" }}>
                  : {format("number", tenantDuesSummary?.totalWarranty)} €
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
              <Box display='flex'>
                <Typography variant='h5'>
                  {getTranslation("Total Cost", "Total Cost", "Total Cost")}
                </Typography>
                <Typography variant='h5' style={{ fontWeight: "bold" }}>
                  : {format("number", tenantDuesSummary?.totalCost)} €
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
              <Box display='flex'>
                <Typography variant='h5'>
                  {getTranslation(
                    "Total Amount Due",
                    "Total Amount Due",
                    "Total Amount Due"
                  )}
                </Typography>
                <Typography variant='h5' style={{ fontWeight: "bold" }}>
                  : {format("number", tenantDuesSummary?.totalAmountDue)} €
                </Typography>
              </Box>
            </Grid>
            <Grid item container xs={12} sm={6} md={4} lg={3} xl={3}>
              <Grid item xs={8} sm={8} md={7} lg={8}>
                <Typography variant='h5'>
                  {getTranslation(
                    "Total Repair And Maintenance",
                    "Total Repair And Maintenance",
                    "Total Repair And Maintenance"
                  )}
                </Typography>
              </Grid>
              <Grid item xs={4} sm={4} md={5} lg={4}>
                <Typography variant='h5' style={{ fontWeight: "bold" }}>
                  :{" "}
                  {format(
                    "number",
                    tenantDuesSummary?.totalRepairAndMaintenance
                  )}{" "}
                  €
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </CardComponent>
      <br />
      <CardComponent
        title={getTranslation(
          "Update Tenant",
          "Update Tenant",
          "Update Tenant"
        )}
        style={{ marginBottom: "20px" }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            paddingLeft: "15px",
            paddingRight: "15px",
            paddingTop: "15px",
          }}
        >
          <Typography
            align='center'
            variant='h4'
            style={{
              background: "rgb(0,68,103)",
              color: "white",
              padding: "12px 17px 12px 17px",
              borderRadius: "15px 15px 0px 1px",
            }}
          >
            {getTranslation(
              "Reference Code",
              "Reference Code",
              "Reference Code"
            )}
            :
            <strong
              style={{
                color: "white",
                marginLeft: "5px",
              }}
            >
              {tenant?.referenceCode}
            </strong>
          </Typography>
          <br />
          <Typography
            align='center'
            variant='h4'
            style={{
              background: "rgb(0,68,103)",
              color: "white",
              padding: "12px 17px 12px 17px",
              borderRadius: "15px 15px 0px 1px",
            }}
          >
            {getTranslation(
              "Account Balance",
              "Account Balance",
              "Account Balance"
            )}
            :{" "}
            <strong
              style={{
                color:
                  tenant?.accountBalance < 0 ? "rgb(229,60,22)" : "#14E114",
              }}
            >
              {format("number", tenant?.accountBalance)}
            </strong>
          </Typography>
        </div>
        <br />
        <CardContent>
          <Formik
            enableReinitialize
            initialValues={defaultValue}
            validationSchema={basicValidationSchema}
            onSubmit={(values, actions) => {
              actions.setSubmitting(true);
              values.telephone = values.telephone.replace(/\s/g, "");
              onSubmit(values, actions);
              actions.setSubmitting(false);
            }}
          >
            {({
              errors,
              touched,
              values,
              handleSubmit,
              isSubmitting,
              getFieldProps,
              setFieldValue,
            }) => (
              <Form>
                <Grid container spacing={2}>
                  <Grid item xs={12} lg={3} md={4} sm={6}>
                    <TextField
                      fullWidth
                      id='firstName'
                      value={values?.firstName}
                      label={getTranslation(" First Name", "Pr�nom", "Vorname")}
                      required
                      size='small'
                      variant='outlined'
                      name='firstName'
                      {...getFieldProps("firstName")}
                      error={touched.firstName && Boolean(errors.firstName)}
                      helperText={
                        touched.firstName && errors.firstName ? (
                          <span
                            style={{
                              boxShadow: "none",
                            }}
                          >
                            {errors.firstName}
                          </span>
                        ) : null
                      }
                      InputLabelProps={{
                        shrink: true,
                      }}
                      style={{
                        boxShadow: "0px 2px 3px rgba(0, 0, 0, 0.2)",
                        borderRadius: "5px",
                        height: "40px",
                        marginBottom:
                          touched.firstName && errors.firstName
                            ? "16px"
                            : "8px",
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} lg={3} md={4} sm={6}>
                    <TextField
                      fullWidth
                      id='lastName'
                      label={getTranslation(
                        "Last Name",
                        "Last Name",
                        "Last Name"
                      )}
                      required
                      size='small'
                      variant='outlined'
                      name='lastName'
                      {...getFieldProps("lastName")}
                      error={touched.lastName && Boolean(errors.lastName)}
                      helperText={
                        touched.lastName && errors.lastName ? (
                          <span
                            style={{
                              boxShadow: "none",
                            }}
                          >
                            {errors.lastName}
                          </span>
                        ) : null
                      }
                      InputLabelProps={{
                        shrink: true,
                      }}
                      style={{
                        boxShadow: "0px 2px 3px rgba(0, 0, 0, 0.2)",
                        borderRadius: "5px",
                        height: "40px",
                        marginBottom:
                          touched.lastName && errors.lastName ? "16px" : "8px",
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} lg={3}>
                    <FormControl fullWidth variant='outlined'>
                      <InputLabel id='demo-simple-select-label'>
                        {getTranslation(
                          "Rent Due Date",
                          "Rent Due Date",
                          "Rent Due Date"
                        )}
                      </InputLabel>
                      <Select
                        style={{
                          height: "40px",
                          boxShadow: "0px 2px 3px rgba(0, 0, 0, 0.2)",
                          borderRadius: "5px",
                        }}
                        autoComplete='true'
                        labelId='demo-simple-select-label'
                        id='demo-simple-select'
                        value={values.rentDueDay}
                        label={getTranslation(
                          "Rent Due Date",
                          "Date d'�ch�ance",
                          "Zahltag"
                        )}
                        onChange={(e, reminderType) => {
                          setFieldValue("rentDueDay", e.target.value);
                        }}
                      >
                        {rentDueOptions()}
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} lg={3} md={4} sm={6}>
                    <TextField
                      fullWidth
                      id='idCard'
                      label={getTranslation(
                        "ID Card",
                        "Carte d'identit�",
                        "Personalausweis"
                      )}
                      required
                      size='small'
                      variant='outlined'
                      name='idCard'
                      {...getFieldProps("idCard")}
                      error={touched.idCard && Boolean(errors.idCard)}
                      helperText={
                        touched.idCard && errors.idCard ? (
                          <span
                            style={{
                              boxShadow: "none",
                            }}
                          >
                            {errors.idCard}
                          </span>
                        ) : null
                      }
                      InputLabelProps={{
                        shrink: true,
                      }}
                      style={{
                        boxShadow: "0px 2px 3px rgba(0, 0, 0, 0.2)",
                        borderRadius: "5px",
                        height: "40px",
                        marginBottom:
                          touched.idCard && errors.idCard ? "16px" : "8px",
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} lg={3} md={4} sm={6}>
                    <TextField
                      fullWidth
                      id='email'
                      label={getTranslation("Email", "Email", "Email")}
                      required
                      size='small'
                      variant='outlined'
                      name='email'
                      {...getFieldProps("email")}
                      error={touched.email && Boolean(errors.email)}
                      helperText={
                        touched.email && errors.email ? (
                          <span
                            style={{
                              boxShadow: "none",
                            }}
                          >
                            {errors.email}
                          </span>
                        ) : null
                      }
                      InputLabelProps={{
                        shrink: true,
                      }}
                      style={{
                        boxShadow: "0px 2px 3px rgba(0, 0, 0, 0.2)",
                        borderRadius: "5px",
                        height: "40px",
                        marginBottom:
                          touched.email && errors.email ? "16px" : "8px",
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} lg={3}>
                    <FormControl fullWidth variant='outlined'>
                      <Autocomplete
                        options={languages}
                        getOptionLabel={(option) => option}
                        value={language ? language : ""}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            fullWidth
                            id='language'
                            label='Language'
                            size='small'
                            required
                            variant='outlined'
                            {...getFieldProps("language")}
                            error={touched.language && Boolean(errors.language)}
                            helperText={touched.language && errors.language}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            style={{
                              height: "40px",
                              boxShadow: "0px 2px 3px rgba(0, 0, 0, 0.2)",
                              borderRadius: "5px",
                            }}
                          />
                        )}
                        onChange={(event, newValue) => {
                          setFieldValue(
                            "language",
                            languages.indexOf(newValue)
                          );
                        }}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} lg={3} md={4} sm={6}>
                    {/* <MuiPhone value={""} onChange={handlePhoneChange} /> */}
                    <TextField
                      fullWidth
                      id='telephone'
                      label={getTranslation("Phone", "T�l�phone", "Telefon")}
                      size='small'
                      variant='outlined'
                      name='telephone'
                      {...getFieldProps("telephone")}
                      error={touched.telephone && Boolean(errors.telephone)}
                      helperText={touched.telephone && errors.telephone}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      style={{
                        height: "40px",
                        boxShadow: "0px 2px 3px rgba(0, 0, 0, 0.2)",
                        borderRadius: "5px",
                      }}
                      onChange={(e) => {
                        setFieldValue("telephone", e.target.value);
                        // setIsDisable(true);
                        // setBtnVisiable(true);
                        // if (e.target.value == "" || e.target.value == "+") {
                        //   setIsDisable(false);
                        //   setBtnVisiable(false);
                        // }
                      }}
                    />
                    <br />
                    <br />

                    {/* {!isValid && (
                      <div style={{ color: "red" }}>Phone is not valid</div>
                    )} */}
                  </Grid>
                  {/* <Grid item>
                    {isValid && (
                      <ButtonWithLoading
                        style={{
                          borderRadius: "25px",
                          backgroundColor: "#e65837",
                        }}
                        title={getTranslation(
                          "Verify Number",
                          "Verify Number",
                          "Verify Number"
                        )}
                        size="small"
                        variant="contained"
                        color="primary"
                        loading={isLoading}
                        onClick={() => {
                          sendSms({
                            phone: phoneNumber,
                            message: "SMS to verify tenant phone number",
                            customerId: customerId,
                          });
                        }}
                      />
                    )}
                  </Grid> */}
                  <Grid item xs={6} sm={6} md={6} lg={6}>
                    <TextField
                      fullWidth
                      id='comment'
                      label={getTranslation("Comment", "Comment", "Comment")}
                      size='small'
                      variant='outlined'
                      multiline
                      rows={3}
                      name='comment'
                      {...getFieldProps("comment")}
                      error={touched.comment && Boolean(errors.comment)}
                      helperText={touched.comment && errors.comment}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      style={{
                        boxShadow: "0px 2px 3px rgba(0, 0, 0, 0.2)",
                        borderRadius: "5px",
                      }}
                    />
                  </Grid>
                  <Grid xs={12} sm={12} md={12} lg={12}></Grid>
                  <Grid xs={12} sm={12} md={6} lg={6} xl={3}>
                    <FormControl>
                      <FormControlLabel
                        label={getTranslation(
                          "Enable Auto Reminder",
                          "Activer les rappels automatiques",
                          "Automatische Erinnernugen aktivieren"
                        )}
                        labelPlacement='start'
                        control={
                          <Checkbox
                            id='enableAutoReminder'
                            checked={values.enableAutoReminder}
                            onChange={(e, check) => {
                              setFieldValue("enableAutoReminder", check);
                            }}
                            style={{ color: "rgb(229,60,22)" }}
                          />
                        }
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} lg={6} md={6} sm={12} xl={9}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name='Email'
                          checked={values.listOfSendReminderBy.includes(1)}
                          onChange={(e) => {
                            if (e.target.checked) {
                              setFieldValue("listOfSendReminderBy", [
                                ...values.listOfSendReminderBy,
                                1,
                              ]);
                            } else {
                              // setFieldValue(
                              //   "listOfSendReminderBy",
                              //   values.listOfSendReminderBy.filter(
                              //     (value) => value !== 1
                              //   )
                              // );
                              setFieldValue(
                                "listOfSendReminderBy",
                                values.listOfSendReminderBy.includes(1)
                                  ? values.listOfSendReminderBy.map((value) =>
                                      value === 1 ? 0 : value
                                    )
                                  : values.listOfSendReminderBy
                              );
                            }
                          }}
                          style={{ color: "rgb(229,60,22)" }}
                        />
                      }
                      label={getTranslation("Email", "Email", "Email")}
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          name='SMS'
                          checked={values.listOfSendReminderBy.includes(2)}
                          onChange={(e) => {
                            if (e.target.checked) {
                              setFieldValue("listOfSendReminderBy", [
                                ...values.listOfSendReminderBy,
                                2,
                              ]);
                            } else {
                              // setFieldValue(
                              //   "listOfSendReminderBy",
                              //   values.listOfSendReminderBy.filter(
                              //     (value) => value !== 2
                              //   )
                              // );
                              setFieldValue(
                                "listOfSendReminderBy",
                                values.listOfSendReminderBy.includes(2)
                                  ? values.listOfSendReminderBy.map((value) =>
                                      value === 2 ? 0 : value
                                    )
                                  : values.listOfSendReminderBy
                              );
                            }
                          }}
                          style={{ color: "rgb(229,60,22)" }}
                        />
                      }
                      label={getTranslation("SMS", "SMS", "SMS")}
                    />

                    <FormControlLabel
                      control={
                        <Checkbox
                          name='Post'
                          checked={values.listOfSendReminderBy.includes(4)}
                          onChange={(e) => {
                            if (e.target.checked) {
                              setFieldValue("listOfSendReminderBy", [
                                ...values.listOfSendReminderBy,
                                4,
                              ]);
                            } else {
                              // setFieldValue(
                              //   "listOfSendReminderBy",
                              //   values.listOfSendReminderBy.filter(
                              //     (value) => value !== 4
                              //   )
                              // );
                              setFieldValue(
                                "listOfSendReminderBy",
                                values.listOfSendReminderBy.includes(4)
                                  ? values.listOfSendReminderBy.map((value) =>
                                      value === 4 ? 0 : value
                                    )
                                  : values.listOfSendReminderBy
                              );
                            }
                          }}
                          style={{ color: "rgb(229,60,22)" }}
                        />
                      }
                      label={getTranslation("Post", "Post", "Post")}
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          name='Post'
                          checked={values.listOfSendReminderBy.includes(8)}
                          onChange={(e) => {
                            if (e.target.checked) {
                              setFieldValue("listOfSendReminderBy", [
                                ...values.listOfSendReminderBy,
                                8,
                              ]);
                            } else {
                              // setFieldValue(
                              //   "listOfSendReminderBy",
                              //   values.listOfSendReminderBy.filter(
                              //     (value) => value !== 8
                              //   )
                              // );
                              setFieldValue(
                                "listOfSendReminderBy",
                                values.listOfSendReminderBy.includes(8)
                                  ? values.listOfSendReminderBy.map((value) =>
                                      value === 8 ? 0 : value
                                    )
                                  : values.listOfSendReminderBy
                              );
                            }
                          }}
                          style={{ color: "rgb(229,60,22)" }}
                        />
                      }
                      label={getTranslation("Phone", "Phone", "Phone")}
                    />
                  </Grid>
                </Grid>

                <Box
                  pr={1}
                  pb={1}
                  width='100%'
                  display='flex'
                  alignItems='center'
                  justifyContent='flex-end'
                  marginTop='10px'
                >
                  <ButtonWithLoading
                    disabled={isDisable}
                    style={{
                      marginLeft: "10px",
                      borderRadius: "25px",
                      backgroundColor: "rgb(229,60,22)",
                      color: "white",
                      fontWeight: "bold",
                    }}
                    title={getTranslation("Update", "Update", "Update")}
                    size='small'
                    variant='contained'
                    margin='20px'
                    loading={isLoading}
                    onClick={handleSubmit}
                  />
                </Box>
              </Form>
            )}
          </Formik>
        </CardContent>
      </CardComponent>
      <TenantDocuments />

      <PreviewImageDialog
        title='Preview'
        open={previewImage}
        fileAddress={previewImageAddress}
        onClose={() => {
          setPreviewImage(false);
          setPreviewImageAddress("");
        }}
      />
    </>
  );
};

export default AddDetails;
