import React from "react";
import { EditButton } from "../../../../../components/ButttonsWithIcons";
import { useState, useEffect } from "react";
import { useParams } from "react-router";
import { Get, GetPackageInfo, Post } from "../../../../../actions";
import { useSnackbar } from "notistack";
import {
	Get_CostWithPagination_URL,
	Post_DeleteAppartment_URL,
	Post_InsertAppartment_URL,
	Post_UpdateAppartment_URL,
	Post_AddOwner_URL,
	Post_AddBuildingOwner_URL,
	Get_BuildingOwnersWithPagination_URL,
	Post_UpdateOwner_URL,
	Post_UpdateBuildingOwner_URL,
	Get_DeleteOwner_URL,
	Get_AllBuildingOwners_URL,
	Get_BuildingPercentage_URL,
} from "../../../../../constants/apiUrls";
import {
	Card,
	CardContent,
	CardHeader,
	Grid,
	Typography,
} from "@material-ui/core";
import AddAppartmentDialog from "./addApartmentDialog";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import { getTranslation } from "../../../../../heplers/translationHelper";
import TableComponent from "../../../../../components/table";
import moment from "moment";
import { DatePicker } from "../../../../../components/DatePicker";
import { useSelector } from "react-redux";
import Owner from "./owner";
import { format } from "../../../../../heplers/format";
import AlertDialog from "../../../../../components/DeleteDialog";
import DialogComponent from "../../../../../components/Dialog";
import { Upgrade } from "../../../../common/Upgrade";
import authUtils from "../../../../../utils/authUtils";

const costColumns = [
	{
		id: "date",
		label: getTranslation("Date", "Date", "Date"),
		format: "date",
	},
	{
		id: "supplierName",
		numeric: false,
		disablePadding: true,
		label: getTranslation("Supplier", "Fournisseur", "Lieferant"),
	},
	{
		id: "activity",
		numeric: false,
		disablePadding: true,
		label: getTranslation("Category", "Catégorie", "Art"),
	},
	{
		id: "amountDeposit",
		align: "right",
		label: getTranslation("Expenses", "Expenses", "Expenses"),
		format: "number",
	},
];
const apartmentColumns = [
	{
		id: "apartmentNo",
		numeric: false,
		disablePadding: true,
		label: getTranslation("Appartment No", "Appartement Nr", "Wohnung Nr"),
	},
	{
		id: "floor",
		label: getTranslation("Floor", "Floor", "Floor"),
	},
	{
		id: "apartmentType",
		label: getTranslation(
			"Appartment Type",
			"Appartment Type",
			"Appartment Type"
		),
		component: ({ row }) => {
			return (
				<Typography>
					{row.apartmentType == 0
						? "House"
						: row.apartmentType == 1
						? "Apartment"
						: row.apartmentType == 2
						? "Office"
						: "Storage"}
				</Typography>
			);
		},
	},
	{
		id: "activeTenantName",
		disablePadding: true,
		label: getTranslation("Active Tenant", "Nom du locataire", "Mietername"),
	},
	{
		id: "reservedTenantName",
		disablePadding: true,
		label: getTranslation("Reserved Tenant", "Nom du locataire", "Mietername"),
	},
	{
		id: "status",
		disablePadding: true,
		label: getTranslation("Status", "Statut", "Status"),
		component: ({ row }) => (
			<Typography>
				{" "}
				{row.status
					.replace(
						"Occupied",
						getTranslation("Occupied", "Occupied", "Occupied")
					)
					.replace(
						"Reserved",
						getTranslation("Reserved", "Reserved", "Reserved")
					)
					.replace(
						"Available",
						getTranslation("Available", "Available", "Available")
					)
					.replace(
						"On Notice Period",
						getTranslation(
							"On Notice Period",
							"On Notice Period",
							"On Notice Period"
						)
					)}
			</Typography>
		),
	},
	{
		id: "amount",
		align: "right",
		label: getTranslation("Rent", "Rent", "Rent"),
		format: "number",
		component: (row) => {
			return <Typography>{format("number", row?.row?.amount)}</Typography>;
		},
	},

	{
		id: "actions",
		numeric: true,
		disablePadding: true,
		label: getTranslation("Action", "Action", "Action"),
		align: "right",
	},
];

const Appartments = ({
	loading,
	rows,
	onFilter,
	onDelete,
	onApartmentAdd,
	onApartmentUpdate,
}) => {
	const [searchString, setSearchString] = useState("");
	const [page, setPage] = useState(0);
	let pageSize = localStorage.getItem("sizeAppart");
	if (pageSize == null) {
		localStorage.setItem("sizeAppart", 10);
		pageSize = 10;
	}
	const [rowsPerPage, setRowsPerPage] = useState(pageSize);
	const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
	const [deletedRow, setDeletedRow] = useState();
	const [editAprtment, setEditAprtment] = useState(null);
	const [deleting, setDeleting] = useState(false);
	const [recentOwner, setRecentOwner] = useState({});
	const [isLoading, setIsLoading] = useState(false);
	const [type, setType] = useState(0);
	const [owners, setOwners] = useState([]);
	const [allOwners, setAllOwners] = useState();
	const [buildingPercentage, setBuildingPercentage] = useState();
	const [openCreateOwnerDialog, setOpenCreateOwnerDialog] = useState(false);
	const currentDate = moment();
	currentDate.set("day", (currentDate.get("day") - 1) * -1);
	const [from, setFrom] = useState(currentDate);
	const [to, setTo] = useState(moment());
	const [buildingCost, setBuildingCost] = useState([]);
	const { enqueueSnackbar } = useSnackbar();
	const [openCreateAppartmentDialog, setOpenCreateAppartmentDialog] =
		useState(false);
	const [costSort, setCostSort] = useState("date");
	const [costSortDirection, setCostSortDirection] = useState(1);
	const { packageInfo } = useSelector((state) => state.auth);
	const [refresh, setReferesh] = useState(false);
	const [upGradeDialogOpen, setUpGradeDialogOpen] = useState(false);
	const [appKeys, setAppKeys] = useState();
	const id = useParams();
	const { buildingId } = useParams();

	const onCreateAparment = () => {
		rows?.totalCount >= packageInfo?.appKeys[1].value
			? enqueueSnackbar(
					getTranslation(
						"Limit excceded, please upgrade your package to add new Aparment.",
						"Limit excceded, please upgrade your package to add new Aparment.",
						"Limit excceded, please upgrade your package to add new Aparment."
					),
					{ variant: "info" }
			  )
			: setOpenCreateAppartmentDialog(true);
	};

	useEffect(() => {
		GetPackageInfo().then((res) => {
			setAppKeys(res?.data?.appKeys);
		});
	}, []);

	useEffect(() => {
		if (type == 0)
			onFilter({
				buildingId: buildingId,
				searchString: searchString,
				pageNumber: page,
				pageSize: rowsPerPage,
			});
		getOwners();
		GetAllOwners();
		getBuildingPercentage();
		setReferesh(false);
	}, [page, searchString, rowsPerPage, refresh]);
	//handlers
	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	const onCreateOwner = () => {
		setOpenCreateOwnerDialog(true);
	};

	const handleChangeRowsPerPage = (event) => {
		localStorage.setItem("sizeAppart", parseInt(event.target.value));
		setRowsPerPage(localStorage.getItem("sizeAppart"));
		setPage(0);
	};
	const handleSearchappartment = (searchString) => {
		setSearchString(searchString);
	};

	const hanldeOnEditApartment = (apartment) => {
		setOpenCreateAppartmentDialog(true);
		setEditAprtment({ buildingId: buildingId, ...apartment });
	};

	const handleOpenDeleteDialog = (row) => {
		//onDelete(row);
		setDeletedRow(row);
		setOpenDeleteDialog(true);
	};

	const handleDeleteAppartment = () => {
		setDeleting(true);
		Post(
			{ ids: [deletedRow.id] },
			Post_DeleteAppartment_URL,
			null,
			(resp) => {
				onDelete(deletedRow);
				enqueueSnackbar(
					getTranslation(
						"Appartment is deleted",
						"Appartment is deleted",
						"Appartment is deleted"
					),
					{
						variant: "success",
					}
				);
				setDeleting(false);
				setOpenDeleteDialog(false);
			},
			(error) => {
				enqueueSnackbar(
					getTranslation(
						"Not able to delete. Appartment is attached with some data.",
						"Not able to delete. Appartment is attached with some data.",
						"Not able to delete. Appartment is attached with some data."
					),
					{ variant: "error" }
				);
				setOpenDeleteDialog(false);
				setDeleting(false);
			}
		);
	};

	const handleCreateAprtment = () => {
		setOpenCreateAppartmentDialog(true);
	};

	const submitApartment = async (values) => {
		setIsLoading(true);
		Post(
			values,
			editAprtment ? Post_UpdateAppartment_URL : Post_InsertAppartment_URL,
			null,
			(resp) => {
				setIsLoading(false);
				editAprtment ? onApartmentUpdate(editAprtment) : onApartmentAdd();
				setOpenCreateAppartmentDialog(false);
				enqueueSnackbar(
					editAprtment
						? resp
							? getTranslation(resp.data, resp.data, resp.data)
							: getTranslation(
									"Apartment updated successfully",
									"Apartment updated successfully",
									"Apartment updated successfully"
							  )
						: resp
						? resp.data
						: getTranslation(
								"Apartment added successfully",
								"Apartment added successfully",
								"Apartment added successfully"
						  ),
					{ variant: "success" }
				);
			},
			(error) => {
				setIsLoading(false);
				setOpenCreateAppartmentDialog(false);
				enqueueSnackbar(
					error
						? getTranslation(error.data, error.data, error.data)
						: getTranslation(
								"Something went wrong. Please contact with admin.",
								"Something went wrong. Please contact with admin.",
								"Something went wrong. Please contact with admin."
						  ),
					{
						variant: "error",
					}
				);
			}
		);
		setEditAprtment(null);
	};
	const GetAllOwners = () => {
		Get(
			{},
			Get_AllBuildingOwners_URL,
			null,
			(res) => {
				setAllOwners(res?.data);
			},
			(err) => {
				enqueueSnackbar(
					err
						? err.data
						: getTranslation(
								"Something went wrong. Please contact with admin",
								"Something went wrong. Please contact with admin",
								"Something went wrong. Please contact with admin"
						  )
				);
			}
		);
	};

	const addBuildingOwner = (payload) => {
		setIsLoading(true);
		Post(
			payload,
			Post_AddBuildingOwner_URL,
			null,
			(res) => {
				setReferesh(true);
				setIsLoading(false);
				enqueueSnackbar(
					res
						? res?.data
						: getTranslation(
								"Owner Added Successfully",
								"Owner Added Successfully",
								"Owner Added Successfully"
						  ),
					{
						variant: "success",
					}
				);
			},
			(err) => {
				setIsLoading(false);
				enqueueSnackbar(
					err
						? err.data
						: getTranslation(
								"Something went wrong. Please contact with admin",
								"Something went wrong. Please contact with admin",
								"Something went wrong. Please contact with admin"
						  ),
					{
						variant: "error",
					}
				);
			}
		);
	};

	const getBuildingPercentage = () => {
		Get(
			{ buildingId: id?.buildingId },
			Get_BuildingPercentage_URL,
			null,
			(res) => {
				setBuildingPercentage(res?.data);
			},
			(err) => {
				enqueueSnackbar(
					err
						? getTranslation(err.data, err.data, err.data)
						: getTranslation(
								"Something went wrong. Please contact with admin",
								"Something went wrong. Please contact with admin",
								"Something went wrong. Please contact with admin"
						  ),
					{ variant: "error" }
				);
			}
		);
	};

	const addOwner = (payload) => {
		setIsLoading(true);
		Post(
			payload,
			Post_AddOwner_URL,
			null,
			(res) => {
				setOpenCreateOwnerDialog(false);
				enqueueSnackbar(res ? res.data : "Owner Added Successfully", {
					variant: "success",
				});
				setReferesh(true);
				setIsLoading(false);
			},
			(err) => {
				enqueueSnackbar(
					err
						? getTranslation(err.data, err.data, err.data)
						: "Can't add owner. Please contact with admin",
					{
						variant: "error",
					}
				);
				setIsLoading(false);
			}
		);
	};

	//
	const handleUpdateOwner = (payload) => {
		setIsLoading(true);
		Post(
			payload,
			Post_UpdateOwner_URL,
			null,
			(res) => {
				setOpenCreateOwnerDialog(false);
				enqueueSnackbar(
					res
						? getTranslation(res.data, res.data, res.data)
						: getTranslation(
								"Owner updated successfully ",
								"Owner updated successfully ",
								"Owner updated successfully "
						  ),
					{
						variant: "success",
					}
				);
				setReferesh(true);
				setIsLoading(false);
				getOwners();
			},
			(err) => {
				enqueueSnackbar(
					err
						? getTranslation(err?.data, err?.data, err?.data)
						: getTranslation(
								"Something went wrong. Please contact with admin",
								"Something went wrong. Please contact with admin",
								"Something went wrong. Please contact with admin"
						  ),
					{
						variant: "error",
					}
				);
				setIsLoading(false);
			}
		);
	};

	const handleUpdateBuildingOwner = (payload) => {
		setIsLoading(true);
		Post(
			payload,
			Post_UpdateBuildingOwner_URL,
			null,
			(res) => {
				enqueueSnackbar(
					res
						? getTranslation(res.data, res.data, res.data)
						: getTranslation(
								"Owner Updated Successfully",
								"Owner Updated Successfully",
								"Owner Updated Successfully"
						  ),
					{
						variant: "success",
					}
				);
				setIsLoading(false);
				setReferesh(true);
			},
			(err) => {
				enqueueSnackbar(
					err
						? err.data
						: getTranslation(
								"An error occurred while updating owner. Please contact with admin.",
								"An error occurred while updating owner. Please contact with admin.",
								"An error occurred while updating owner. Please contact with admin."
						  ),
					{ variant: "error" }
				);
				setIsLoading(false);
			}
		);
	};
	const getOwners = (pageSize, pageNumber, searchString) => {
		Get(
			{
				buildingId: id?.buildingId,
				searchString: searchString ? searchString : "",
				pageNumber: pageNumber ? pageNumber : 0,
				pageSize: pageSize ? pageSize : 10,
				sortDirection: 1,
				sort: "fullName",
			},
			Get_BuildingOwnersWithPagination_URL,
			null,
			(res) => {
				setOwners(res?.data);
			},
			(err) => {
				enqueueSnackbar(getTranslation(err?.data, err?.data, err?.data), {
					variant: "error",
				});
			}
		);
	};

	const handleDeleteOwnerDialog = (status) => {
		if (status) setOpenDeleteDialog(status);
		else setOpenDeleteDialog(status);
	};

	const handleDeleteOwner = (ownerId) => {
		setDeleting(true);
		Get(
			{ ownerId: ownerId },
			Get_DeleteOwner_URL,
			null,
			(res) => {
				setReferesh(true);
				setOpenDeleteDialog(false);
				setDeleting(false);
				enqueueSnackbar(res?.data, {
					variant: "success",
				});
				getOwners();
			},
			(err) => {
				setDeleting(false);
				enqueueSnackbar(err?.data, {
					variant: "error",
				});
			}
		);
	};

	useEffect(() => {
		if (type == 1) loadBuildingCostList();
	}, [page, searchString, rowsPerPage, costSort, costSortDirection, from, to]);
	const loadBuildingCostList = () => {
		Get(
			{
				buildingId: buildingId,
				searchString: searchString,
				pageNumber: page,
				pageSize: rowsPerPage,
				sort: costSort,
				sortDirection: costSortDirection,
				from: moment(from).format("MM-DD-YYYY"),
				to: moment(to).format("MM-DD-YYYY"),
			},
			Get_CostWithPagination_URL,
			null,
			(resp) => {
				setBuildingCost(resp?.data);
			},
			(error) => {
				enqueueSnackbar(
					getTranslation(
						"Can't load building costs",
						"Can't load building costs",
						"Can't load building costs"
					),
					{
						variant: "error",
					}
				);
			}
		);
	};
	return (
		<>
			<Card>
				<CardHeader />
				<CardContent>
					<ToggleButtonGroup
						size="small"
						style={{
							display: "flex",
							alignItems: "center",
							justifyContent: "center",
						}}
						id="type"
						value={type}
						exclusive
						onChange={(event, type) => {
							if (type !== null) {
								setType(type);
								if (type == 1) {
									loadBuildingCostList();
								}
							}
						}}
					>
						<ToggleButton value={0} aria-label="left aligned">
							<Typography>
								{getTranslation("Apartments", "Appartements", "Wohnungen")}
							</Typography>
						</ToggleButton>
						<ToggleButton value={1} aria-label="centered">
							<Typography>
								{getTranslation("Expenses", "Expenses", "Expenses")}
							</Typography>
						</ToggleButton>
						<ToggleButton value={2} aria-label="centered">
							<Typography>
								{getTranslation("OWNER", "OWNER", "OWNER")}
							</Typography>
						</ToggleButton>
					</ToggleButtonGroup>
					<br />
					{type === 0 ? (
						<TableComponent
							loading={loading}
							dense
							colums={apartmentColumns}
							sortBy="name"
							rows={rows?.data}
							count={rows?.totalCount}
							page={page}
							rowsPerPage={rowsPerPage}
							handleChangePage={handleChangePage}
							handleChangeRowsPerPage={handleChangeRowsPerPage}
							actions={[
								{
									component: (rowData) => (
										<EditButton
											onClick={() => {
												hanldeOnEditApartment(rowData);
											}}
										/>
									),
								},
							]}
							viewSearch
							onSearch={(searchString) => handleSearchappartment(searchString)}
							viewDelete
							onDelete={handleOpenDeleteDialog}
							viewCreate
							onCreate={() => {
								if (appKeys[1].value <= rows?.totalCount) {
									setUpGradeDialogOpen(true);
								} else {
									onCreateAparment();
								}
							}}
						/>
					) : type == 1 ? (
						<>
							<Grid container spacing={2} style={{ marginBottom: "10px" }}>
								<Grid item>
									<DatePicker
										label={getTranslation("From", "From", "From")}
										value={from}
										required
										size="small"
										onChange={(newDate) => {
											setFrom(newDate);
										}}
									/>
								</Grid>
								<Grid item>
									<DatePicker
										label={getTranslation("To", "To", "To")}
										value={to}
										required
										size="small"
										onChange={(newDate) => {
											setTo(newDate);
										}}
									/>
								</Grid>
							</Grid>

							<TableComponent
								dense
								//title="Apartments"
								colums={costColumns}
								sortBy="date"
								sortDirection="desc"
								rows={buildingCost?.data}
								count={buildingCost?.totalCount}
								page={page}
								rowsPerPage={rowsPerPage}
								handleChangePage={handleChangePage}
								handleChangeRowsPerPage={handleChangeRowsPerPage}
								viewSearch
								onSearch={(searchString) =>
									handleSearchappartment(searchString)
								}
								onSorting={(property, direction) => {
									setCostSort(property);
									setCostSortDirection(direction === "asc" ? 0 : 1);
								}}
							/>
						</>
					) : (
						<>
							<Owner
								owners={owners}
								onSubmit={addOwner}
								onUpdateOwner={handleUpdateOwner}
								onDeleteOwner={handleDeleteOwner}
								onUpdateBuildingOwner={handleUpdateBuildingOwner}
								isOpen={openDeleteDialog}
								handleDeleteDialog={handleDeleteOwnerDialog}
								ownerDeleting={deleting}
								getOwners={getOwners}
								onCreate={onCreateOwner}
								openOwnerDialog={openCreateOwnerDialog}
								allOwners={allOwners}
								selectedValue={recentOwner}
								addBuildingOwner={addBuildingOwner}
								buildingPercentage={buildingPercentage}
								onClose={() => {
									setOpenCreateOwnerDialog(false);
								}}
								isLoading={isLoading}
							/>
						</>
					)}
				</CardContent>
			</Card>
			<AddAppartmentDialog
				apartment={editAprtment}
				enableEdit={editAprtment !== null}
				isOpen={openCreateAppartmentDialog}
				onClose={() => {
					setOpenCreateAppartmentDialog(false);
					setEditAprtment(null);
				}}
				onSubmit={submitApartment}
				isLoading={isLoading}
			/>
			<AlertDialog
				open={openDeleteDialog}
				deleting={deleting}
				onClose={() => setOpenDeleteDialog(false)}
				onSubmit={handleDeleteAppartment}
			/>
			<DialogComponent
				open={upGradeDialogOpen}
				onClose={() => setUpGradeDialogOpen(false)}
				title={getTranslation(
					"Upgrade apartment",
					"Upgrade apartment",
					"Upgrade apartment"
				)}
			>
				<Upgrade
					descriptionMessage={getTranslation(
						"To use this premium feature you need to upgrade to Professional account.",
						"To use this premium feature you need to upgrade to Professional account.",
						"To use this premium feature you need to upgrade to Professional account."
					)}
				/>
			</DialogComponent>
		</>
	);
};
export default Appartments;
