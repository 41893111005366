import React, { useState, useEffect } from "react";
import {
  CardContent,
  CardActions,
  Grid,
  TextField,
  Box,
  Typography,
  CardHeader,
} from "@material-ui/core";
import { Formik, Form } from "formik";
import Card from "../../../components/Card";
import ButtonWithLoading from "../../../components/ButtonWithLoading";
import * as Yup from "yup";
import Switch from "../../../components/Switch";
import DialogComponent from "../../../components/Dialog";
import { getTranslation } from "../../../heplers/translationHelper";
import { CountrySelection } from "../../../constants/countries";

const AddBuildingDialog = ({
  building,
  isOpen,
  onClose,
  onSubmit,
  enableEdit,
  isLoading,
}) => {
  const initialValues = {
    name: "",
    address: "",
    country: "",
    state: "",
    zipCode: "",
    town: "",
    country: "",
    state: "",
    comments: "",
    status: true,
  };

  const basicValidationSchema = Yup.object().shape({
    name: Yup.string().nullable().required(" Name is required"),
    address: Yup.string().nullable().required("Address is required"),
    town: Yup.string().nullable().required("Town is required"),
    country: Yup.string().nullable().required("Country is required"),
    state: Yup.string().nullable().required("State is required"),
    zipCode: Yup.string()
      .nullable()
      .required("Zip code is required")
      .test("non-negative", "Zip code cannot be negative", (value) => {
        if (value) {
          const zipCode = parseInt(value);
          return zipCode > 0;
        }
        return true;
      }),
  });

  const defaultValue = building ? building : initialValues;
  return (
    <DialogComponent
      title={getTranslation("Add Building", "Add Building", "Add Building")}
      open={isOpen}
      onClose={onClose}
    >
      <br />
      <br />
      <Formik
        enableReinitialize
        initialValues={defaultValue}
        validationSchema={basicValidationSchema}
        onSubmit={(values, actions) => {
          actions.setSubmitting(true);
          onSubmit(values, actions);
          actions.resetForm(initialValues);
        }}
      >
        {({
          errors,
          touched,
          values,
          handleSubmit,
          isSubmitting,
          getFieldProps,
          setFieldValue,
          handleChange,
        }) => (
          <Form>
            <Grid container spacing={2}>
              <Grid item xs={6} sm={6} md={6} lg={6}>
                <TextField
                  fullWidth
                  id="name"
                  label={getTranslation("Name", "Name", "Name")}
                  required
                  size="small"
                  variant="outlined"
                  name="name"
                  {...getFieldProps("name")}
                  error={touched.name && Boolean(errors.name)}
                  helperText={touched.name && errors.name}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    pattern: "^[A-Za-z\\s]+$",
                    onKeyPress: (event) => {
                      const charCode = event.charCode;
                      if (!/[A-Za-z\s]/.test(String.fromCharCode(charCode))) {
                        event.preventDefault();
                      }
                    },
                  }}
                />
              </Grid>
              <Grid item xs={6} sm={6} md={6} lg={6}>
                <CountrySelection
                  label={getTranslation("Country", "Country", "Country")}
                  required={true}
                  selected={values.country}
                  error={touched.country && Boolean(errors.country)}
                  helperText={touched.country && errors.country}
                  name="country"
                  onChange={(e, value) => {
                    setFieldValue("country", value);
                  }}
                />
              </Grid>
              <Grid item xs={6} sm={6} md={6} lg={6}>
                <TextField
                  fullWidth
                  id="state"
                  label={getTranslation("State", "State", "State")}
                  size="small"
                  variant="outlined"
                  name="state"
                  {...getFieldProps("state")}
                  error={touched.state && Boolean(errors.state)}
                  helperText={touched.state && errors.state}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={6} sm={6} md={6} lg={6}>
                <TextField
                  fullWidth
                  id="address"
                  label={getTranslation("Address", "Address", "Address")}
                  required
                  size="small"
                  variant="outlined"
                  name="address"
                  {...getFieldProps("address")}
                  error={touched.address && Boolean(errors.address)}
                  helperText={touched.address && errors.address}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    pattern: "^[A-Za-z0-9#\\s]+$",
                    onKeyPress: (event) => {
                      const charCode = event.charCode;
                      if (
                        !/[A-Za-z0-9#\s]/.test(String.fromCharCode(charCode))
                      ) {
                        event.preventDefault();
                      }
                    },
                  }}
                />
              </Grid>

              <Grid item xs={6} sm={6} md={6} lg={6}>
                <TextField
                  fullWidth
                  id="town"
                  label={getTranslation("Town", "Town", "Town")}
                  required
                  size="small"
                  variant="outlined"
                  name="town"
                  {...getFieldProps("town")}
                  error={touched.town && Boolean(errors.town)}
                  helperText={touched.town && errors.town}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    pattern: "^[A-Za-z\\s]+$",
                    onKeyPress: (event) => {
                      const charCode = event.charCode;
                      if (!/[A-Za-z\s]/.test(String.fromCharCode(charCode))) {
                        event.preventDefault();
                      }
                    },
                  }}
                />
              </Grid>
              <Grid item xs={6} sm={6} md={6} lg={6}>
                <TextField
                  fullWidth
                  id="zipCode"
                  label={getTranslation("Zipcode", "Zipcode", "Zipcode")}
                  required
                  size="small"
                  variant="outlined"
                  name="zipCode"
                  type="number"
                  {...getFieldProps("zipCode")}
                  error={touched.zipCode && Boolean(errors.zipCode)}
                  helperText={touched.zipCode && errors.zipCode}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{ min: 0 }}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <TextField
                  fullWidth
                  id="comment"
                  label={getTranslation("Comment", "Comment", "Comment")}
                  multiline
                  rows={5}
                  size="small"
                  variant="outlined"
                  name="comment"
                  type="number"
                  {...getFieldProps("comment")}
                  error={touched.comment && Boolean(errors.comment)}
                  helperText={touched.comment && errors.comment}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
            </Grid>
            <Box
              pr={1}
              pb={1}
              width="100%"
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <Typography>
                {getTranslation("Status", "Status", "Status")}
                <Switch
                  id="status"
                  checked={values.status}
                  onChange={handleChange}
                />
              </Typography>
              <ButtonWithLoading
                title={
                  enableEdit
                    ? getTranslation("Update", "Update", "Update")
                    : getTranslation("Add", "Add", "Add")
                }
                style={{ backgroundColor: "rgb(229,60,22)", color: "white" }}
                size="medium"
                variant="contained"
                loading={isLoading}
                onClick={handleSubmit}
                type="submit"
              />
            </Box>
          </Form>
        )}
      </Formik>
    </DialogComponent>
  );
};

export default AddBuildingDialog;
