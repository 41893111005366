import {
	Box,
	Checkbox,
	Divider,
	FormControl,
	FormControlLabel,
	Grid,
	InputLabel,
	MenuItem,
	Select,
	TextField,
	Typography,
} from "@material-ui/core";
import { Form, Formik } from "formik";
import React from "react";
import { getTranslation } from "../../../heplers/translationHelper";
import {
	DeleteIConButton,
	EditButton,
} from "../../../components/ButttonsWithIcons";
import { tenantCategories } from "../../enums";
import * as Yup from "yup";
import { useState } from "react";

const SplitAmount = ({
	onSubmit,
	count,
	item,
	deleteItem,
	enableError,
	disableError,
	update,
	title,
}) => {
	const validationSchema = Yup.object().shape({
		amount: Yup.number()
			.min(0, "Amount cannot be less than the initial amount")
			.positive("cant be negative")
			.required("Amount is required"),
	});
	const defaultValue = item;
	return (
		<div>
			<Formik
				enableReinitialize
				initialValues={defaultValue}
				validationSchema={validationSchema}
				onSubmit={(values, actions) => {
					actions.setSubmitting(true);
					onSubmit(values, actions);
				}}
			>
				{({
					errors,
					touched,
					values,
					handleSubmit,
					isSubmitting,
					getFieldProps,
					setFieldValue,
					setFieldError,
				}) => (
					<Form>
						{count == 0 ? (
							<Grid container spacing={2}>
								<Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
									<Typography
										style={{
											fontSize: "16px",
											fontWeight: "700",
											lineHeight: "19px",
											letterSpacing: "-0.02em",
											textAlign: "left",
											color: "rgb(0,68,103)",
										}}
									>
										{getTranslation("Indexation", "Indexation", "Indexation")}
									</Typography>
								</Grid>
								<Grid>
									<Divider
										orientation="vertical"
										variant="middle"
										style={{
											height: "0px",
										}}
									/>
								</Grid>
								<Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
									<Typography
										style={{
											fontSize: "16px",
											fontWeight: "700",
											lineHeight: "19px",
											letterSpacing: "-0.02em",
											textAlign: "left",
											color: "rgb(0,68,103)",
										}}
									>
										{getTranslation("Amount", "Amount", "Amount")}
									</Typography>
								</Grid>
								<Grid>
									<Divider
										orientation="vertical"
										variant="middle"
										style={{
											height: "0px",
										}}
									/>
								</Grid>
								<Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
									<Typography
										style={{
											fontSize: "16px",
											fontWeight: "700",
											lineHeight: "19px",
											letterSpacing: "-0.02em",
											textAlign: "left",
											color: "rgb(0,68,103)",
										}}
									>
										{getTranslation("Type", "Type", "Type")}
									</Typography>
								</Grid>
								<Grid>
									<Divider
										orientation="vertical"
										variant="middle"
										style={{
											height: "0px",
										}}
									/>
								</Grid>
								<Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
									<Typography
										style={{
											fontSize: "16px",
											fontWeight: "700",
											lineHeight: "19px",
											letterSpacing: "-0.02em",
											textAlign: "left",
											color: "rgb(0,68,103)",
										}}
									>
										{getTranslation(
											"Description",
											"Description",
											"Description"
										)}
									</Typography>
								</Grid>
							</Grid>
						) : (
							""
						)}
						<Grid container spacing={2} style={{ marginTop: "12px" }}>
							<Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
								<FormControl>
									<FormControlLabel
										disabled={count < 1}
										labelPlacement="start"
										control={
											<Checkbox
												id="isIndexed"
												checked={values.isIndexed}
												onChange={(e, check) => {
													setFieldValue("isIndexed", check);
													//  onSubmit(count, values);
												}}
												onBlur={() => {
													onSubmit(count, values);
												}}
												style={{ color: "rgb(251,94,57)" }}
											/>
										}
									/>
								</FormControl>
							</Grid>
							<Grid>
								<Divider
									orientation="vertical"
									variant="middle"
									style={{
										height: "20px",
										marginTop: "16px",
										backgroundColor: "rgb(251,105,70)",
									}}
								/>
							</Grid>

							<Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
								<TextField
									fullWidth
									disabled={update ? count === 0 || count === 1 : count === 0}
									value={values.amount}
									label={getTranslation("Amount", "Amount", "Amount")}
									variant="outlined"
									size="small"
									type="number"
									InputLabelProps={{
										shrink: true,
									}}
									style={{
										boxShadow: "0px 2px 3px rgba(0, 0, 0, 0.2)",
										borderRadius: "5px",
									}}
									onChange={(e) => {
										setFieldValue("amount", e.target.value);
									}}
									onBlur={() => {
										if (values.amount >= item.amount) {
											disableError();
											onSubmit(count, values);
										} else {
											setFieldError("amount", "amount cannot be less");
											enableError();
										}
									}}
									error={touched.amount && Boolean(errors.amount)}
									helperText={touched.amount && errors.amount}
									InputProps={{ inputProps: { min: item.amount } }}
								/>
							</Grid>
							<Grid>
								<Divider
									orientation="vertical"
									variant="middle"
									style={{
										height: "20px",
										marginTop: "16px",
										backgroundColor: "rgb(251,105,70)",
									}}
								/>
							</Grid>
							<Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
								<FormControl fullWidth variant="outlined">
									<Select
										style={{
											height: "40px",
										}}
										disabled={count === 0 || count === 1}
										labelId="demo-simple-select-label"
										id="demo-simple-select"
										placeholder="Category"
										name="duesCategory"
										value={values.type || tenantCategories[0].id}
										onChange={(e) => {
											setFieldValue("type", e.target.value);
										}}
										onBlur={() => {
											onSubmit(count, values);
										}}
									>
										{tenantCategories.map((category) => (
											<MenuItem key={category.id} value={category.id}>
												{category.label}
											</MenuItem>
										))}
									</Select>
								</FormControl>
							</Grid>
							<Grid>
								<Divider
									orientation="vertical"
									variant="middle"
									style={{
										height: "20px",
										marginTop: "16px",
										backgroundColor: "rgb(251,105,70)",
									}}
								/>
							</Grid>
							<Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
								<TextField
									disabled={count === 0}
									fullWidth
									value={values.description}
									label={getTranslation(
										"Description",
										"Description",
										"Description"
									)}
									variant="outlined"
									size="small"
									InputLabelProps={{
										shrink: true,
									}}
									style={{
										boxShadow: "0px 2px 3px rgba(0, 0, 0, 0.2)",
										borderRadius: "5px",
									}}
									onChange={(e) => {
										setFieldValue("description", e.target.value);
									}}
									onBlur={() => {
										onSubmit(count, values);
									}}
									error={touched.description && Boolean(errors.description)}
									helperText={touched.description && errors.description}
								/>
							</Grid>
							<Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
								{count < 2 ? (
									""
								) : (
									<DeleteIConButton
										onClick={() => {
											deleteItem(count, values);
										}}
									/>
								)}
							</Grid>
						</Grid>
					</Form>
				)}
			</Formik>
		</div>
	);
};

export default SplitAmount;
