import { Card, CardContent, CardHeader, Grid } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import Table from "../../../../../components/table";
import { getTranslation } from "../../../../../heplers/translationHelper";
import { Box, IconButton, Tooltip, Typography } from "@material-ui/core";
import { AddPostDueDate } from "../../addPostponDueDate";
import { ReminderToAll, PostponIcon } from "../../../../../components/icons";
import { DownloadFile, Get, Post } from "../../../../../actions";
import {
  Get_DeleteTenantBank_URL,
  Get_GetTenantBanks_URL,
  Get_NotesWithPagination_URL,
  Get_TenantDuesSummary_URL,
  Post_PostPoneDue_URL,
} from "../../../../../constants/apiUrls";
import { useSnackbar } from "notistack";
import { format } from "../../../../../heplers/format";
import { DownloadButton } from "../../../../../components/ButttonsWithIcons";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import { DeleteSharp } from "@material-ui/icons";
import TableComponent from "../../../../../components/table";
import DialogComponent from "../../../../../components/Dialog";
import AddNoteDialog from "../../recoveryprocedure/details/components/addNoteDialog";
import CardComponent from "../../../../../components/Card";



const tenantBankColumns = [
  {
    id: "counterpartReference",
    numeric: false,
    disablePadding: true,
    label: getTranslation(
      "Counterpart Reference",
      "Counterpart Reference",
      "Counterpart Reference"
    ),
    component: ({ row }) => (
      <Typography>{row?.counterpartReference}</Typography>
    ),
  },
  {
    id: "counterpartName",
    numeric: false,
    disablePadding: true,
    label: getTranslation(
      "Counterpart Name",
      "Counterpart Name",
      "Counterpart Name"
    ),
    component: ({ row }) => <Typography>{row?.counterpartName}</Typography>,
  },
  {
    id: "actions",
    label: getTranslation("Action", "Action", "Action"),
    align: "right",
  },
];


const Banks = ({ tenantId, loading, rows, onFilter }) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchString, setSearchString] = useState("");
  const [sort, setSort] = useState("date");
  const [sortDirection, setSortDirection] = useState(1);
  const [due, setDue] = useState(null);
  const [tenantBanks, setTenantBanks] = useState();

  const { enqueueSnackbar } = useSnackbar();


  // filter useEffect
  useEffect(() => {
    onFilter({
      searchString,
      pageNumber: page,
      pageSize: rowsPerPage,
      sort: sort,
      sortDirection: sortDirection,
      tenantId: tenantId,
    });
  }, [
    page,
    searchString,
    rowsPerPage,
    sort,
    sortDirection,
   
  ]);

  //handlers
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  //set rowperpage
  const handleChangeRowsPerPage = (event) => {
    localStorage.setItem("sizeDue", parseInt(event.target.value));
    setRowsPerPage(localStorage.getItem("sizeDue"));
    setPage(0);
  };
  const handleSearchDetails = (searchString) => {
    setSearchString(searchString);
  };

  //delete tenant bank data
  const handleDeleteTenantBank = (row) => {
    Get(
      { counterpartReference: row?.counterpartReference },
      Get_DeleteTenantBank_URL,
      null,
      (resp) => {
        enqueueSnackbar("Tenant bank information deleted successfully", {
          variant: "success",
        });
        setTenantBanks((tenantBanks) =>
          tenantBanks.filter(
            (bank) => bank.counterpartReference !== row.counterpartReference
          )
        );
      },
      (error) => {
        enqueueSnackbar(error?.data, { variant: "error" });
      }
    );
  };

  //for tenant dues summay and tenant bank
  useEffect(() => {
    getTenantBanks();
  }, []);

  const getTenantBanks = () => {
    Get(
      { tenantId: tenantId },
      Get_GetTenantBanks_URL,
      null,
      (resp) => {
        setTenantBanks(resp?.data);
      },
      (err) => {
      }
    );
  };
  return (
    <>
      <br />

      {/* tenant bank table area  */}
      <CardComponent  title={getTranslation("Tenant Banks", "Tenant Banks", "Tenant Banks")}>
      <br/>
      <br/>
        <CardContent>
          {tenantBanks == null ? (
            <Typography variant="h4" align="center">
              {getTranslation(
                "No bank record exists",
                "No bank record exists",
                "No bank record exists"
              )}
            </Typography>
          ) : (
            <Table
              loading={loading}
              dense
              colums={tenantBankColumns}
              rows={tenantBanks}
              page={page}
              rowsPerPage={rowsPerPage}
              count={tenantBanks?.length}
              actions={[
                {
                  component: (row) => (
                    <IconButton
                      size="small"
                      variant="contained"
                      onClick={() => handleDeleteTenantBank(row)}
                    >
                      <DeleteSharp />
                    </IconButton>
                  ),
                },
              ]}
              viewSearch
            />
          )}
        </CardContent>
      </CardComponent>
      <br />
  
    </>
  );
};
export default Banks;
