import Dialog from "../../../components/Dialog";
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useSnackbar } from "notistack";
import { Get, Post } from "../../../actions/apiActions";
import {
	CardContent,
	CardActions,
	Grid,
	TextField,
	Box,
	Typography,
	Checkbox,
} from "@material-ui/core";
import * as Yup from "yup";
import { Formik } from "formik";
import ButtonWithLoading from "../../../components/ButtonWithLoading";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { Get_AllTanenets_URL } from "../../../constants/apiUrls";
import { getTranslation } from "../../../heplers/translationHelper";
import FundtoCategorymapper from "./fundtoCategorymapper";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import { tenantCategories } from "../../enums";
import _ from "lodash";

const columns = [
	{
		id: "counterpartName",
		numeric: false,
		disablePadding: true,
		label: getTranslation(
			"Counter Part Name",
			"Counter Part Name",
			"Counter Part Name"
		),
	},
	{
		id: "amount",
		numeric: false,
		disablePadding: true,
		label: getTranslation("Amount", "Amount", "Amount"),
	},
	{
		id: "remittanceinformation",
		numeric: false,
		disablePadding: true,
		label: getTranslation(
			"Remittance Information",
			"Remittance Information",
			"Remittance Information"
		),
	},
	{
		id: "actions",
		numeric: false,
		disablePadding: true,
		label: getTranslation("Actions", "Actions", "Actions"),
	},
];

export const AddFundsToTenantDialog = ({
	counterpartName,
	remittanceinformation,
	amount,
	counterpartReference,
	isOpen,
	editTenFunds,
	enableEdit,
	tenantDetails,
	clearLoading,
	bank,
	onClose,
	onSubmit,
	clearTransction,
}) => {
	const history = useHistory();
	const { enqueueSnackbar } = useSnackbar();
	const [tenantList, setTenantList] = useState([]);
	const [selectedTenant, setSelectedTenant] = useState(null);
	const [isMakeRecurringEnabled, setIsMakeRecurringEnabled] = useState(false);
	const [counterPartName, setCounterPartName] = useState();
	const [supplierAmount, setSupplierAmount] = useState();
	const [supplierRemittanceinformation, setSupplierRemittanceinformation] =
		useState();
	const [isCounterpartNameEnabled, setIsCounterpartNameEnabled] =
		useState(false);
	const [isAmountEnabled, setIsAmountEnabled] = useState(false);
	const [isRemittanceInformationEnabled, setIsRemittanceInformationEnabled] =
		useState(false);
	const [makeRecurringDisable, setMakeRecurringDisable] = useState(false);
	const [amountError, setAmountError] = useState(false);

	const enableAmountError = () => {
		setAmountError(true);
	};

	const disableAmountError = () => {
		setAmountError(false);
	};

	//////////////////////////////// Get Supplier ///////////////////
	useEffect(() => {
		loadTenants();
	}, []);

	const loadTenants = (values) => {
		Get(
			values,
			Get_AllTanenets_URL,
			history,
			(resp) => {
				setTenantList(resp?.data);
			},
			(error) => {
				enqueueSnackbar(
					getTranslation(
						" Can't Load the building! Internal server Error ",
						" Impossible de charger le b�timent�! Erreur interne du serveur ",
						" Das Geb�ude kann nicht geladen werden! Interner Serverfehler "
					),
					{ variant: "error" }
				);
			}
		);
	};

	useEffect(() => {
		setIsMakeRecurringEnabled(false);
		setMakeRecurringDisable(false);
		if (counterpartName) {
			// alert(counterpartName.toLowerCase().startsWith("Schons".toLowerCase()))
			setSelectedTenant(
				tenantList?.find(
					(x) =>
						counterpartName
							?.toLowerCase()
							?.startsWith(x.firstName?.toLowerCase()) ||
						counterpartName?.toLowerCase().startsWith(x.lastName?.toLowerCase())
				)
			);
		}
		if (!enableEdit) {
			setSelectedTenant(tenantList?.find((x) => x.id == bank?.tenantId));
		}
	}, [counterpartName]);

	const initialValues = {
		tenantId: 0,
		duesCategory: [],
		isMakeRecurringEnabled: false,
		supplierAutoMatchingOptions: {
			counterpartName: counterPartName,
			amount: supplierAmount,
			remittanceinformation: supplierRemittanceinformation,
			counterpartReference: counterpartReference,
			isCounterpartNameEnabled: false,
			isAmountEnabled: false,
			isRemittanceInformationEnabled: false,
		},
	};
	const basicValidationSchema = Yup.object().shape({
		tenantId: Yup.number()
			.min(
				1,
				getTranslation(
					" Tenant is required. ",
					" Le locataire est requis. ",
					" Mieter ist erforderlich. "
				)
			)
			.required("Tenant is required."),
		amount: Yup.number().min(1, "Add amount is required"),
	});

	const [fundToCategoryArray, setFundToCategoryArray] = useState([]);
	useEffect(() => {
		setFundToCategoryArray([
			{
				duesCategory: 0,
				amount: amount,
			},
		]);
	}, [amount]);

	useEffect(() => {
		setCounterPartName(counterpartName);
		setSupplierAmount(amount);
		setSupplierRemittanceinformation(remittanceinformation);
		setIsCounterpartNameEnabled(false);
		setIsAmountEnabled(false);
		setIsRemittanceInformationEnabled(false);
		if (isMakeRecurringEnabled) {
			setIsCounterpartNameEnabled(true);
			setIsAmountEnabled(true);
			setIsRemittanceInformationEnabled(true);
		}
	}, [counterpartName, isMakeRecurringEnabled]);

	useEffect(() => {
		if (fundToCategoryArray.length == 2) {
			setMakeRecurringDisable(true);
			setIsMakeRecurringEnabled(false);
			enqueueSnackbar("Can not enable recurring for more then one category", {
				variant: "info",
			});
		}
	}, [fundToCategoryArray.length]);

	const [remainingItems, setRemainingItems] = useState(tenantCategories);

	const handleOnAdd = (values, action) => {
		setAmountError(false);
		const arr = fundToCategoryArray;
		const balance =
			amount - _.sumBy(fundToCategoryArray, (x) => parseFloat(x.amount));
		const remainingItems = tenantCategories.filter((item) => {
			const match = arr.find((element) => element.duesCategory === item.id);
			return !match;
		});
		setRemainingItems(remainingItems);
		if (balance >= 1)
			setFundToCategoryArray([
				...arr,
				{ duesCategory: remainingItems[0].id, amount: balance.toFixed(2) },
			]);
	};

	const handleOnChange = (values, actions) => {
		const arr = fundToCategoryArray;
		arr[arr.length - 1] = values;
		setFundToCategoryArray([...arr]);
	};

	const defaultValue = editTenFunds ? editTenFunds : initialValues;
	return (
		<Dialog
			open={isOpen}
			title={
				enableEdit
					? getTranslation(
							"Update funds of Tenant",
							"Mettre à jour les fonds du locataire",
							"Aktualisieren Sie die Mittel des Mieters"
					  )
					: getTranslation(
							"Add funds to Tenant",
							"Ajouter des fonds au locataire",
							"Fügen Sie dem Mieter Guthaben hinzu"
					  )
			}
			onClose={onClose}
			maxWidth="sm"
		>
			<div>
				<Formik
					enableReinitialize
					initialValues={defaultValue}
					validationSchema={basicValidationSchema}
					onSubmit={(values, actions) => {
						actions.setSubmitting(true);
						if (isMakeRecurringEnabled) {
							if (
								isAmountEnabled ||
								isCounterpartNameEnabled ||
								isRemittanceInformationEnabled
							) {
								onSubmit(
									{
										tenantId: values.tenantId,
										duesCategories: fundToCategoryArray,
										isMakeRecurringEnabled: isMakeRecurringEnabled,
										supplierAutoMatchingOptions: {
											counterpartName: counterPartName,
											amount: supplierAmount,
											remittanceinformation: supplierRemittanceinformation,
											counterpartReference: counterpartReference,
											isCounterpartNameEnabled: isCounterpartNameEnabled,
											isAmountEnabled: isAmountEnabled,
											isRemittanceInformationEnabled:
												isRemittanceInformationEnabled,
										},
									},
									actions
								);
							} else {
								enqueueSnackbar("Select atleast one recurring option", {
									variant: "error",
								});
								actions.setSubmitting(false);
							}
						} else {
							onSubmit(
								{
									tenantId: values.tenantId,
									duesCategories: fundToCategoryArray,
									isMakeRecurringEnabled: isMakeRecurringEnabled,
									supplierAutoMatchingOptions: {
										counterpartName: counterPartName,
										amount: supplierAmount,
										remittanceinformation: supplierRemittanceinformation,
										counterpartReference: counterpartReference,
										isCounterpartNameEnabled: isCounterpartNameEnabled,
										isAmountEnabled: isAmountEnabled,
										isRemittanceInformationEnabled:
											isRemittanceInformationEnabled,
									},
								},
								actions
							);
						}
					}}
				>
					{({
						errors,
						touched,
						values,
						handleSubmit,
						isSubmitting,
						getFieldProps,
						setFieldValue,
					}) => (
						<form>
							{selectedTenant && selectedTenant?.id !== values.tenantId
								? setFieldValue("tenantId", selectedTenant.id)
								: null}
							<CardContent>
								<Grid container spacing={2}>
									<Grid item xs={12} sm={12} md={12} lg={12}>
										<Autocomplete
											options={tenantList}
											getOptionLabel={(option) => option.name}
											value={selectedTenant}
											size="small"
											onChange={(e, tenant) => {
												if (tenant) {
													setFieldValue("tenantId", tenant?.id);
													setSelectedTenant(tenant);
												}
											}}
											renderInput={(params) => (
												<TextField
													{...params}
													label={getTranslation(
														"Tenant",
														"Locataires",
														"Mieter"
													)}
													variant="outlined"
													error={touched.tenantId && Boolean(errors.tenantId)}
												/>
											)}
										/>
									</Grid>
									<Grid item xs={12} sm={12} md={12} lg={12}>
										<FundtoCategorymapper
											array={fundToCategoryArray}
											setArray={setFundToCategoryArray}
											transactionAmount={amount}
											handleOnAdd={handleOnAdd}
											remainingItems={remainingItems}
											handleOnChange={handleOnChange}
											enableAmountError={enableAmountError}
											disableAmountError={disableAmountError}
										/>
									</Grid>
								</Grid>
								<Box
									style={{
										display: "flex",
										alignItems: "flex-start",
										justifyContent: "flex-start",
										marginTop: "20px",
									}}
								>
									<ButtonWithLoading
										title={
											<>
												<Typography
													variant="h5"
													style={{
														fontWeight: "bold",
														color: "rgb(0,68,103)",
													}}
												>
													{getTranslation(
														"Add split amount",
														"Add split amount",
														"Add split amount"
													)}
													&nbsp;&nbsp;{" "}
												</Typography>
												<AddCircleIcon style={{ color: "rgb(229,60,22)" }} />
											</>
										}
										size="small"
										variant="contained"
										//loading={isSubmitting}
										onClick={handleOnAdd}
										style={{
											background: "rgb(244,246,248)",
											boxShadow: "4px 4px 10px 0px rgba(0, 0, 0, 0.1)",
											padding: "12px 10px 12px 8px",
										}}
									/>
								</Box>
							</CardContent>
							<Grid item xs={12} sm={12} md={12} lg={12}>
								<table>
									<tbody>
										<tr>
											<td>
												<Checkbox
													disabled={makeRecurringDisable}
													style={{ color: "rgb(229,60,22)" }}
													checked={isMakeRecurringEnabled}
													onChange={(e) => {
														setIsMakeRecurringEnabled(!isMakeRecurringEnabled);
														setFieldValue(
															"isMakeRecurringEnabled",
															e.target.checked
														);
														setFieldValue(
															"supplierAutoMatchingOptions.isCounterpartNameEnabled",
															e.target.checked
														);
														setFieldValue(
															"supplierAutoMatchingOptions.isAmountEnabled",
															e.target.checked
														);
														setFieldValue(
															"supplierAutoMatchingOptions.isRemittanceInformationEnabled",
															e.target.checked
														);
														setFieldValue(
															"supplierAutoMatchingOptions.counterpartReference",
															counterpartReference
														);
													}}
												/>
											</td>
											<td>
												<Typography style={{ fontWeight: "bold" }}>
													{getTranslation(
														"Enable Recurring",
														"Enable Recurring",
														"Enable Recurring"
													)}
												</Typography>
											</td>
										</tr>
									</tbody>
								</table>
							</Grid>

							<Grid item xs={12} sm={12} md={12} lg={12}>
								<table>
									<tboday>
										<tr>
											<td>
												{isMakeRecurringEnabled ? (
													<Checkbox
														checked={isCounterpartNameEnabled}
														style={{ color: "rgb(229,60,22)" }}
														onChange={(e) => {
															setIsCounterpartNameEnabled(e.target.checked);
															setFieldValue(
																"supplierAutoMatchingOptions.isCounterpartNameEnabled",
																e.target.checked
															);
														}}
													/>
												) : (
													""
												)}
											</td>
											<td>
												<Typography style={{ fontWeight: "bold" }}>
													{getTranslation(
														"Counter Part Name",
														"Nom contre-partie",
														"Name Gegenpartei"
													)}
													:{" "}
												</Typography>
											</td>
										</tr>
										<tr>
											<td></td>
											<td>
												{isCounterpartNameEnabled ? (
													<TextField
														variant="outlined"
														size="small"
														multiline
														inputProps={{ cols: 50 }}
														value={counterPartName}
														onChange={(e) => {
															setCounterPartName(e.target.value);
															setFieldValue(
																"supplierAutoMatchingOptions.counterpartName",
																e.target.value
															);
														}}
													/>
												) : (
													<Typography>
														{counterPartName
															? counterPartName
															: counterpartName}
													</Typography>
												)}
											</td>
										</tr>
										<tr>
											<td>
												{isMakeRecurringEnabled ? (
													<Checkbox
														checked={isAmountEnabled}
														style={{ color: "rgb(229,60,22)" }}
														onChange={(e) => {
															setIsAmountEnabled(e.target.checked);
															setFieldValue(
																"supplierAutoMatchingOptions.isAmountEnabled",
																e.target.checked
															);
														}}
													/>
												) : (
													""
												)}
											</td>
											<td>
												<Typography style={{ fontWeight: "bold" }}>
													{getTranslation(
														"Amount  :",
														"Montant  :",
														"Menge  :"
													)}
												</Typography>
											</td>
										</tr>
										<tr>
											<td></td>
											<td>
												{isAmountEnabled ? (
													<TextField
														variant="outlined"
														size="small"
														value={supplierAmount}
														onChange={(e) => {
															setSupplierAmount(e.target.value);
															setFieldValue(
																"supplierAutoMatchingOptions.amount",
																e.target.value
															);
														}}
													/>
												) : (
													<Typography>
														{supplierAmount
															? supplierAmount
															: amount?.toFixed(2)}
													</Typography>
												)}
											</td>
										</tr>
										<tr>
											<td>
												{isMakeRecurringEnabled ? (
													<Checkbox
														checked={isRemittanceInformationEnabled}
														style={{ color: "rgb(229,60,22)" }}
														onChange={(e) => {
															setIsRemittanceInformationEnabled(
																e.target.checked
															);
															setFieldValue(
																"supplierAutoMatchingOptions.isRemittanceInformationEnabled",
																e.target.checked
															);
														}}
													/>
												) : (
													""
												)}
											</td>
											<td>
												<Typography style={{ fontWeight: "bold" }}>
													{getTranslation(
														"Remittance Information :",
														"Renseignements sur les remises",
														"Überweisungsinformationen"
													)}
												</Typography>
											</td>
										</tr>
										<tr>
											<td></td>
											<td>
												{isRemittanceInformationEnabled ? (
													<TextField
														variant="outlined"
														size="small"
														value={supplierRemittanceinformation}
														onChange={(e) => {
															setSupplierRemittanceinformation(e.target.value);
															setFieldValue(
																"supplierAutoMatchingOptions.remittanceInformation",
																e.target.value
															);
														}}
														multiline
														inputProps={{ cols: 90 }}
													/>
												) : (
													<Typography>
														{supplierRemittanceinformation
															? supplierRemittanceinformation
															: remittanceinformation}
													</Typography>
												)}
											</td>
										</tr>
									</tboday>
								</table>
								<br />
							</Grid>

							<CardActions>
								<Box
									pr={1}
									pb={1}
									width="100%"
									display="flex"
									alignItems="center"
									justifyContent="center"
								>
									<ButtonWithLoading
										title={
											<Typography
												variant="h5"
												style={{ fontWeight: "bold", color: "white" }}
											>
												{getTranslation("Confirm", "Confirm", "Confirm")}
											</Typography>
										}
										loading={isSubmitting}
										onClick={handleSubmit}
										size="small"
										variant="contained"
										style={{
											// backgroundColor: "rgb(229,60,22)",
											background: amountError
												? "rgb(170,170,170)"
												: "rgb(229,60,22)",
											color: "white",
											fontWeight: "bold",
										}}
										disabled={amountError}
									/>

									{/* reset button */}

									{enableEdit && (
										<ButtonWithLoading
											title={
												<Typography
													variant="h5"
													style={{ fontWeight: "bold", color: "white" }}
												>
													{getTranslation(
														"Reset Transaction",
														"Reset Transaction",
														"Reset Transaction"
													)}
												</Typography>
											}
											loading={clearLoading}
											onClick={clearTransction}
											size="small"
											variant="contained"
											style={{
												// backgroundColor: "rgb(229,60,22)",
												background: amountError
													? "rgb(170,170,170)"
													: "rgb(229,60,22)",
												color: "white",
												fontWeight: "bold",
												marginLeft: "10px",
											}}
											disabled={amountError}
										/>
									)}
								</Box>
							</CardActions>
							{amountError && (
								<>
									<Typography
										variant="h5"
										align="center"
										style={{ color: "red" }}
									>
										{getTranslation(
											"Adjust the remianing amount using split amount",
											"Adjust the remianing amount using split amount",
											"Adjust the remianing amount using split amount"
										)}
									</Typography>
								</>
							)}
						</form>
					)}
				</Formik>
			</div>
		</Dialog>
	);
};
