import React, { useState } from "react";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { getTranslation } from "../../../heplers/translationHelper";
import TableComponent from "../../../components/table";
import { Helmet } from "react-helmet";
import { Get, Post } from "../../../actions";
import {
	Get_AllIndexationProposalsWithPagination_URL,
	Get_AllRecoveryProceduresWithPagination_URL,
	Post_ConfirmIndexationProposals_URL,
	Post_ConfirmRecoveryProcedures_URL,
	Post_DeleteRecoveryProcedure_URL,
	Post_StartRecoveryProcedure_URL,
	Post_UpdateRecoveryProcedure_URL,
	Get_AllDuesWithPagination_URL,
} from "../../../constants/apiUrls";
import { useSnackbar } from "notistack";
import { format } from "../../../heplers/format";
import { TextField, Tooltip, Typography } from "@material-ui/core";
import AlertDialog from "../../../components/DeleteDialog";
import { Field, Formik } from "formik";
import { EditButton } from "../../../components/ButttonsWithIcons";
import RecoveryProcedureStepper from "../../tanents/components/recoveryprocedure/components/recoveryProcedureStepper";
import DialogComponent from "../../../components/Dialog";
const DashBoardDataTable = ({
	dataType,
	indexationList,
	indexationListCount,
	setIndexationListCount,
	setIndexationList,
	setRecoveryList,
	recoveryList,
	dueList,
	setDueList,
}) => {
	const dispatch = useDispatch();
	const { enqueueSnackbar } = useSnackbar();
	const [searchString, setSearchString] = useState("");
	const [isOpenDialog, setIsOpenDialog] = useState(false);
	const [isLoading, setIsLoading] = useState(true);
	const [selectRow, setSelectRow] = useState([]);
	const [refResh, setRefResh] = useState(false);

	const [page, setPage] = useState(0);
	let pageSize = localStorage.getItem("sizeIndexation");
	if (pageSize == null) {
		localStorage.setItem("sizeIndexation", 10);
		pageSize = 10;
	}
	const [rowsPerPage, setRowsPerPage] = useState(pageSize);

	const handleLossChange = (f, g, index, newPropsal, oldRent, percentage) => {
		let Percent = percentage / 100;
		let a = newPropsal - oldRent;
		let b = a * Percent;
		let c = b + oldRent;

		const updatedTableData = indexationList.map((item, i) => {
			if (item?.contractId === index) {
				let Percent = percentage / 100;
				let a = newPropsal - oldRent;
				let b = a * Percent;
				var c = b + oldRent;
				//c = c.toFixed(2);
				return {
					...item,
					percentage: percentage,
					newRent: format("number", c),
				};
			}
			return item;
		});
		setIndexationList(updatedTableData);
	};

	const columns = [
		{
			id: "startDate",
			disablePadding: true,
			label: getTranslation("Contract Date", "Contract Date", "Contract Date"),
			component: ({ row }) => {
				return (
					<Typography style={{ color: "red" }}>
						{format("date", row?.startDate)}
					</Typography>
				);
			},
		},
		{
			id: "oldIndex",
			numeric: false,
			disablePadding: true,
			label: getTranslation("Old Index", "Old Index", "Old Index"),
		},
		{
			id: "oldRent",
			numeric: false,
			disablePadding: true,
			label: getTranslation("Rent", "Rent", "Rent"),
			component: (row) => {
				return (
					<Typography variant="h5">
						{format("number", row?.row?.oldRent)}
					</Typography>
				);
			},
		},
		{
			id: "newIndex",
			numeric: false,
			disablePadding: true,
			label: getTranslation("New Index", "New Index", "New Index"),
		},
		{
			id: "newProposal",
			numeric: false,
			disablePadding: true,
			label: getTranslation("New Proposal", "New Proposal", "New Proposal"),
			component: (row) => {
				return (
					<Typography variant="h5">
						{format("number", row?.row?.newProposal)}
					</Typography>
				);
			},
		},
		{
			id: "percentage",
			numeric: false,
			disablePadding: true,
			label: getTranslation(
				"Percentage (%)",
				"Percentage (%)",
				"Percentage (%)"
			),
			component: (row) => {
				const handlePercentageChange = (e) => {
					const newPercentage = e.target.value;
					const regex = /^(100|[1-9]?[0-9])$/;

					if (newPercentage < 0) {
						enqueueSnackbar("Percentage can't be negative", {
							variant: "error",
						});
						return;
					} else if (newPercentage > 100) {
						enqueueSnackbar("Percentage can't be greater than 100", {
							variant: "error",
						});
						return;
					}

					//   const newPercentage = e.target.value;
					//   const regex = /^(100|[1-9]?[0-9])$/;

					//   if (!regex.test(newPercentage)) {
					//     enqueueSnackbar("Values are not Proper", { variant: "error" });
					//     return;
					//   }
					handleLossChange(
						row,
						row.row,
						row?.row?.contractId,
						row?.row?.newProposal,
						row?.row?.oldRent,
						newPercentage
					);
				};

				return (
					<Formik
						initialValues={{
							percentage: row?.row?.percentage,
						}}
					>
						{({ getFieldProps, values }) => (
							<TextField
								id="percentage"
								type="number"
								variant="outlined"
								style={{ width: "100%" }}
								hiddenLabel
								defaultValue="Small"
								size="small"
								{...getFieldProps("percentage")}
								inputProps={{ min: 0, max: 100 }}
								onChange={handlePercentageChange}
								// onBlur={(e) => {
								//   handleLossChange(
								//     row,
								//     row.row,
								//     row?.row?.contractId,
								//     row?.row?.newProposal,
								//     row?.row?.oldRent,
								//     values.percentage
								//   );
								// }}
								InputProps={{
									style: {
										"& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
											{
												WebkitAppearance: "none",
												margin: 0,
											},
										"& input[type=number]": {
											MozAppearance: "textfield",
										},
										color: "rbg(242,131,62)",
									},
								}}
							/>
						)}
					</Formik>
				);
			},
		},
		{
			id: "newRent",
			numeric: false,
			disablePadding: true,
			label: getTranslation("New Rent", "New Rent", "New Rent"),
			component: (row) => {
				return (
					<Typography variant="h5">
						{format("number", row?.row?.newRent)}
					</Typography>
				);
			},
		},
	];

	const recoveryColumns = [
		{
			id: "tenant",
			numeric: false,
			disablePadding: true,
			label: getTranslation("Tenant Name", "Tenant Name", "Tenant Name"),
			component: ({ row }) => {
				return <Typography>{row?.tenant?.name}</Typography>;
			},
		},
		{
			id: "apartmentAddress",
			label: getTranslation(
				"Appartment Address",
				"Appartment Address",
				"Appartment Address"
			),
			align: "left",
			component: ({ row }) => {
				return <Typography>{row?.appartmentAddress}</Typography>;
			},
		},
		{
			id: "recoverystatus",
			label: getTranslation("Status", "Status", "Status"),
			align: "left",
			component: ({ row }) => {
				return (
					<Typography>
						{row?.recoveryProcedureStatus == 0
							? "Active"
							: row?.recoveryProcedureStatus == 1
							? "Closed"
							: "Draft"}
					</Typography>
				);
			},
		},
		{
			id: "recoveryPersons",
			disablePadding: true,
			label: getTranslation(
				"Recovery Person",
				"Recovery Person",
				"Recovery Person"
			),
			component: ({ row }) => {
				return (
					<>
						<Typography>
							{row?.recoveryPersons[0]?.name} | {row?.recoveryPersons[0]?.email}
						</Typography>
						<Typography>
							{row?.recoveryPersons[1]
								? row?.recoveryPersons[1]?.name + " | "
								: ""}{" "}
							{row?.recoveryPersons[1] ? row?.recoveryPersons[1]?.email : ""}
						</Typography>
						<Typography>
							{row?.recoveryPersons[2]
								? row?.recoveryPersons[2]?.name + " | "
								: ""}{" "}
							{row?.recoveryPersons[2] ? row?.recoveryPersons[2]?.email : ""}
						</Typography>
					</>
				);
			},
		},
		{
			id: "actions",
			label: getTranslation("Action", "Action", "Action"),
			align: "right",
		},
	];

	const duesColumns = [
		{
			id: "date",
			disablePadding: true,
			label: getTranslation("Date", "Date", "Date"),
			format: "date",
		},
		{
			id: "name",
			numeric: true,
			disablePadding: true,
			label: getTranslation("Name", "Name", "Name"),
		},
		{
			id: "categoryText",
			numeric: true,
			label: getTranslation("Activity", "Activity", "Activity"),
			component: ({ row }) => (
				<Typography>
					{row?.categoryText
						? row.categoryText
								.replace(
									"Rent- Due",
									getTranslation("Rent-Due", "Rent-Due", "Rent-Due")
								)
								.replace(
									"Warranty- Payment",
									getTranslation(
										"Warranty-Payment",
										"Warranty-Payment",
										"Warranty-Payment"
									)
								)
								.replace(
									"Rent- Payment",
									getTranslation("Rent-Payment", "Rent-Payment", "Rent-Payment")
								)
								.replace(
									"Repair & Maintenance- Payment",
									getTranslation(
										"Repair & Maintenance-Payment",
										"Repair & Maintenance-Payment",
										"Repair & Maintenance-Payment"
									)
								)
								.replace(
									"Repair & Maintenance- Due",
									getTranslation(
										"Repair & Maintenance-Due",
										"Repair & Maintenance-Due",
										"Repair & Maintenance-Due"
									)
								)
								.replace(
									"Cost- Due",
									getTranslation("Cost-Due", "Cost-Due", "Cost-Due")
								)
						: "-"}
				</Typography>
			),
		},
		{
			id: "buildingName",
			numeric: true,
			disablePadding: true,
			label: getTranslation("Building", "Building", "Building"),
		},
		{
			id: "appartmentNo",
			numeric: true,
			disablePadding: true,
			label: getTranslation("Apartments", "Apartments", "Apartments"),
		},
		{
			id: "paymentAmount",
			format: "number",
			align: "right",
			label: getTranslation("Payment", "Payment", "Payment"),
			component: (row) => {
				return (
					<Typography>{format("number", row?.row?.paymentAmount)}</Typography>
				);
			},
		},
		{
			id: "dueAmount",
			format: "number",
			align: "right",
			label: getTranslation("Due", "Due", "Due"),
			component: (row) => {
				return <Typography>{format("number", row?.row?.dueAmount)}</Typography>;
			},
		},
		{
			id: "comments",
			label: getTranslation("Comments", "Comments", "Comments"),
			component: ({ row }) => {
				return (
					<>
						{" "}
						{row.comments?.split(/\r?\n/).map((item) => {
							return <Typography>{item}</Typography>;
						})}
					</>
				);
			},
		},
		{
			id: "actions",
			numeric: true,
			disablePadding: true,
			label: getTranslation("Actions", "Actions", "Actions"),
			align: "right",
		},
	];

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};
	const handleChangeRowsPerPage = (event) => {
		localStorage.setItem("sizeIndexation", parseInt(event.target.value));
		setRowsPerPage(localStorage.getItem("sizeIndexation"));
		setPage(0);
	};
	const handleSearchappartment = (searchString) => {
		setSearchString(searchString);
	};

	useEffect(() => {
		dispatch({ type: "Clear_All_BreadCrumb" });
		dispatch({
			type: "Add_BreadCrumb",
			payload: {
				title: "Indexation",
				url: "/dues/indexation",
			},
		});
	}, []);

	useEffect(() => {
		getIndexations();
		getRecoveryProcedure();
		loadDues();
		setIsLoading(true);
		setSelectRow([]);
		setRefResh(false);
	}, [searchString, dataType, refResh, page, rowsPerPage]);

	//get all the contract that need to be indexed
	const getIndexations = () => {
		Get(
			{
				pageNumber: page,
				pageSize: rowsPerPage,
				searchString: searchString,
			},
			Get_AllIndexationProposalsWithPagination_URL,
			null,
			(resp) => {
				setIndexationList(resp?.data.data);
				setIndexationListCount(resp.data);
				setIsLoading(false);
			},
			(err) => {
				enqueueSnackbar(err?.data, { variant: "error" });
				setIsLoading(false);
				console.log(err);
			}
		);
	};

	//getting all recovery procedure
	const getRecoveryProcedure = () => {
		Get(
			{
				sort: "startDate",
				sortDirection: 1,
				pageNumber: page,
				pageSize: rowsPerPage,
				status: "draft",
				searchString: searchString,
			},
			Get_AllRecoveryProceduresWithPagination_URL,
			null,
			(resp) => {
				setRecoveryList(resp?.data);
				setIsLoading(false);
			},
			(err) => {
				enqueueSnackbar(
					err?.data
						? err?.data
						: getTranslation(
								"Something went wrong. Please try again.",
								"Something went wrong. Please try again.",
								"Something went wrong. Please try again."
						  ),
					{
						variant: "error",
					}
				);
				setIsLoading(false);
				console.log(err);
			}
		);
	};

	//geting all dues
	const loadDues = (values) => {
		Get(
			{
				pageNumber: page,
				pageSize: rowsPerPage,
				searchString: searchString,
			},
			Get_AllDuesWithPagination_URL,
			null,
			(resp) => {
				// setDuesLoadign(false);
				setDueList(resp?.data);
				setIsLoading(false);
			},
			(error) => {
				enqueueSnackbar(error?.data ? error?.data : "Internal server Error", {
					variant: "error",
				});
				setIsLoading(false);
			}
		);
	};

	//post indexation confirm list
	const postIndexation = () => {
		Post(
			{
				confirmedProposals: selectRow,
			},
			Post_ConfirmIndexationProposals_URL,
			null,
			(resp) => {
				enqueueSnackbar("Indexation is successfully confirmed", {
					variant: "success",
				});
				setRefResh(true);
			},
			(err) => {
				enqueueSnackbar(err?.data, { variant: "error" });
			}
		);
	};

	const confirmRecoveryProcedures = (payload) => {
		if (payload.length == 0) {
			enqueueSnackbar("No tenant selected to start Recovery Procedure", {
				variant: "error",
			});
			return;
		}

		var selectedRecoveryProcedures = payload.map((x) => {
			return x.id;
		});
		setIsLoading(true);
		Post(
			selectedRecoveryProcedures,
			Post_ConfirmRecoveryProcedures_URL,
			null,
			(resp) => {
				enqueueSnackbar("Recovery Procedures Started Successfully", {
					variant: "success",
				});
				setRefResh(true);
				setIsLoading(false);
			},
			(err) => {
				enqueueSnackbar(
					err.data
						? err.data
						: getTranslation(
								"Something went wrong. Please try again.",
								"Something went wrong. Please try again.",
								"Something went wrong. Please try again."
						  ),
					{
						variant: "error",
					}
				);
				setIsLoading(false);
			}
		);
	};

	//open confirm dialog
	const confirmDialog = () => {
		setIsOpenDialog(true);
	};

	const confirmDues = (payload) => {
		alert("check");
	};
	//call the postindexation api
	const handleConfirmIndexation = () => {
		// if (dataType == 0) {
		//   postIndexation();
		// } else {
		//   confirmRecoveryProcedures(selectRow);
		// }
		if (dataType === "0") {
			postIndexation();
		} else if (dataType === "1") {
			confirmRecoveryProcedures(selectRow);
		} else if (dataType === "2") {
			confirmDues(selectRow);
		}

		setIsOpenDialog(false);
	};
	const handleSelectAll = (row) => {
		if (row.length == selectRow.length) {
			setSelectRow([]);
		} else setSelectRow(row);
	};
	const handleSelectionUnChecked = (row) => {
		let updateArray = selectRow.filter((x) => x.id != row);
		setSelectRow(updateArray);
	};
	const handleSelectionChecked = (row) => {
		if (selectRow) setSelectRow([...selectRow, row]);
		else setSelectRow([row]);
	};
	const [editRecoveryData, setEditRecoveryData] = useState();
	const [startRecoveryDialog, setStartRecoveryDialog] = useState(false);
	const [isEdit, setIsEdit] = useState(false);
	const [deletedRow, setDeletedRow] = useState();
	const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

	const onEditRecovery = (recoveryData) => {
		setEditRecoveryData(recoveryData);
		setIsEdit(true);
		setStartRecoveryDialog(true);
	};

	const handleOpenDeleteDialog = (row) => {
		setDeletedRow(row);
		setOpenDeleteDialog(true);
	};

	const handleDeleteRecovery = () => {
		setIsLoading(true);
		Post(
			{
				id: deletedRow.id,
			},
			Post_DeleteRecoveryProcedure_URL,
			null,
			(resp) => {
				enqueueSnackbar("Recovery Procedure Deleted Successfully", {
					variant: "success",
				});
				setRefResh(true);
				setIsLoading(false);
				setOpenDeleteDialog(false);
			},
			(err) => {
				enqueueSnackbar(
					err.data
						? err.data
						: getTranslation(
								"Something went wrong. Please try again.",
								"Something went wrong. Please try again.",
								"Something went wrong. Please try again."
						  ),
					{
						variant: "error",
					}
				);
				setIsLoading(false);
			}
		);
	};

	const postRecoveryProcedure = (payload) => {
		setIsLoading(true);
		Post(
			payload,
			Post_StartRecoveryProcedure_URL,
			null,
			(resp) => {
				setRefResh(true);
				enqueueSnackbar(
					resp.data ? resp.data : "Recovery Procedure Added successfully",
					{
						variant: "success",
					}
				);
				setIsLoading(false);
				setStartRecoveryDialog(false);
			},
			(err) => {
				enqueueSnackbar(
					err?.data
						? getTranslation(err?.data, err?.data, err?.data)
						: getTranslation(
								"Something went wrong. Please try again.",
								"Something went wrong. Please try again.",
								"Something went wrong. Please try again."
						  ),
					{
						variant: "error",
					}
				);
				setIsLoading(false);
			}
		);
	};
	const upDateRecoveryProcedure = (payload) => {
		setIsLoading(true);

		Post(
			payload,
			Post_UpdateRecoveryProcedure_URL,
			null,
			(resp) => {
				enqueueSnackbar("Update Recovery Procedure Successfully", {
					variant: "success",
				});
				setRefResh(true);
				setStartRecoveryDialog(false);
				setIsLoading(false);
			},
			(err) => {
				enqueueSnackbar(
					err.data
						? err.data
						: getTranslation(
								"Something went wrong. Please try again.",
								"Something went wrong. Please try again.",
								"Something went wrong. Please try again."
						  ),
					{
						variant: "error",
					}
				);
				setIsLoading(false);
			}
		);
	};

	return (
		<div>
			{/* <Helmet>
				<title>
					{getTranslation("Indexation", "Indexation", "Indexation")}
				</title>
			</Helmet> */}
			<TableComponent
				dense
				loading={isLoading}
				//rows={dataType == 1 ? recoveryList?.data : indexationList?.data}
				rows={
					dataType === "0"
						? indexationList
						: dataType === "1"
						? recoveryList?.data
						: dataType === "2"
						? dueList?.data
						: []
				}
				// colums={dataType == 1 ? recoveryColumns : columns}
				colums={
					dataType === "0"
						? columns
						: dataType === "1"
						? recoveryColumns
						: dataType === "2"
						? duesColumns
						: []
				}
				// count={
				//   dataType == 1 ? recoveryList?.totalCount : indexationList?.totalCount
				// }
				count={
					dataType === "0"
						? indexationListCount?.totalCount
						: dataType === "1"
						? recoveryList?.totalCount
						: dataType === "2"
						? dueList?.totalCount
						: []
				}
				viewSearch
				viewConfirmAll={
					dataType == 0 && indexationList?.length == 0
						? "false"
						: dataType == 1 && recoveryList?.length == 0
						? "false"
						: "true"
				}
				onConfirmAll={confirmDialog}
				page={page}
				rowsPerPage={rowsPerPage}
				handleChangePage={handleChangePage}
				handleChangeRowsPerPage={handleChangeRowsPerPage}
				onSearch={(searchString) => handleSearchappartment(searchString)}
				enableCheckbox={true} // Enable checkboxes
				onSelectionChecked={(row) => handleSelectionChecked(row.id)} // Callback for selection
				onSelectionUnChecked={(row) => handleSelectionUnChecked(row.id)} // Callback for deselection
				onSelectionChange={(row) => {
					handleSelectAll(row);
				}}
				selected={selectRow}
				viewDelete
				onDelete={(row) => handleOpenDeleteDialog(row)}
				actions={
					dataType == 1
						? [
								{
									component: (rowData) => (
										<Tooltip
											title={getTranslation(
												"Edit Recovery",
												"Edit Recovery",
												"Edit Recovery"
											)}
										>
											<EditButton
												onClick={() => {
													onEditRecovery(rowData);
												}}
											/>
										</Tooltip>
									),
								},
						  ]
						: ""
				}
			/>

			<AlertDialog
				open={isOpenDialog}
				onClose={() => setIsOpenDialog(false)}
				onSubmit={handleConfirmIndexation}
				title={getTranslation("Confirm", "Confirm", "Confirm")}
				description={
					dataType == 1
						? getTranslation(
								"Are you sure you want to start recovery procedure for selected one?",
								"Are you sure you want to start recovery procedure for selected one?",
								"Are you sure you want to start recovery procedure for selected one?"
						  )
						: dataType == 0
						? getTranslation(
								"Are you sure you want to apply indexation on selected one?",
								"Are you sure you want to apply indexation on selected one?",
								"Are you sure you want to apply indexation on selected one?"
						  )
						: dataType == 2
						? getTranslation(
								"Are you sure you want to confirm selected dues",
								"Are you sure you want to confirm selected dues",
								"Are you sure you want to confirm selected dues"
						  )
						: ""
				}
				buttonTitle={getTranslation("Confirm", "Confirm", "Confirm")}
			/>

			<DialogComponent
				fullWidth
				maxWidth="lg"
				title={getTranslation(
					"Start Recovery Procedure",
					"Start Recovery Procedure",
					"Start Recovery Procedure"
				)}
				open={startRecoveryDialog}
				onClose={() => {
					setStartRecoveryDialog(false);
				}}
			>
				<RecoveryProcedureStepper
					// tenantList={tenantList}
					// tenantDetailLis={tenantDetailList}
					// tenantData={recoveryInfo}
					isEdit={isEdit}
					editRecoveryData={editRecoveryData}
					onClose={() => setStartRecoveryDialog(false)}
					handleSubmit={postRecoveryProcedure}
					onUpdate={upDateRecoveryProcedure}
					isLoading={isLoading}
				/>
			</DialogComponent>

			<AlertDialog
				open={openDeleteDialog}
				deleting={isLoading}
				onClose={() => setOpenDeleteDialog(false)}
				onSubmit={handleDeleteRecovery}
			/>
		</div>
	);
};

export default DashBoardDataTable;
