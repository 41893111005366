import React, { useState, useEffect } from "react";
import { Link as RouterLink, useLocation } from "react-router-dom";
import List from "@material-ui/core/List";
import ListItemText from "@material-ui/core/ListItemText";
import ListItem from "@material-ui/core/ListItem";
import { makeStyles, Typography } from "@material-ui/core";
import {
  BankIcon,
  TenantBankIcon,
  EmailIcon,
  GenerateDueIcon,
  PreviousDueIcon,
  ContractIcon,
  NotesIcon,
  SummaryIcon,
  PersonListIcon,
  IndexationIcon,
  TenantRecoveryProcedure,
  TenantDetailsIcon,
} from "../../components/icons";
import { getTranslation } from "../../heplers/translationHelper";
const useStyles = makeStyles((theme) => ({
  menuItem: {
    borderBottom: "2px " + theme.palette.common.white + " solid",
    "&:hover": {
      borderBottom: "2px " + "#F65E39" + " solid",
    },
  },
  selected: {
    backgroundColor: "#F65E39",
    borderBottom: "2px #F65E39 solid",
  },
}));
function SubMenus({ isMobile }) {
  const [hideTopMenu, setHideTopMenu] = useState(true);
  const [tId, setTid] = useState(13);

  const [submenu, setSubmenu] = useState();
  const [selectedMenu, setSelectedMenu] = useState("");
  let location = useLocation();

  useEffect(() => {
    const pathSegments = location.pathname.split("/");
    let id;

    const detailsIndex = pathSegments.indexOf("details");

    if (location.pathname.startsWith("/dues")) {
      setSubmenu(duesSubmenu);
      setHideTopMenu(false);
    } else if (location.pathname.startsWith("/setting")) {
      setSubmenu(settingsSubmenu);
      setHideTopMenu(false);
    } else if (location.pathname.startsWith("/tanents/details")) {
      if (detailsIndex !== -1) {
        id = pathSegments[detailsIndex + 1];
      }
      setSubmenu(tenantSubmenu1(id));
      setHideTopMenu(false);
    } else if (location.pathname.startsWith("/tanents")) {
      setSubmenu(tenantSubmenu);
      setHideTopMenu(false);
    } else if (location.pathname.startsWith("/tanents/details/gy")) {
      setSubmenu(tenantSubmenuDetail);
      setHideTopMenu(false);
    } else {
      setHideTopMenu(true);
    }
  }, [location]);

  return (
    <>
      {hideTopMenu === false ? (
        <List style={flexContainer}>
          {submenu.map((item) => (
            <ListItemLink
              color="inherit"
              to={item.link}
              primary={item.title}
              component={item.component}
              selected={location.pathname.endsWith(item.link)}
              key={item.key}
            />
          ))}
        </List>
      ) : (
        <Typography color="secondary" variant="h5">
          {(isMobile == false && window.location.pathname === "/dashboard") ||
          window.location.pathname === "/"
            ? getTranslation("Welcome", "Welcome", "Welcome")
            : null}
        </Typography>
      )}
    </>
  );
}

const flexContainer = {
  display: "flex",
  flexDirection: "row",
  padding: 0,
};

function ListItemLink(props) {
  const { primary, to, selected, component } = props;
  const classes = useStyles();

  const renderLink = React.useMemo(
    () =>
      React.forwardRef((itemProps, ref) => (
        <RouterLink to={to} ref={ref} {...itemProps} />
      )),
    [to]
  );
  const Component = component;
  return (
    <li>
      <ListItem
        button
        component={renderLink}
        selected={selected}
        className={`${selected ? classes.selected : classes.menuItem}`}
        style={{
          backgroundColor: selected ? "rgb(230,231,245)" : "rgb(255,255,255)",
        }}
      >
        {component ? <Component /> : <ListItemText primary={primary} />}
      </ListItem>
    </li>
  );
}

//  Company Sub menus
const duesSubmenu = [
  { key: "generated", component: GenerateDueIcon, link: "/dues/duesGenerated" },
  { key: "previous", component: PreviousDueIcon, link: "/dues/previousDues" },
  { key: "indexation", component: IndexationIcon, link: "/dues/indexation" },
];

//  Settings Sub menus
const settingsSubmenu = [
  { key: "emailSetting", component: EmailIcon, link: "/settings/email" },
  { key: "bankSetting", component: BankIcon, link: "/settings/bank" },
];

//  Tenant Sub menus
const tenantSubmenu = [
  { key: "tenant", component: PersonListIcon, link: "/tanents" },
  {
    key: "recoveryProcedure",
    component: TenantRecoveryProcedure,
    link: "/tanents/recoveryprocedure",
  },
];

const tenantSubmenu1 = (id) => [
  {
    key: "tenant",
    component: TenantDetailsIcon,
    link: `/tanents/details/${id}/details`,
  },
  {
    key: "contracts",
    component: ContractIcon,
    link: `/tanents/details/${id}/contracts`,
  },
  {
    key: "banks",
    component: TenantBankIcon,
    link: `/tanents/details/${id}/banks`,
  },
  { key: "dues", component: SummaryIcon, link: `/tanents/details/${id}/dues` },
  { key: "notes", component: NotesIcon, link: `/tanents/details/${id}/notes` },
];

const tenantSubmenuDetail = (query) => [
  {
    key: "tenant",
    component: TenantDetailsIcon,
    link: "/tanents/details" + query,
  },
  {
    key: "tenant",
    component: TenantDetailsIcon,
    link: "/tanents/12/details" + query,
  },
  {
    key: "recoveryProcedure",
    component: TenantRecoveryProcedure,
    link: "/tanents/recoveryprocedure",
  },
];
export default SubMenus;
