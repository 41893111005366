import {
	Box,
	Drawer,
	List,
	ListSubheader,
	Divider,
	Typography,
	Dialog,
	Select,
	MenuItem,
	CircularProgress,
} from "@material-ui/core";
import React, { useEffect } from "react";
import { matchPath } from "react-router";
import { Link as RouterLink, useLocation } from "react-router-dom";
import NavItem from "./NavItem";
import navConfig from "./Menu";
import { Logo1 } from "../../components/Logo";
import useStyles from "../../assests/styles/layout/sidebar";
import { useTheme } from "@material-ui/core/styles";
import moment from "moment/moment";
import DialogComponent from "../../components/Dialog";
import authService from "../../utils/authUtils";
import { WarningOutlined } from "@material-ui/icons";
import { useState } from "react";
import { getTranslation } from "../../heplers/translationHelper";
import SidebarImage from "../../assests/images/sidebarImage.svg";
import ButtonWithLoading from "../../components/ButtonWithLoading";
import authUtils from "../../utils/authUtils";
function renderNavItems({ items, depth, pathname, ...rest }) {
	return (
		<List disablePadding>
			{items.reduce(
				(acc, item) => reduceChildRoutes({ acc, pathname, item, ...rest }),
				[]
			)}
		</List>
	);
}

function reduceChildRoutes({ acc, pathname, item, depth = 0 }) {
	const key = item.title + depth;

	if (item.items) {
		const open = matchPath(pathname, {
			path: item.href,
			exact: false,
		});

		acc.push(
			<NavItem
				depth={depth}
				icon={item.icon}
				key={key}
				info={item.info}
				open={Boolean(open)}
				title={item.title}
				component={item.component}
			>
				<Box pl={5}>
					{renderNavItems({
						depth: depth + 3,
						pathname,
						items: item.items,
					})}
				</Box>
			</NavItem>
		);
	} else {
		acc.push(
			<NavItem
				open={true}
				depth={depth}
				href={item.href}
				icon={item.icon}
				key={key}
				info={item.info}
				title={item.title}
				component={item.component}
			/>
		);
	}
	return acc;
}

function Sidebar({ openMobile, onMobileClose }) {
	const classes = useStyles();
	const location = useLocation();
	const theme = useTheme();
	const [openAlert, setOpenAlert] = useState(false);
	const [expiredAlert, setExpiredAlert] = useState(false);
	const [traiAlert, setTrailAlert] = useState(false);
	const [openModal, setOpenModal] = useState(false);
	const [modalContent, setModalContent] = useState("");
	const handleDrawerClose = () => {
		onMobileClose();
	};

	const selectedOrg = authService
		.getCustomerOrganizations()
		?.find((x) => x.customerId == authService.getCustomerId());

	useEffect(() => {
		// if (selectedOrg?.packageStatus == 3)
		//   setOpenAlert(moment(selectedOrg?.trialEndDate).isBefore(moment()));
		const currentDate = new Date();

		if (selectedOrg?.packageStatus === 2) {
			setExpiredAlert(true);
			setOpenAlert(true);
		}
		if (
			selectedOrg?.packageStatus === 3 &&
			new Date(selectedOrg?.trialEndDate) < currentDate
		) {
			setExpiredAlert(true);
			setOpenAlert(true);
		}
		if (
			selectedOrg?.packageStatus === 3 &&
			new Date(selectedOrg?.trialEndDate) > currentDate
		)
			setTrailAlert(true);
		setOpenAlert(moment(selectedOrg?.trialEndDate).isBefore(moment()));
	}, []);

	const getStatusText = (packageStatus, trialEndDate) => {
		if (packageStatus === 1) {
			return "Active";
		} else if (packageStatus === 2) {
			return "Inactive";
		} else if (packageStatus === 3) {
			const currentDate = new Date();
			const endDate = new Date(trialEndDate);

			if (endDate < currentDate) {
				return "Trial Expired";
			} else {
				return "Trial";
			}
		} else {
			return "";
		}
	};

	const getStatusColor = (packageStatus, trialEndDate) => {
		if (packageStatus === 1) {
			return "green";
		} else if (
			packageStatus === 2 ||
			(packageStatus === 3 && new Date(trialEndDate) < new Date())
		) {
			return "red";
		} else {
			return "inherit";
		}
	};

	// end traildate manageing
	const currentDate = new Date();

	const trialEndDate = new Date(selectedOrg?.trialEndDate);

	// Calculatingthe difference in milliseconds
	const timeDifference = trialEndDate - currentDate;

	// Calculating the number of days left
	const daysDifference = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));

	const daysLeft =
		daysDifference >= 0 ? `${daysDifference} Day left` : "Trial ended";

	const handleClick = () => {
		const objToSend = {
			token: authService.getUserToken(),
			refreshToken: authService.getUserRefreshToken(),
			refreshTokenExpiry: authService.getUserRefreshTokenExpiry(),
			isRefreshing: authService.getIsRefreshing(),
			customerId: authService.getCustomerId(),
			redirectURL: authService.removeRedirectURL(),
			user: authService.getUser(),
			redirectUrlBack: `${process.env.REACT_APP_CLIC_RENT_DOMAIN}upgrade`,
			target: "trial end",
		};

		const stringifiedObj = JSON.stringify(objToSend);

		const base64Obj = btoa(stringifiedObj); // Convert to Base64

		const url = `${
			process.env.REACT_APP_APP_MANAGER_DOMAIN
		}identity?data=${encodeURIComponent(base64Obj)}`;
		window.location.href = url;
	};

	const content = (
		<Box height="100%" display="flex" flexDirection="column">
			<Box display="flex" justifyContent="center" py={1}>
				<RouterLink to="/">
					<div
						style={{
							marginTop: "10px",
							marginBottom: "10px",
							display: "flex",
							justifyContent: "center",
						}}
					>
						<Logo1 />
					</div>
				</RouterLink>
			</Box>
			<Box px={0.5}>
				{navConfig.map((config) => (
					<List
						key={config.subheader}
						subheader={
							<ListSubheader disableGutters disableSticky>
								{config.subheader}
							</ListSubheader>
						}
					>
						{renderNavItems({
							items: config.items,
							pathname: location.pathname,
						})}
					</List>
				))}
			</Box>
			{/* <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginTop: "2%",
        }}
      >
        <img
          src={SidebarImage}
          style={{
            width: "90%",
            height: "100%",
            marginLeft: "5%",
          }}
        />
      </div> */}
		</Box>
	);
	return (
		<>
			<Drawer
				className={classes.drawer}
				variant="persistent"
				anchor="left"
				open={openMobile}
				classes={{
					paper: classes.drawerPaper,
				}}
				style={openMobile ? { backgroundColor: "rgb(230, 231, 245)" } : {}}
			>
				<div>{content}</div>

				{selectedOrg && selectedOrg?.packageStatus == 3 ? (
					<div
						style={{
							backgroundColor: "rgb(230, 231, 245)",
							//  backgroundColor: "white",

							color: "white",
							textAlign: "center",
							margin: "5px 35px 0px 35px",
							padding: "10px 0px 10px 0px",
							// boxShadow: "2px 2px 4px rgba(0, 0, 0, 0.1)",
							// borderRadius: "20px",
						}}
					>
						<Typography style={{ color: "grey" }}>
							{" "}
							{getTranslation("Trial Period", "Trial Period", "Trial Period")}:
						</Typography>
						<Typography style={{ marginBottom: "20px", color: "grey" }}>
							{daysLeft}
						</Typography>

						<ButtonWithLoading
							onClick={handleClick}
							variant="contained"
							style={{
								backgroundColor: "rgb(229,60,22)",
								color: "white",
								fontWeight: "bold",
							}}
							title={getTranslation("End Trial", "End Trial", "End Trial")}
							size="medium"
						/>
						<Typography style={{ fontSize: "10px", color: "grey" }}>
							&copy; Qollex {moment().format("YYYY")}
						</Typography>
						{/* <Typography
              variant='h3'
              style={{ fontSize: "10px", position: "absolute", bottom: 0 }}
            >
              Version 5.0
            </Typography> */}
					</div>
				) : null}
				<div
					className={classes.drawerHeader}
					style={{
						backgroundColor: "rgb(230, 231, 245)",
						display: "flex",
						justifyContent: "center",
						marginTop: "50px",
					}}
				>
					{/* <p style={{ textAlign: "center" }}>Version 3.0</p> */}
					<Typography
						variant="body1"
						style={{ fontWeight: "bold", position: "absolute", bottom: 0 }}
					>
						Version 5.0
					</Typography>

					{/* <IconButton onClick={handleDrawerClose}>
            {theme.direction === "ltr" ? (
              <ChevronLeftIcon />
            ) : (
              <ChevronRightIcon />
            )}
          </IconButton> */}
				</div>
				<style>
					{`
      .MuiDrawer-paper::-webkit-scrollbar {
        width: 8px;
      }

      .MuiDrawer-paper::-webkit-scrollbar-thumb {
        background-color: rgb(19,68,102);
        border-radius: 4px;
      }

      .MuiDrawer-paper::-webkit-scrollbar-track {
        background-color: rgb(230,231,245);
        margin-top: 170px; 
      }
    `}
				</style>
			</Drawer>
			{traiAlert ? (
				<DialogComponent
					open={openAlert}
					maxWidth={"sm"}
					fullWidth
					title={getTranslation("Trial Alert", "Trial Alert", "Trial Alert")}
					onClose={() => setOpenAlert(false)}
				>
					<div style={{ textAlign: "center" }}>
						<WarningOutlined />
					</div>
					<Typography>
						{getTranslation(
							"Your trial period for organization is expired. Please upgrade the app before",
							"Your trial period for organization is expired. Please upgrade the app before",
							"Your trial period for organization is expired. Please upgrade the app before"
						)}{" "}
						{selectedOrg
							? moment(selectedOrg?.trialEndDate)
									.add("days", selectedOrg?.deadline)
									.format("DD-MM-YYYY")
							: 0}
					</Typography>
					<div style={{ display: "flex", justifyContent: "center" }}>
						<Select
							style={{
								marginRight: "20px",
								width: "160px",
								height: "34px",
								borderRadius: "17px",
							}}
							variant="outlined"
							labelId="demo-simple-select-label"
							id="demo-simple-select"
							label={getTranslation(
								"Select Organization",
								"S�lectionnez l'organisation",
								"W�hlen Sie Organisation aus"
							)}
							onChange={(e) => {
								authUtils.setCustomerId(e.target.value);
								const selectedOrg = authUtils
									.getCustomerOrganizations()
									.find((x) => x.customerId == e.target.value);

								const currentDate = new Date(); // Get the current date

								if (selectedOrg) {
									if (selectedOrg?.packageStatus === 2) {
										setOpenModal(true);
										console.log(
											"Package has expired. Show modal for packageStatus 2."
										);
										setModalContent(
											"Your Package has been expired kindly checkout."
										);
										return;
									} else if (
										selectedOrg?.packageStatus === 3 &&
										new Date(selectedOrg?.trialEndDate) < currentDate
									) {
										setOpenModal(true);
										setModalContent(
											"Your trial period for organization has expired. Kindly checout."
										);
										console.log(
											"Package has expired due to trial end date. Show modal for packageStatus 3."
										);
										return;
									} else {
										authUtils.setIsStarter(selectedOrg?.isStarter);
										window.location.reload();
									}
								}
							}}
							defaultValue={authUtils.getCustomerId()}
							disabled={authUtils.getCustomerOrganizations()?.length <= 1}
						>
							{authUtils.getCustomerOrganizations()?.map((app) => (
								<MenuItem value={app.customerId}>
									{app.organizationName}- {"   "}
									<span
										style={{
											fontSize: "12px",
											color: getStatusColor(
												app.packageStatus,
												app.trialEndDate
											),
										}}
									>
										({getStatusText(app.packageStatus, app.trialEndDate)})
									</span>
								</MenuItem>
							))}
							<MenuItem>
								<a
									style={{ marginRight: "50px" }}
									target="_blank"
									href="https://appmanager.impact-soft.com/myapps/1"
								>
									{getTranslation(
										"Wants to have more organizations?",
										"Wants to have more organizations?",
										"Wants to have more organizations?"
									)}
								</a>
							</MenuItem>
						</Select>
					</div>

					<div
						style={{
							display: "flex",
							justifyContent: "center",
							marginTop: "10px",
						}}
					>
						<ButtonWithLoading
							onClick={handleClick}
							variant="contained"
							style={{
								backgroundColor: "rgb(229,60,22)",
								color: "white",
								fontWeight: "bold",
							}}
							title={getTranslation("Checkout", "Checkout", "Checkout")}
							size="medium"
						/>
					</div>
				</DialogComponent>
			) : null}
			{expiredAlert ? (
				<DialogComponent
					open={openAlert}
					maxWidth={"sm"}
					fullWidth
					title={getTranslation(
						"Expired Alert",
						"Expired Alert",
						"Expired Alert"
					)}
					//  onClose={() => setOpenAlert(false)}
				>
					<div style={{ textAlign: "center" }}>
						<WarningOutlined />
					</div>
					<Typography align="center">
						{getTranslation(
							"Your package for organization is expired. Please Upgrade the app",
							"Your package for organization is expired. Please Upgrade the app",
							"Your package for organization is expired. Please Upgrade the app"
						)}{" "}
					</Typography>

					<div style={{ display: "flex", justifyContent: "center" }}>
						<Select
							style={{
								marginRight: "20px",
								width: "160px",
								height: "34px",
								borderRadius: "17px",
							}}
							variant="outlined"
							labelId="demo-simple-select-label"
							id="demo-simple-select"
							label={getTranslation(
								"Select Organization",
								"S�lectionnez l'organisation",
								"W�hlen Sie Organisation aus"
							)}
							onChange={(e) => {
								authUtils.setCustomerId(e.target.value);
								const selectedOrg = authUtils
									.getCustomerOrganizations()
									.find((x) => x.customerId == e.target.value);

								const currentDate = new Date(); // Get the current date

								if (selectedOrg) {
									if (selectedOrg?.packageStatus === 2) {
										setOpenModal(true);
										console.log(
											"Package has expired. Show modal for packageStatus 2."
										);
										setModalContent(
											"Your Package has been expired kindly checkout."
										);
										return;
									} else if (
										selectedOrg?.packageStatus === 3 &&
										new Date(selectedOrg?.trialEndDate) < currentDate
									) {
										setOpenModal(true);
										setModalContent(
											"Your trial period for organization has expired. Kindly checout."
										);
										console.log(
											"Package has expired due to trial end date. Show modal for packageStatus 3."
										);
										return;
									} else {
										authUtils.setIsStarter(selectedOrg?.isStarter);
										window.location.reload();
									}
								}
							}}
							defaultValue={authUtils.getCustomerId()}
							disabled={authUtils.getCustomerOrganizations()?.length <= 1}
						>
							{authUtils.getCustomerOrganizations()?.map((app) => (
								<MenuItem value={app.customerId}>
									{app.organizationName}- {"   "}
									<span
										style={{
											fontSize: "12px",
											color: getStatusColor(
												app.packageStatus,
												app.trialEndDate
											),
										}}
									>
										({getStatusText(app.packageStatus, app.trialEndDate)})
									</span>
								</MenuItem>
							))}
							<MenuItem>
								<a
									style={{ marginRight: "50px" }}
									target="_blank"
									href="https://appmanager.impact-soft.com/myapps/1"
								>
									{getTranslation(
										"Wants to have more organizations?",
										"Wants to have more organizations?",
										"Wants to have more organizations?"
									)}
								</a>
							</MenuItem>
						</Select>
					</div>

					<div
						style={{
							display: "flex",
							justifyContent: "center",
							marginTop: "10px",
						}}
					>
						<ButtonWithLoading
							onClick={handleClick}
							variant="contained"
							style={{
								backgroundColor: "rgb(229,60,22)",
								color: "white",
								fontWeight: "bold",
							}}
							title={getTranslation("Checkout", "Checkout", "Checkout")}
							size="medium"
						/>
					</div>
				</DialogComponent>
			) : (
				""
			)}
		</>
	);
}
export default Sidebar;
