import React, { useRef, useState } from "react";
import {
  Grid,
  TextField,
  Box,
  FormControl,
  FormControlLabel,
  Checkbox,
  Typography,
  InputLabel,
  Select,
  MenuItem,
  FormLabel,
  FormGroup,
  Button,
  Paper,
} from "@material-ui/core";
import DialogComponent from "../../../components/Dialog";
import { getTranslation } from "../../../heplers/translationHelper";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import ButtonWithLoading from "../../../components/ButtonWithLoading";
import { Post } from "../../../actions";
import {
  Post_SystemSetting_SendTestEmail,
  Post_SystemSetting_SendTestSMS,
} from "../../../constants/apiUrls";
import { useSnackbar } from "notistack";
import renderHTML from "react-render-html";
import { useReactToPrint } from "react-to-print/lib";

export default function TestSmsDialoge({
  template,
  title,
  editor,
  isOpen,
  onClose,
}) {
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  // const cleanEditorContent = (html) => {
  //   const doc = new DOMParser().parseFromString(html, "text/html");
  //   return doc.body.textContent || "";
  // };

  const initialValues = {
    email: "",
    phoneNumber: "",
  };

  const send = (value) => {
    let messageValue = editor;
    let apiUrl;
    let payload = {};

    if (messageValue === "") {
      messageValue = template;
    }

    const currentDate = new Date().toISOString().split("T")[0];

    // messageValue = messageValue.replace(/\[tenant_name\]/g, "Marcel");
    // messageValue = messageValue.replace(
    //   /\[tenant_email\]/g,
    //   "example@gmail.com"
    // );
    // messageValue = messageValue.replace(/\[due_Date\]/g, currentDate);
    // messageValue = messageValue.replace(
    //   /\[tenant_appartment\]/g,
    //   "Building 101"
    // );
    // messageValue = messageValue.replace(/\[tenant_reference]/g, "454399325");
    // messageValue = messageValue.replace(/\[town\]/g, "Building 101");
    // messageValue = messageValue.replace(/\[due_Amount\]/g, "10000");
    // messageValue = messageValue.replace(/\[current_date\]/g, currentDate);
    // messageValue = messageValue.replace(/\[zip_code\]/g, "12345");
    // messageValue = messageValue.replace(
    //   /\[appartment_address\]/g,
    //   "1234 Apartment St, Your City"
    // );
    // messageValue = messageValue.replace(/\[last_payment\]/g, currentDate);
    // messageValue = messageValue.replace(/\[total_rent\]/g, "787");
    // messageValue = messageValue.replace(/\[total_warranty\]/g, "1 year");
    // messageValue = messageValue.replace(
    //   /\[total_maintenance\]/g,
    //   "2 year cost"
    // );

    // const message = cleanEditorContent(messageValue);

    if (title === "Send Test Message") {
      apiUrl = Post_SystemSetting_SendTestSMS;
      payload = {
        phoneNumber: value.phoneNumber, // Set phoneNumber for SMS
        message: template,
      };
    } else {
      apiUrl = Post_SystemSetting_SendTestEmail;
      payload = {
        email: value.email, // Set email for email
        message: template,
      };
    }
    setLoading(true);
    Post(
      payload, // Use the payload with email or phoneNumber
      apiUrl,
      null,
      (resp) => {
        enqueueSnackbar(resp.data, {
          variant: "success",
        });
        onClose(false);
        setLoading(false);
      },
      (error) => {
        enqueueSnackbar(error.data, { variant: "error" });
        setLoading(false);
      }
    );
  };

  const emailValidationSchema = Yup.object().shape({
    email: Yup.string().email("Invalid Email").required("Email is required"),
  });
  const phoneValidationSchema = Yup.object().shape({
    phoneNumber: Yup.string().required("Phone is required"),
  });

  return (
    <>
      <DialogComponent
        title={getTranslation(title, title, title)}
        open={isOpen}
        onClose={onClose}
        fullWidth
        maxWidth='sm'
      >
        <Box p={2}>
          <Formik
            enableReinitialize
            initialValues={initialValues}
            validationSchema={
              title === "Send Test Message"
                ? phoneValidationSchema
                : emailValidationSchema
            }
            onSubmit={(values, actions) => {
              send(values);
            }}
          >
            {({
              errors,
              touched,
              values,
              handleSubmit,
              isSubmitting,
              getFieldProps,
              setFieldValue,
            }) => (
              <Form>
                <Grid container spacing={2}>
                  <Grid item xs={12} lg={7} md={7} sm={7}>
                    {title !== "Send Test Message" && (
                      <TextField
                        fullWidth
                        id='email'
                        label={getTranslation("Email", "Email", "Email")}
                        size='small'
                        variant='outlined'
                        type='text'
                        name='email'
                        {...getFieldProps("email")}
                        error={touched.email && Boolean(errors.email)}
                        helperText={touched.email && errors.email}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    )}

                    <Grid>
                      {title === "Send Test Message" && (
                        <TextField
                          fullWidth
                          id='phoneNumber'
                          label={getTranslation("Phone", "Phone", "Phone")}
                          size='small'
                          variant='outlined'
                          type='text'
                          name='phoneNumber'
                          {...getFieldProps("phoneNumber")}
                          error={
                            touched.phoneNumber && Boolean(errors.phoneNumber)
                          }
                          helperText={touched.phoneNumber && errors.phoneNumber}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      )}
                    </Grid>
                  </Grid>
                  <Grid item xs={12} lg={5} md={5} sm={5}>
                    <ButtonWithLoading
                      title={getTranslation(title, title, title)}
                      size='small'
                      variant='contained'
                      color='primary'
                      type='submit'
                      loading={loading}
                      style={{
                        backgroundColor: "rgb(229,60,22)",
                        color: "white",
                        fontWeight: "bold",
                      }}
                      onClick={handleSubmit}
                    />
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </Box>
      </DialogComponent>
    </>
  );
}
